// ** Fetch Events
export const fetchEvents = (events: any) => {
  return (dispatch: any) => {
    dispatch({
      type: 'FETCH_EVENTS',
      events
    })
  }
}

// ** Add Event
export const addEvent = (event:any) => {
  return (dispatch:any) => {
    dispatch({
      type: 'ADD_EVENT',
      event
    })
  }
}

// ** Add Event
export const addRecurringEvents = (events:any) => {
  return (dispatch:any) => {
    dispatch({
      type: 'ADD_RECURRING_EVENT',
      events
    })
  }
}

// ** Update Event
export const updateEvent = (event:any) => {
  return (dispatch:any) => {
    dispatch({
      type: 'UPDATE_EVENT',
      event
    })
  }
}


// ** remove Event
export const removeEvent = (id:any) => {
  return (dispatch: any) => {
    dispatch({
      type: 'REMOVE_EVENT',
      id
    })
  }
}

// ** Select Event (get event data on click)
export const selectEvent = (event: any) => {
  return (dispatch: any) => {
    dispatch({
      type: 'SELECT_EVENT',
      event
    })
  }
}
