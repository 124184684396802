/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import AuthorizationAPI from "../api/authorization";
import { validateEmailAddress } from "../utils/authValidation";
import { Alert, message } from "antd";

const ForgotPassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [userinfo, setUserInfo] = useState<any>({
    email: "",
  })
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errors, setErrors] = useState<any>({
    email: "",
  })

  const handleSendEmail = async () => {
    setErrorMsg("");

    if (!userinfo.email) {
      setErrors((info: any) => ({ ...info, email: "Email is required" }))
    }

    if (!validateEmailAddress(userinfo.email)) {
      return;
    }

    setLoading(true);
    const values = {
      email: userinfo.email,
    };

    AuthorizationAPI.forgotPassword(values)
      .then(data => {
        message.success(`Please check your email to reset your password.!`);
      })
      .catch(error => {
        setErrorMsg(error?.response?.data?.error?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;
    setUserInfo((info: any) => ({ ...info, [field]: value }))
    switch (field) {
      case 'email':
        if (!validateEmailAddress(value)) {
          setErrors((info: any) => ({ ...info, email: "Email is invalid" }))
        } else {
          setErrors((info: any) => ({ ...info, email: "" }))
        }
        break;

      default:
        break;
    }
  }


  return (
    <>
      <div className="login-bg">
        <Header />
        <div className="mask-bg">
          <div className="main-section bottom-padding" style={{ height: '100%' }}>
            <div className="container" style={{ height: '100%' }}>
              <div className="row align-items-center gy-5" style={{ height: '100%' }}>
                <div className="middle-back">
                  <img src="/images/topback.svg" alt="" />
                </div>

                <div className="col-lg-12 mb-3" style={{ zIndex: '1' }}>
                  <div className="row align-items-center justify-content-center  py-5">
                    <div className="login-panel">
                      {
                        loading &&
                        <div className="loading-panel">
                          <div className="loading-bar">
                            <ClipLoader color={"#06b6aa"} loading={loading} size={50} />
                          </div>
                        </div>
                      }

                      <div className="col-lg-9  col-11">
                        <div className="login-title" >
                          Password Recovery
                        </div>
                      </div>
                      {errorMsg ? (
                        <Alert
                          className="alert_error"
                          message={errorMsg}
                          type="error"
                          closable
                          afterClose={() => setErrorMsg("")}
                        />
                      ) : null}
                      <div className="col-lg-9 col-11">
                        <input
                          className="login-input"
                          type="text"
                          name="email"
                          placeholder="Email Address"
                          value={userinfo.email}
                          onChange={handleChange}
                        />
                        {
                          errors.email &&
                          <p className="error-text">{errors.email}</p>
                        }
                      </div>

                      <div className="col-lg-9 col-11">
                        <div className="login-button-container">
                          <button className="login-btn d-flex justify-content-between" onClick={handleSendEmail}>
                            <p className="m-0">
                              Send Email
                            </p>
                            <div style={{ width: '30px', borderRadius: '100px', background: 'black', height: '30px', marginLeft: '20px', position: 'relative' }}>
                              <img src="/images/play.png" alt="" style={{ width: '10px', position: 'absolute', top: '10px', left: '10px' }} /></div>
                          </button>
                          <Link to='/register' className="login-text2">
                            Ready to Sign in?
                          </Link>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ForgotPassword;