import React from 'react';

const ReportIcon = ({ ...props }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <g clipPath="url(#clip0_46_3222)">
      <path
        d="M11.0428 1.54175H4.12355C3.7483 1.54218 3.38854 1.69144 3.1232 1.95678C2.85786 2.22212 2.7086 2.58187 2.70817 2.95712V3.70841H1.95688C1.58163 3.70884 1.22187 3.8581 0.956533 4.12344C0.691192 4.38878 0.541934 4.74854 0.541504 5.12379V12.043C0.541934 12.4183 0.691192 12.778 0.956533 13.0434C1.22187 13.3087 1.58163 13.458 1.95688 13.4584H8.87613C9.25138 13.458 9.61113 13.3087 9.87648 13.0434C10.1418 12.778 10.2911 12.4183 10.2915 12.043V11.2917H11.0428C11.418 11.2913 11.7778 11.1421 12.0431 10.8767C12.3085 10.6114 12.4577 10.2516 12.4582 9.87637V2.95712C12.4577 2.58187 12.3085 2.22212 12.0431 1.95678C11.7778 1.69144 11.418 1.54218 11.0428 1.54175ZM9.20817 12.043C9.20803 12.1311 9.173 12.2154 9.11076 12.2777C9.04852 12.3399 8.96415 12.3749 8.87613 12.3751H1.95688C1.86886 12.3749 1.78449 12.3399 1.72225 12.2777C1.66001 12.2154 1.62498 12.1311 1.62484 12.043V5.12379C1.62498 5.03577 1.66001 4.9514 1.72225 4.88916C1.78449 4.82692 1.86886 4.79189 1.95688 4.79175H8.87613C8.96415 4.79189 9.04852 4.82692 9.11076 4.88916C9.173 4.9514 9.20803 5.03577 9.20817 5.12379V12.043ZM11.3748 9.87637C11.3747 9.96439 11.3397 10.0488 11.2774 10.111C11.2152 10.1732 11.1308 10.2083 11.0428 10.2084H10.2915V5.12379C10.2911 4.74854 10.1418 4.38878 9.87648 4.12344C9.61113 3.8581 9.25138 3.70884 8.87613 3.70841H3.7915V2.95712C3.79165 2.8691 3.82668 2.78473 3.88892 2.72249C3.95115 2.66025 4.03553 2.62522 4.12355 2.62508H11.0428C11.1308 2.62522 11.2152 2.66025 11.2774 2.72249C11.3397 2.78473 11.3747 2.8691 11.3748 2.95712V9.87637Z"
        fill="currentColor"
      />
      <path
        d="M7.58301 8.04167H5.95801V6.41667C5.95801 6.27301 5.90094 6.13523 5.79936 6.03365C5.69777 5.93207 5.56 5.875 5.41634 5.875C5.27268 5.875 5.13491 5.93207 5.03332 6.03365C4.93174 6.13523 4.87467 6.27301 4.87467 6.41667V8.04167H3.24967C3.10602 8.04167 2.96824 8.09873 2.86666 8.20032C2.76508 8.3019 2.70801 8.43967 2.70801 8.58333C2.70801 8.72699 2.76508 8.86477 2.86666 8.96635C2.96824 9.06793 3.10602 9.125 3.24967 9.125H4.87467V10.75C4.87467 10.8937 4.93174 11.0314 5.03332 11.133C5.13491 11.2346 5.27268 11.2917 5.41634 11.2917C5.56 11.2917 5.69777 11.2346 5.79936 11.133C5.90094 11.0314 5.95801 10.8937 5.95801 10.75V9.125H7.58301C7.72667 9.125 7.86444 9.06793 7.96602 8.96635C8.06761 8.86477 8.12467 8.72699 8.12467 8.58333C8.12467 8.43967 8.06761 8.3019 7.96602 8.20032C7.86444 8.09873 7.72667 8.04167 7.58301 8.04167Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_46_3222">
        <rect width="13" height="13" fill="white" transform="translate(0 1)" />
      </clipPath>
    </defs>
  </svg>
);

export default ReportIcon;
