/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
// ** React Import
import { useEffect, useRef } from 'react'

// ** Full Calendar & it's Plugins
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment';
import CalendarAPI from '../../api/calendar'
import { datetime, RRule } from 'rrule'
import { checkTime } from '../../utils/helper'

const Calendar = (props: any) => {
  const {
    isRtl,
    store,
    dispatch,
    calendarApi,
    setCalendarApi,
    selectEvent,
    updateEvent,
    blankEvent,
    openAddEventModal,
    openEditEventModal
  } = props

  const calendarRef = useRef<any>(null)

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi())
  }, [])

  const UpdateEvent = async (eventData: any, view: string) => {
    let start = new Date(eventData.start);
    const extendedProps = eventData.extendedProps
    const repeatData = extendedProps.repeat_data;
    let full_rule = "";
    if (view === "timeGridDay") {
      const time = moment(new Date(eventData.start)).format('HH:mm:ss');
      const d = moment(new Date(repeatData.start)).format('YYYY-MM-DD');
      let isoTimestamp = `${d}T${time}`;
      start = new Date(isoTimestamp);
    }

    if (extendedProps.rrule_text) {
      const freq = repeatData.freq?.value;
      let ruleObject = new RRule({
        freq: RRule.DAILY,
        interval: repeatData.interval,
        dtstart: datetime(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()),
        until: datetime(start.getUTCFullYear(), 12, 31)
      })
      switch (freq) {
        case "Weekly":
          let byweekday: any[] = [];
          repeatData.weeklyRepeatOn.forEach((element: any) => {
            // @ts-ignore
            byweekday.push(RRule[element?.value]);
          });
          ruleObject = new RRule({
            freq: RRule.WEEKLY,
            interval: repeatData.interval,
            byweekday: byweekday,
            dtstart: datetime(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()),
            until: datetime(start.getUTCFullYear(), 12, 31)
          })
          break;

        case "Monthly":
          switch (repeatData.monthlyRepeatOn?.value) {
            case "On day 25":
              ruleObject = new RRule({
                freq: RRule.MONTHLY,
                interval: 1,
                bymonthday: [25],
                dtstart: datetime(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()),
                until: datetime(start.getUTCFullYear(), 12, 31)
              })
              break;
            case "On the fourth Monday":
              ruleObject = new RRule({
                freq: RRule.MONTHLY,
                interval: 1,
                byweekday: [RRule.MO],
                bysetpos: [4],
                dtstart: datetime(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()),
                until: datetime(start.getUTCFullYear(), 12, 31)
              })
              break;
            case "On the last Monday":
              ruleObject = new RRule({
                freq: RRule.MONTHLY,
                interval: 1,
                byweekday: [RRule.MO],
                bysetpos: [-1],
                dtstart: datetime(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()),
                until: datetime(start.getUTCFullYear(), 12, 31)
              })
              break;

            default:
              break;
          }
          break;

        default:
          break;
      }

      full_rule = ruleObject.toString();
    }

    const startDate = new Date(eventData.start);
    const endDate = new Date(eventData.end);
    //@ts-ignore
    const milliseconds = endDate - startDate;
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;

    hours = checkTime(hours);
    minutes = checkTime(minutes);
    const duration = `${hours}:${minutes}`
    const newEvent = {
      title: eventData.name,
      description: eventData.description,
      start: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      color: extendedProps.event_color,
      event_id: extendedProps.event_id,
      allDay: eventData.allDay,
      duration: eventData.allDay ? null : duration,
      rrule_text: full_rule,
      repeat_data: extendedProps.repeat_data
    }
    const body = { newEvent };
    const res = await CalendarAPI.updateEvent(body, eventData.id);

    if (res.event.rrule_text) {
      const event = {
        ...res.event,
        rrule: res.event.rrule_text,
        url: `##${res.event.id}`,
        event_color: res.event.color
      }
      dispatch(updateEvent(event))
    } else {
      const event = {
        ...res.event,
        url: `##${res.event.id}`,
        event_color: res.event.color
      }
      dispatch(updateEvent(event))
    }

  }


  const calendarOptions = {
    events: store.events.length ? store.events : [],
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, rrulePlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, today',
      center: 'title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    },
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 4,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClick({ event: clickedEvent }: any) {
      dispatch(selectEvent(clickedEvent))
      openEditEventModal();
    },

    dateClick(info: any) {
      const ev = blankEvent
      const time = moment(new Date()).format('HH:mm:ss');
      const d = moment(new Date(info.date)).format('YYYY-MM-DD');
      let isoTimestamp = `${d}T${time}`;
      ev.start = isoTimestamp
      ev.end = isoTimestamp
      dispatch(selectEvent(ev))
      openAddEventModal();
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }: any) {
      UpdateEvent(droppedEvent, calendarApi.view.type);
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }: any) {
      UpdateEvent(resizedEvent, calendarApi.view.type);
    },

    ref: calendarRef,

    // Get direction from app state (store)
    direction: isRtl ? 'rtl' : 'ltr'
  }

  return (
    //@ts-ignore
    <FullCalendar {...calendarOptions} />
  )
}

export default Calendar;