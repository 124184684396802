import React from 'react';

const CopyIcon = ({ ...props }) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
    <path
      d="M16.4539 0.164062H5.61252C5.02456 0.164736 4.46088 0.398601 4.04513 0.81435C3.62938 1.2301 3.39551 1.79378 3.39484 2.38174V3.5589H2.21768C1.62972 3.55958 1.06604 3.79344 0.650288 4.20919C0.234538 4.62494 0.000673879 5.18863 0 5.77658V16.618C0.000673879 17.206 0.234538 17.7697 0.650288 18.1854C1.06604 18.6012 1.62972 18.835 2.21768 18.8357H13.0591C13.6471 18.835 14.2107 18.6012 14.6265 18.1854C15.0422 17.7697 15.2761 17.206 15.2768 16.618V15.4408H16.4539C17.0419 15.4402 17.6056 15.2063 18.0213 14.7906C18.4371 14.3748 18.671 13.8111 18.6716 13.2232V2.38174C18.671 1.79378 18.4371 1.2301 18.0213 0.81435C17.6056 0.398601 17.0419 0.164736 16.4539 0.164062ZM13.5794 16.618C13.5791 16.7559 13.5243 16.8881 13.4267 16.9856C13.3292 17.0832 13.197 17.138 13.0591 17.1383H2.21768C2.07977 17.138 1.94757 17.0832 1.85005 16.9856C1.75253 16.8881 1.69765 16.7559 1.69742 16.618V5.77658C1.69765 5.63867 1.75253 5.50647 1.85005 5.40895C1.94757 5.31143 2.07977 5.25655 2.21768 5.25632H13.0591C13.197 5.25655 13.3292 5.31143 13.4267 5.40895C13.5243 5.50647 13.5791 5.63867 13.5794 5.77658V16.618ZM16.9742 13.2232C16.974 13.3611 16.9191 13.4933 16.8216 13.5908C16.7241 13.6883 16.5919 13.7432 16.4539 13.7434H15.2768V5.77658C15.2761 5.18863 15.0422 4.62494 14.6265 4.20919C14.2107 3.79344 13.6471 3.55958 13.0591 3.5589H5.09226V2.38174C5.09249 2.24383 5.14737 2.11163 5.24489 2.01411C5.34241 1.91659 5.47461 1.86171 5.61252 1.86148H16.4539C16.5919 1.86171 16.7241 1.91659 16.8216 2.01411C16.9191 2.11163 16.974 2.24383 16.9742 2.38174V13.2232Z"
      fill="currentColor"
    />
  </svg>
);

export default CopyIcon;
