/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useEffect, useState, CSSProperties, useCallback } from "react";
import MicIcon from '@mui/icons-material/Mic';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import MicOffIcon from '@mui/icons-material/MicOff';
import IconButton from "@mui/material/IconButton";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import '../../assets/scss/style.bundle.scss';
import ChatbotAPI from "../../api/chatbot";
import PulseLoader from "react-spinners/PulseLoader";
import BotMessge from "./botMessage";
import ChatMenuActions from "./optionMenu";
import { Input } from "../../components/ui";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/AppContext";
import './scrollbar.css';
import { format } from 'date-fns';

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
};

const Chatting = () => {
    const { calendarApi } = useAuth();
    
    const { id } = useParams();
    const {
        transcript,
        listening,
        resetTranscript,
        isMicrophoneAvailable,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();
    const navigate = useNavigate();
    const { allChats, setAllChats, handleCreateNewChat, handleDeleteChat, justLoggedIn, setJustLoggedIn } = useAuth();

    const [message, setMessage] = useState<string>("");
    const [text, setText] = useState<string>("");
    const [chatData, setChatData] = useState<any>({
        id: "",
        title: "Helpful Howie here to Assist!",
        messages: [],
        chat_id: ""
    });
    const [edit, setEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState<string>("");

    const handleFirstPage = () => {
        let firstChat = allChats.find((item: any) => item.chat_id === id);
        if (!firstChat) {
            firstChat = allChats[0];
            navigate(`/chat/${firstChat.chat_id}`, { replace: true });
        } else {
            handleGetChatData(firstChat.id);
        }
    }

    const handleGetChatData = async (id: any) => {
        const res = await ChatbotAPI.getChat(id);
        let chatdata = {
            ...res.data.attributes,
            id,
        }
        setChatData(chatdata);
        setTitle(res.data.attributes.title);
    }

    useEffect(() => {
        if (allChats && allChats !== undefined) {
            if (allChats.length > 0) {
                handleFirstPage();
            }
        }
    }, [id, allChats])

    useEffect(() => {
        let scroll = document.querySelector('[data-kt-element="messages"]')
        if (scroll !== undefined && scroll !== null) {
            // @ts-ignore
            scroll.scrollTop = scroll.scrollHeight
        }
        // eslint-disable-next-line
    }, [chatData.messages])

    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true });
        setText(message);
    }

    const stopListening = () => {
        SpeechRecognition.stopListening()
        resetTranscript()
    }

    useEffect(() => {
        if (transcript) {
            const message = text + transcript;
            setMessage(message);
        }
    }, [transcript])

    const handleChangeMessage = (event: any) => {
        const text = event.target.value;
        setMessage(text);
    }

    const addMessageToChat = (sender: string, text: string) => {
        setChatData((data: any) => ({
            ...data,
            messages: [...(data.messages || []), { sender, text }]
        }));
    };

    const handleSendMessage = async (msg: string) => {
        const newMessages = [...(chatData.messages || []), { sender: 'you', text: msg }];
        
        // Update the chat data state with the new message
        setChatData((data) => ({
            ...data,
            messages: newMessages,
        }));

        const body = newMessages;
        setLoading(true);

        try {
            const res = await ChatbotAPI.sendMessage(body);
            setLoading(false);
            addMessageToChat('chatbot', res.message);
            setMessage("");
        } catch (error) {
            setLoading(false);
        }
    };

    const onSendButtonClick = () => {
        handleSendMessage(message);
        setMessage("");
    };

    const welcomeMessageSent = useRef(false);
    const handleSendWelcome = async () => {
        const getNotifications = async () => {
            // Uncomment the following line if calendarAPI condition is needed
            // if (calendarAPI) {
            console.log("Fetching events from calendar API...");
            const events = await calendarApi.getEvents();
            console.log("Events fetched: ", events);

            const today = format(new Date(), "yyyy-MM-dd");
            console.log("Today's date: ", today);

            const todayEvents = events.filter(event => 
                format(new Date(event.start), "yyyy-MM-dd") === today
            );
            console.log("Today's events: ", todayEvents);

            const eventTitles = todayEvents.map(event => event.title);
            console.log("Event titles: ", eventTitles);

            return eventTitles;
            // }
            // return []; // Return an empty array if calendarAPI is not available
        };

        if (justLoggedIn && !welcomeMessageSent.current) {
            console.log("Sending welcome message...");
            welcomeMessageSent.current = true;
            setLoading(true);
            try {
                const todayEventPull = await getNotifications();
                console.log("Today Event Pull: ", todayEventPull);

                //const welcomeMessage = {
                //    message: "Welcome to the chat!",
                //    events: notifications
                //};
                //
                //const welcomeMessage = [{ role: 'user', content: "Welcome me to the to the chat, Introduce yourself, and end the message with Feel free to ask me any questions and I will do my best to assist you! Finally Here are my upcoming events for today EVENTS:" + todayEventPull.join(", ")}];
                const welcomeMessage = [{ role: 'user', content: "Introduce yourself BRIEFLY, and end the message with How can I assist you today?"}];
                console.log("Welcome message to send: ", welcomeMessage);

                const res = await ChatbotAPI.sendMessage(welcomeMessage);
                console.log("Response from sendMessage: ", res);

                addMessageToChat('chatbot', res.message);
            } catch (error) {
                console.error("Error in handleSendWelcome: ", error);
            } finally {
                setLoading(false);
                setJustLoggedIn(false);
                console.log("Welcome message sent and state updated.");
            }
        }
    };

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSendMessage(message);
            e.preventDefault();
        } else if (e.key === 'Enter' && e.shiftKey) {
            setMessage(prev => prev + '\n');
            e.preventDefault();
        }
    }

    useEffect(() => {
        handleSendWelcome();
    }, [justLoggedIn]); 

    useEffect(() => {
        return () => {
            SpeechRecognition.stopListening();
        };
    }, [])

    const handleSaveName = async () => {
        setEdit(false);
        const newChat = {
            title: title,
            messages: chatData.messages
        }

        const body1 = { newChat };

        await ChatbotAPI.updateChat(body1, chatData.id);
        setChatData((data: any) => ({
            ...data,
            title: title
        }))

        const chats = allChats.map((item: any) => {
            if (item.id === chatData.id) {
                return {
                    ...item,
                    title: title
                }
            } else {
                return item;
            }
        })

        setAllChats([...chats])
    }

    const handleCancelName = () => {
        setTitle(chatData.title);
        setEdit(false);
    }

    const handleAddNewChat = async () => {
        const chat = await handleCreateNewChat();
        navigate(`/chat/${chat.chat_id}`, { replace: true });
    }

    const handleDelete = async () => {
        await handleDeleteChat(chatData.id);
        if (allChats.length > 0) {
            const firstChat = allChats[0];
            navigate(`/chat/${firstChat.chat_id}`, { replace: true });
        } else {
            handleAddNewChat();
        }
    }

    return (
        //<div className="card" style={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
        //<div className="card" style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100vw', margin: 0 }}>
        <div className="card" style={{
            display: 'flex',
            flexDirection: 'column',
            height: '91vh', // Full height of the viewport
            width: 'calc(100vw -25px)', // Attempt to account for scrollbar width
            margin: 0, // Ensure no margin
            boxSizing: 'border-box', // Include padding and border in the element's size
            overflow: 'hidden' // Prevent scrolling on the card, if desired
        }}>
            <div className="card-header" style={{ flexShrink: 0 }} >
                <div className="card-title w-100">
                    <div className="d-flex w-100">
                        {
                            edit ?
                                <div className="d-flex w-100">
                                    <Input
                                        type="text"
                                        style={{ maxWidth: '500px' }}
                                        label=""
                                        placeholder="Please Enter"
                                        value={title}
                                        onValue={(title: any) => setTitle(title)}
                                    />
                                    <IconButton
                                        className=" btn-active-light-primary"
                                        color="secondary"
                                        disabled={title.length === 0}
                                        onClick={handleSaveName}
                                    >
                                        <SaveIcon />
                                    </IconButton>
                                    <IconButton
                                        className=" btn-active-light-danger"
                                        color="error"
                                        onClick={handleCancelName}
                                    >
                                        <CancelIcon />
                                    </IconButton>
                                </div>
                                :
                                <div className="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                                    {chatData.title}
                                </div>
                        }

                    </div>
                    <button
                        className="btn btn-info btn-sm me-2"
                        style={{ whiteSpace: 'pre', fontWeight: 'bold', backgroundColor: '#289ae6', fontSize: '0.9em',  fontFamily: 'Inter, Helvetica, sans-serif'}}
                        type="button"
                        onClick={handleDelete}
                    >
                         Clear Chat
                    </button>
                </div>
            </div>
            <div className="card-body"  style={{ flexGrow: 1, overflow: 'hidden' }} >
                <div className="scrollbar scroll-y me-n5 pe-5" data-kt-element='messages' style={{ height: 'calc(73vh - 20px)' }}>
                    {
                        chatData?.messages?.map((item: any, index: any) => {
                            if (item.sender === "you") {
                                return (
                                    <div className="d-flex justify-content-end mb-5" key={index}>
                                        <div className="d-flex flex-column align-items-end">
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="me-3">
                                                    <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">You</a>
                                                </div>
                                                <div className="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="/images/avatar.png" />
                                                </div>
                                            </div>
                                            <div className="p-5 rounded bg-light-info mw-lg-700px text-start" style={{ whiteSpace: 'pre-line', fontSize: '1.2em', color: 'black', fontWeight: 'bold' }}>

                                                {item.text}
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <BotMessge 
                                        data={item}
                                    />
                                )
                            }
                        })
                    }
                    {
                        loading &&
                        <div className="d-flex justify-content-start mb-1" >
                            <div className="d-flex flex-column align-items-start">
                                <div className="d-flex align-items-center mb-2">
                                    <div className="symbol symbol-35px symbol-circle">
                                        <img alt="Pic" src="/images/logo-1.png" />
                                    </div>
                                    <div className="ms-3">
                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">Helpful Howie</a>
                                    </div>
                                </div>
                                <div className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-700px text-start" >
                                    <PulseLoader
                                        color='#69deff'
                                        loading={loading}
                                        cssOverride={override}
                                        size={10}
                                        speedMultiplier={0.6}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="card-footer pt-4" id="kt_chat_messenger_footer" style={{flexShrink:0, display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'nowrap', backgroundColor: '#ce7d22', padding: '10px 0' }}>
                <textarea
                    className="form-control form-control-flush mb-3"
                    rows={1}
                    readOnly={listening}
                    value={message}
                    placeholder="Type a message"
                    onChange={handleChangeMessage}
                    onKeyPress={handleKeyPress} // Added here
                    style={{ 
                        flexGrow: 1, 
                        fontSize: '1.2em', 
                        backgroundColor: '#ebd7c8', 
                        borderRadius: '7px', 
                        marginLeft: '10px',
                        color: 'black',       // Sets the text color to black
                        fontWeight: 'bold'    // Makes the text bold
                    }}                      
                >
                </textarea>
                <div className="d-flex align-items-center me-2" style={{ flexShrink: 0, marginRight: '10px' }}>
                    {
                        listening ?
                            <IconButton
                                className=" btn-active-light-danger"
                                color="error"
                                aria-label="stop recording"
                                disabled={!listening}
                                onClick={stopListening}
                            >
                                <MicOffIcon fontSize="large"/>
                            </IconButton>
                            :
                            <IconButton
                                className=" btn-active-light-primary"
                                color="secondary"
                                aria-label="start recording"
                                disabled={!browserSupportsSpeechRecognition || !isMicrophoneAvailable || listening}
                                onClick={startListening}
                            >
                                <MicIcon fontSize="large"/>
                            </IconButton>
                    }

                </div>
                <button
                    className="btn btn-primary"
                    type="button"
                    disabled={message.length === 0 || listening}
                    onClick={onSendButtonClick}
                    style={{ flexShrink: 0, marginRight: '10px', color: 'black', fontWeight: 'bold', fontSize: '1.2em' }}
                >
                    SEND
                </button>
            </div>
        </div>
    )
}

export default Chatting;

