import React, { useContext, Children, useEffect, useState } from 'react';
import {
  CalendarCardMain,
  CalendarCardGrid,
  CalendarCardCell,
  CalendarCardCellDate,
  CalendarCardDay,
  CalendarCardDays,
  CalendarReset,
  CalendarTitle,
  CalendarEventStatus,
  CalendarEventContainer,
  Events,
} from './styles';
import {
  TCalendarCardProps,
  TCalendarDate,
  TCalendarEvents,
} from './types';
import { format } from 'date-fns';
import {
  CalendarControls,
  // Scheduler,
} from './elements';
import { RestartAlt } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { getCalendarDates } from '../../../utils/calendar';
import { useAppSelector } from '../../../hooks/use-dispatch';
import { useAuth } from '../../../context/AppContext';

import { CalendarContext } from '../../../context/CalendarContext';



const CalendarCard = ({ ...props }: TCalendarCardProps) => {
  const { updateSelectedDate } = useContext(CalendarContext);
  
  const [date, setDate] = useState(new Date());
  const store = useAppSelector((state: any) => state.calendar);

  const { calendarApi } = useAuth();
  const days: TCalendarDate[] = getCalendarDates(date);
  const [events, setEvents] = useState<TCalendarEvents[]>([]);

  const handleClick = (x: TCalendarDate) => () => {
    setDate(x.date);
    updateSelectedDate(x.date);
    getCalendarEvents();
  };

  const handleDate = (v: Date) => {
    setDate(v);
    getCalendarEvents();
  };

  const handleToday = () => {
    setDate(new Date());
    getCalendarEvents();
  };

  const getCalendarEvents = async () => {
    if (calendarApi) {
      const events = await calendarApi.getEvents();
      let notifications = events.map((item: any) => {
        return {
          start: item.start,
          title: item.title,
          description: item.extendedProps.description,
          color: item.extendedProps.event_color,
          allDay: item.allDay
        }
      })

      setEvents([...notifications]);
    }
  };

  useEffect(() => {
    getCalendarEvents();
    //eslint-disable-next-line
  }, [calendarApi, store?.events]);

  return (
    <CalendarCardMain
      title={
        <CalendarTitle>
          Calendar
          <Tooltip title="Reset to today's date">
            <CalendarReset onClick={handleToday}>
              <RestartAlt />
            </CalendarReset>
          </Tooltip>
        </CalendarTitle>
      }
      actions={Children.toArray([
        <CalendarControls
          date={date}
          onBack={handleDate}
          onForward={handleDate}
        />,
      ])}
      {...props}
    >
      <CalendarCardDays columns={7}>
        <CalendarCardDay weekend>SUN</CalendarCardDay>
        <CalendarCardDay>MON</CalendarCardDay>
        <CalendarCardDay>TUE</CalendarCardDay>
        <CalendarCardDay>WED</CalendarCardDay>
        <CalendarCardDay>THU</CalendarCardDay>
        <CalendarCardDay>FRI</CalendarCardDay>
        <CalendarCardDay weekend>SAT</CalendarCardDay>
      </CalendarCardDays>
      <CalendarCardGrid columns={7}>
        {days.map((x, index) => {
          const isHighlighted = x.date.toISOString() === date.toISOString();
          const todayEvents = events.filter(
            (event) =>
              format(new Date(event.start), 'MM/dd/yyyy') ===
                format(x.date, 'MM/dd/yyyy')
          );
          return (
            <CalendarCardCell
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={handleClick(x)}
              isHighlighted={isHighlighted}
            >
              <CalendarCardCellDate
                currentMonth={x.currentMonth}
                today={x.today}
              >
                {format(x.date, 'd')}
              </CalendarCardCellDate>
              <CalendarEventContainer>
                {todayEvents.map((event, index: any) => {
                  return (
                    <Tooltip key={index} title={event.title}>
                      <Events>
                        <CalendarEventStatus
                          color={event.color}
                        />
                      </Events>
                    </Tooltip>
                  );
                })}
              </CalendarEventContainer>
            </CalendarCardCell>
          );
        })}
      </CalendarCardGrid>
      {/* {scModal && <Scheduler onClose={scModalClose} date={date} />} */}
    </CalendarCardMain>
  );
};

export default CalendarCard;
