import React from 'react';

const GramophoneIcon = ({ ...props }) => (
  <svg width="21" height="22" viewBox="0 0 21 22" {...props}>
    <g clipPath="url(#clip0_30_5828)">
      <path
        d="M17.8358 3.13241C17.6209 2.98609 17.3752 2.89757 17.1202 2.87463C16.8652 2.85169 16.6088 2.89503 16.3735 3.00085L8.66188 6.19784C8.4793 6.27606 8.28406 6.31627 8.08694 6.31625H3.15002C2.73567 6.31625 2.33829 6.48951 2.0453 6.79792C1.75231 7.10634 1.58771 7.52463 1.58771 7.96079V8.09235H0.0253906V12.0393H1.58771V12.2103C1.5975 12.6396 1.76641 13.0478 2.05835 13.3477C2.35028 13.6477 2.74209 13.8155 3.15002 13.8154L5.0248 17.9991C5.15175 18.2808 5.35161 18.5191 5.60144 18.6867C5.85126 18.8543 6.14092 18.9443 6.43714 18.9463H7.22455C7.63673 18.9429 8.0309 18.7681 8.3212 18.46C8.61149 18.152 8.77438 17.7357 8.77436 17.3018V13.9732L16.3735 17.1702C16.5604 17.2485 16.7597 17.2887 16.9609 17.2886C17.273 17.2833 17.5769 17.1828 17.8358 16.9992C18.0414 16.8531 18.2109 16.6576 18.3305 16.4288C18.45 16.2 18.5161 15.9446 18.5232 15.6836V4.48752C18.5221 4.21981 18.4589 3.95644 18.3391 3.72028C18.2193 3.48413 18.0465 3.28233 17.8358 3.13241ZM7.21205 7.96079V12.2103H3.15002V7.96079H7.21205ZM7.21205 17.3018H6.42464L4.88732 13.8154H7.21205V17.3018ZM9.23681 12.4076C9.08841 12.3278 8.93367 12.2618 8.77436 12.2103V7.8687C8.93215 7.83443 9.08686 7.78601 9.23681 7.72398L16.9609 4.48752V15.6441L9.23681 12.4076ZM18.5607 8.42126V11.7104C18.9751 11.7104 19.3724 11.5371 19.6654 11.2287C19.9584 10.9203 20.123 10.502 20.123 10.0658C20.123 9.62965 19.9584 9.21135 19.6654 8.90294C19.3724 8.59453 18.9751 8.42126 18.5607 8.42126Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_30_5828">
        <rect
          width="19.9977"
          height="21.0502"
          fill="white"
          transform="translate(0.0244141 0.369385)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GramophoneIcon;
