import axiosInstance from "../../axios/axiosInstance";
import { API } from "../../utils/constant";
// import { CHATAPI } from "../../utils/constant";
import { getToken } from "../../utils/helper";

const ChatbotAPI = {
  

  sendMessage: async (body: any) => {
    console.log("BODY0:", body);
    //const body = { messages: body }
    console.log("BODY1:", body);

    const payload = { message: body };

    console.log(payload)

    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.post(`${API}/chat/message`, payload, {
      headers: headers
    });
    return data;
  },

  getAllChats: async () => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.get(`${API}/chat/get_all`, {
      headers: headers
    });
    return data;
  },

  getChat: async (id: any) => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.get(`${API}/chats/${id}`, {
      headers: headers
    });
    return data;
  },

  addChat: async (body: any) => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.post(`${API}/chats`, body, {
      headers: headers
    });
    return data;
  },

  updateChat: async (body: any, id: any) => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.post(`${API}/chat/update_chat/${id}`, body, {
      headers: headers
    });
    return data;
  },

  deleteChat: async (id: any) => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.delete(`${API}/chat/delete_chat/${id}`, {
      headers: headers
    });
    return data;
  },
};

export default ChatbotAPI;
