import React from 'react';

const CampaignsIcon = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 23 20" fill="none" {...props}>
    <path
      d="M20.4703 1.0437C20.2253 0.876875 19.9452 0.775948 19.6545 0.749793C19.3637 0.723638 19.0714 0.773055 18.8031 0.893702L10.0108 4.5387C9.80265 4.62788 9.58005 4.67372 9.35531 4.6737H3.72656C3.25415 4.6737 2.80108 4.87125 2.46703 5.22288C2.13298 5.57451 1.94531 6.05142 1.94531 6.5487V6.6987H0.164062V11.1987H1.94531V11.3937C1.95647 11.8831 2.14906 12.3486 2.4819 12.6905C2.81475 13.0325 3.26146 13.2238 3.72656 13.2237L5.86406 17.9937C6.0088 18.3149 6.23667 18.5866 6.5215 18.7777C6.80634 18.9687 7.13658 19.0713 7.47431 19.0737H8.37206C8.842 19.0697 9.29142 18.8705 9.62239 18.5193C9.95337 18.1681 10.1391 17.6934 10.1391 17.1987V13.4037L18.8031 17.0487C19.0162 17.138 19.2435 17.1838 19.4728 17.1837C19.8286 17.1777 20.1752 17.063 20.4703 16.8537C20.7047 16.6871 20.898 16.4642 21.0343 16.2034C21.1706 15.9425 21.2459 15.6513 21.2541 15.3537V2.5887C21.2528 2.28348 21.1807 1.9832 21.0441 1.71395C20.9075 1.4447 20.7105 1.21463 20.4703 1.0437ZM8.35781 6.5487V11.3937H3.72656V6.5487H8.35781ZM8.35781 17.1987H7.46006L5.70731 13.2237H8.35781V17.1987ZM10.6663 11.6187C10.4971 11.5277 10.3207 11.4524 10.1391 11.3937V6.4437C10.319 6.40463 10.4954 6.34943 10.6663 6.2787L19.4728 2.5887V15.3087L10.6663 11.6187ZM21.2968 7.0737V10.8237C21.7692 10.8237 22.2223 10.6262 22.5563 10.2745C22.8904 9.9229 23.0781 9.44598 23.0781 8.9487C23.0781 8.45142 22.8904 7.97451 22.5563 7.62288C22.2223 7.27125 21.7692 7.0737 21.2968 7.0737Z"
      fill="currentColor"
    />
  </svg>
);

export default CampaignsIcon;
