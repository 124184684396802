import styled from '@emotion/styled';
import { CardWithText } from '../../custom';
import { IconButton, Theme } from '@mui/material';
import { Grid } from '../../system';

export const CalendarCardMain = styled(CardWithText)<{ theme?: Theme }>``;

export const CalendarCardGrid = styled(Grid)<{ theme?: Theme }>`
  ${({ theme }) => `
    border-radius: 4px;
    width: 100%;
    border: 1px solid #27263d;
    gap: 1px;
    background-color: #27263d;
    overflow: hidden;
  `}
`;

export const CalendarCardCell = styled.div<{
  theme?: Theme;
  isHighlighted: boolean;
}>`
  ${({ theme, isHighlighted }: any) => `
    width: 100%;
    padding: ${theme.spacing(1.5)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${
      // @ts-ignore
      isHighlighted ? theme.palette.dark.dark : theme.palette.dark.lightDark
    };
    cursor: pointer;
    user-select: none;
    transition: background-color 150ms ease-in-out;
    &:hover {
      background-color: ${
        isHighlighted ? theme.palette.dark.dark : theme.palette.dark.dark
      };
    }
  `}
`;

export const CalendarEventContainer = styled.div<{
  theme?: Theme;
}>`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    max-width: 40px;
    overflow: auto
  `}
`;

export const Events = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
   
  `}
`;

export const CalendarEventStatus = styled.div<{
  theme?: Theme;
  color: string;
}>`
  ${({ theme, color }) => `
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 1.2px;
    float: left;
    background-color: ${color};
`}
`;

export const CalendarCardCellDate = styled.div<{
  theme?: Theme;
  currentMonth: boolean;
  today: boolean;
}>`
  ${({ theme, currentMonth, today }) => `
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    ${
      currentMonth
        ? `
      background-color: ${
        // @ts-ignore
        today ? '#ffffffd4' : theme.palette.dark.lightDark
      };
    `
        : `
      background-color: ${
        // @ts-ignore
        today ? theme.palette.dark.lightDark : theme.palette.dark.lightDark
      };
    `
    }
    ${
      currentMonth
        ? `
      color: ${today ? '#000000' : '#e3e3e3'};
    `
        : `
        color: ${today ? '#000000' : '#858585'};
    `
    }
  `}
`;

export const CalendarCardDay = styled.div<{
  theme?: Theme;
  weekend?: boolean;
}>`
  ${({ theme, weekend }: any) => `
    width: 100%;
    padding: ${theme.spacing(2)} 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.dark.lightDark};
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    color: ${weekend ? '#ff3b3b' : '#c8c8c8'};
  `}
`;

export const CalendarCardDays = styled(Grid)`
  width: 100%;
  gap: 0;
`;

export const CalendarTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    color: #e3e3e3;
  `}
`;

export const CalendarReset = styled(IconButton)<{ theme?: Theme }>`
  ${({ theme }) => `
    width: 24px;
    height: 24px;
    padding: 0;
    padding: ${theme.spacing(0.5)};
    svg {
      width: 100%;
      height: 100%;
      display: block;
      color: #b8b8b8;
    }
  `}
`;
