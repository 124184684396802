import React from 'react';

const RegisteredIcon = ({ ...props }) => (
  <svg viewBox="0 0 18 24" fill="currentColor" {...props}>
    <path d="M3.4481 20.82C2.2721 20.82 1.3481 19.812 1.3481 18.636V5.27997C1.3481 4.01997 2.2721 3.09597 3.5321 3.09597H14.5361C15.7961 3.09597 16.7201 4.01997 16.7201 5.27997V18.636C16.7201 19.896 15.7961 20.82 14.5361 20.82H3.6161C3.6161 20.82 3.5321 20.82 3.4481 20.82ZM3.5321 3.93597C2.7761 3.93597 2.1881 4.52397 2.1881 5.27997V18.636C2.1881 19.392 2.8601 20.064 3.6161 19.98H14.5361C15.2921 19.98 15.8801 19.392 15.8801 18.636V5.27997C15.8801 4.52397 15.2921 3.93597 14.5361 3.93597H3.5321Z" />
    <path d="M14.1161 19.14H4.0361C3.5321 19.14 3.1121 18.72 3.1121 18.216V5.78397C3.1121 5.27997 3.5321 4.85997 4.0361 4.85997H14.2001C14.7041 4.85997 15.1241 5.27997 15.1241 5.78397V18.132C15.1241 18.72 14.7041 19.14 14.1161 19.14ZM4.0361 5.69997C3.9521 5.69997 3.9521 5.78397 3.9521 5.78397V18.216C3.9521 18.3 4.0361 18.3 4.0361 18.3H14.1161C14.2001 18.3 14.2841 18.216 14.2841 18.132V5.78397C14.2841 5.69997 14.2001 5.69997 14.2001 5.69997H4.0361Z" />
    <path d="M1.7681 10.992H0.928104C0.508104 10.992 0.256104 10.656 0.256104 10.32V8.30397C0.256104 7.88397 0.592104 7.63197 0.928104 7.63197H1.7681" />
    <path d="M16.3841 10.992H17.2241C17.6441 10.992 17.8961 10.656 17.8961 10.32V8.30397C17.8961 7.88397 17.5601 7.63197 17.2241 7.63197H16.3841" />
    <path d="M16.3841 16.452H17.2241C17.6441 16.452 17.8961 16.116 17.8961 15.78V14.856C17.8961 14.436 17.5601 14.184 17.2241 14.184H16.3841" />
    <path d="M12.1841 23.676H5.9681C5.3801 23.676 4.8761 23.34 4.7081 22.752L3.9521 20.568C3.8681 20.4 3.9521 20.316 4.0361 20.148C4.1201 19.98 4.2041 19.98 4.3721 19.98H13.7801C13.9481 19.98 14.0321 20.064 14.1161 20.148C14.2001 20.232 14.2001 20.4 14.2001 20.568L13.4441 22.752C13.1921 23.34 12.6881 23.676 12.1841 23.676ZM4.9601 20.904L5.5481 22.5C5.6321 22.668 5.7161 22.836 5.9681 22.836H12.1841C12.3521 22.836 12.5201 22.668 12.6881 22.5L13.1921 20.904H4.9601Z" />
    <path d="M13.7801 3.93597H4.3721C4.2041 3.93597 4.1201 3.85197 4.0361 3.76797C3.9521 3.68397 3.9521 3.51597 3.9521 3.34797L4.7081 1.16397C4.7921 0.659975 5.3801 0.323975 5.9681 0.323975H12.1841C12.6881 0.323975 13.1921 0.659975 13.3601 1.16397L14.1161 3.26397C14.2001 3.34797 14.2001 3.43197 14.2001 3.51597C14.2001 3.76797 14.0321 3.93597 13.7801 3.93597ZM4.9601 3.09597H13.1921L12.6041 1.49997C12.5201 1.33197 12.3521 1.16397 12.1841 1.16397H5.9681C5.7161 1.16397 5.5481 1.33197 5.5481 1.41597L4.9601 3.09597Z" />
    <path d="M10.0841 14.436H8.5721C8.4041 14.436 8.2361 14.268 8.1521 14.1L7.3961 10.572L6.3881 12.672C6.3041 12.84 6.1361 12.924 5.9681 12.924H3.8681C3.6161 12.924 3.4481 12.756 3.4481 12.504C3.4481 12.252 3.6161 12.084 3.8681 12.084H5.8001L7.2281 9.14397C7.3121 8.97597 7.4801 8.89197 7.6481 8.89197C7.8161 8.89197 7.9841 9.05997 7.9841 9.22797L8.9081 13.68H9.7481L10.5881 10.068C10.5881 9.89998 10.7561 9.81597 10.9241 9.73197C11.0921 9.73197 11.2601 9.73197 11.3441 9.89997L12.8561 12.084H14.7041C14.9561 12.084 15.1241 12.252 15.1241 12.504C15.1241 12.756 14.9561 12.84 14.7041 12.84H12.6041C12.4361 12.84 12.3521 12.756 12.2681 12.672L11.1761 11.076L10.5041 14.1C10.4201 14.268 10.2521 14.436 10.0841 14.436Z" />
  </svg>
);

export default RegisteredIcon;
