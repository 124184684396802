import React from 'react';

const ClientContactedIcon = ({ ...props }) => (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.6446 19.9879H4.50858C2.24058 19.9879 0.392578 18.1399 0.392578 15.8719V4.27994C0.392578 2.01194 2.24058 0.16394 4.50858 0.16394H21.7286C23.9966 0.16394 25.7606 2.01194 25.7606 4.27994V15.8719C25.6766 18.1399 23.8286 19.9879 21.6446 19.9879ZM4.50858 1.42394C2.99658 1.42394 1.65258 2.68394 1.65258 4.27994V15.8719C1.65258 17.3839 2.91258 18.7279 4.50858 18.7279H21.6446C23.1566 18.7279 24.5006 17.4679 24.5846 15.8719V4.27994C24.5846 2.76794 23.3246 1.42394 21.8126 1.42394H4.50858Z"
      fill="currentColor"
    />
    <path
      d="M13.0756 13.772C12.9076 13.772 12.7396 13.688 12.6556 13.604L7.86763 8.98398L3.07962 4.36398C2.82762 4.11198 2.82762 3.69198 3.07962 3.43998C3.33163 3.18798 3.75163 3.18798 4.00363 3.43998L8.79162 7.97598L13.1596 12.176L17.5276 7.97598L22.3156 3.43998C22.5676 3.18798 22.9876 3.18798 23.2396 3.43998C23.4916 3.69198 23.4916 4.11198 23.2396 4.36398L18.4516 8.89998L13.4956 13.52C13.4116 13.688 13.2436 13.772 13.0756 13.772Z"
      fill="currentColor"
    />
    <path
      d="M3.49963 17.132C3.33163 17.132 3.16363 17.048 3.07962 16.964C2.82762 16.712 2.82762 16.292 3.07962 16.04L9.71562 9.74003C9.96762 9.48803 10.3876 9.48803 10.6396 9.74003C10.8916 9.99203 10.8916 10.412 10.6396 10.664L3.91963 16.964C3.83562 17.132 3.66763 17.132 3.49963 17.132Z"
      fill="currentColor"
    />
    <path
      d="M22.6513 17.132C22.4833 17.132 22.3153 17.048 22.2313 16.964L15.5953 10.664C15.3432 10.412 15.3432 9.99203 15.5953 9.74003C15.8473 9.48803 16.2673 9.48803 16.5193 9.74003L23.1553 16.04C23.4073 16.292 23.4073 16.712 23.1553 16.964C22.9873 17.132 22.8193 17.132 22.6513 17.132Z"
      fill="currentColor"
    />
  </svg>
);

export default ClientContactedIcon;
