import React from 'react';

import {
  NotificationMain,
  NotificationContent,
  NotificationStatus,
  NotificationText,
  NotificationDate,
  NotifictionLinkContainer,
  NotificationDescription,
} from './styles';
import { TNotificationProps } from './types';
import { format } from 'date-fns';

const Notification = ({
  color,
  allDay,
  title,
  description,
  start,
  ...props
}: TNotificationProps) => {
  let href = '#';

  return (
    <NotificationMain {...props} style={{ backgroundColor: '#b79e82' }}> 
      <NotifictionLinkContainer to={href}>
        <NotificationContent>
          <NotificationStatus color={color} />
          <NotificationText style={{ color: '#000000' }}> {title} </NotificationText>
        </NotificationContent>
        <NotificationDescription style={{ color: '#000000' }}> {description} </NotificationDescription>
        {
          start &&
          <NotificationDate style={{ color: '#000000' }}>
            {allDay
              ? format(new Date(start), 'MMM dd, yyyy')
              : format(new Date(start), 'MMM dd, yyyy | h:mm a')}
          </NotificationDate>
        }
      </NotifictionLinkContainer>
    </NotificationMain>
  );
};

export default Notification;
