import { AUTH_TOKEN, AUTH_USER } from "./constant";

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token: any) => {
  localStorage.setItem(AUTH_TOKEN, token);
};

export const setUser = (user: any) => {
  localStorage.setItem(AUTH_USER, JSON.stringify(user));
};

export const getUser = () => {
  const user = localStorage.getItem(AUTH_USER);
  if (user) {
    return JSON.parse(user);
  } else {
    return null
  }
}

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const sortByProperty = <T>(
  arr: T[],
  property: keyof T,
  sortOrder: 'asc' | 'desc' = 'asc'
): T[] => {
  const sortedArray = [...arr].sort((a, b) => {
    const comparison = a[property] > b[property] ? 1 : a[property] < b[property] ? -1 : 0;
    return sortOrder === 'asc' ? comparison : -comparison;
  });

  return sortedArray;
};

export function checkTime(i: any) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}
