/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { getToken, getUser, setToken, setUser } from "../utils/helper";
import ChatbotAPI from "../api/chatbot";

export const AppContext = createContext<any>({});

export const useAuth = () => {
  return useContext(AppContext)
}

export const AppcontextProvider = ({ children }: any) => {

  const [currentUser, setCurrentUser] = useState<any>(null)
  const [calendarApi, setCalendarApi] = useState<any>(null)
  const [allChats, setAllChats] = useState<any>(null);
  const [justLoggedIn, setJustLoggedIn] = useState(false);
  const [state, setState] = useState<any>({
    user: null,
    initialLoading: true,
    showMobileMenu: true,
    notificationStatus: 0,
  });

  const handleMobileMenu = (value: boolean) => {
    setState((x: any) => ({ ...x, showMobileMenu: value }));
  };

  const handleGetAllChats = async () => {
    try {
      const token = getToken();
      if (token) {
        const res = await ChatbotAPI.getAllChats();
        setAllChats(res.chats);
      }
    } catch (error) {
    }
  }

  const handleCreateNewChat = async () => {
    const newChat = {
      title: "Helpful Howie here to Assist!",// #" + (allChats?.length + 1),
      message: []
    }
    const body = { newChat };
    const res = await ChatbotAPI.addChat(body);
    const chat = {
      id: res.chat.id,
      chat_id: res.chat.chat_id,
      title: res.chat.title,
    }
    setAllChats((chats: any) => [chat, ...chats]);

    return chat
  }

  const handleDeleteChat = async (id: any) => {
    await ChatbotAPI.deleteChat(id);
    const chats = allChats?.filter((item: any) => item.id !== id);
    setAllChats([...chats]);
  }

  useEffect(() => {
    if (allChats) {
      if (allChats.length === 0) {
        handleCreateNewChat();
      }
    }
  }, [allChats])

  useEffect(() => {
    handleGetAllChats();
  }, [currentUser])

  const logout = () => {
    setCurrentUser(null)
    setUser("");
    setToken("");
  }
  return (
    <AppContext.Provider
      value={{
        ...state,
        currentUser,
        setCurrentUser,
        logout,
        handleMobileMenu,
        calendarApi,
        setCalendarApi,
        allChats,
        setAllChats,
        handleCreateNewChat,
        handleDeleteChat,
	justLoggedIn,
	setJustLoggedIn
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

type WithChildren = {
  children?: ReactNode
}

export const AuthInit: FC<WithChildren> = ({ children }) => {
  const { logout, setCurrentUser } = useAuth()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const saveduser = getUser();
    if (saveduser) {
      setCurrentUser(saveduser);
      setShowSplashScreen(false)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <></> : <>{children}</>
}
