import React from 'react';

const reportSMLIcon = ({ ...props }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <path
      d="M12.5303 11.9H2.00909V2.45455C2.00909 2.14826 1.76084 1.9 1.45455 1.9C1.14826 1.9 0.9 2.14826 0.9 2.45455V12.4545C0.9 12.7608 1.14826 13.0091 1.45455 13.0091H12.5303C12.8366 13.0091 13.0848 12.7608 13.0848 12.4545C13.0848 12.1482 12.8366 11.9 12.5303 11.9Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M12.5453 3.09702H9.13619C8.8299 3.09702 8.58164 3.34528 8.58164 3.65157C8.58164 3.95786 8.8299 4.20611 9.13619 4.20611H11.9907V7.0608C11.9907 7.36691 12.239 7.61535 12.5453 7.61535C12.8516 7.61535 13.0998 7.36688 13.0998 7.06064V3.65157C13.0998 3.34528 12.8515 3.09702 12.5453 3.09702Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M12.9379 3.27527L12.938 3.27533C13.1541 3.49233 13.1533 3.84352 12.9363 4.05961C12.9363 4.05961 12.9363 4.05961 12.9363 4.05961L9.16361 7.81717L9.16355 7.81723C8.94666 8.03286 8.59668 8.03273 8.38022 7.81628C8.38022 7.81628 8.38022 7.81628 8.38022 7.81628L7.45412 6.89033L3.80079 10.5435L12.9379 3.27527ZM12.9379 3.27527C12.7215 3.05832 12.3708 3.05776 12.1537 3.27378L12.1537 3.27381M12.9379 3.27527L12.1537 3.27381M12.1537 3.27381L8.77303 6.6408M12.1537 3.27381L8.77303 6.6408M8.77303 6.6408L7.84617 5.71394L7.84614 5.71391C7.62955 5.49752 7.27871 5.49726 7.06203 5.71394L3.01661 9.75937L3.01658 9.75941L3.08729 9.83012C2.90971 10.0075 2.90971 10.2954 3.08729 10.4728M8.77303 6.6408L3.80073 10.5436L3.73002 10.4728C3.6414 10.5616 3.52487 10.606 3.40867 10.606C3.2923 10.606 3.17593 10.5616 3.08729 10.4728M3.08729 10.4728L3.01652 10.5435C3.01654 10.5435 3.01655 10.5435 3.01657 10.5435C3.01658 10.5436 3.0166 10.5436 3.01661 10.5436L3.08729 10.4728Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
);

export default reportSMLIcon;
