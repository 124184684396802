import React from 'react';

const ReccomendedIcon = ({ ...props }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1952 22.3659H9.6592C6.7192 22.3659 4.2832 20.0139 4.2832 17.0739C4.2832 16.7379 4.5352 16.4019 4.9552 16.4019C5.3752 16.4019 5.6272 16.6539 5.6272 17.0739C5.6272 19.3419 7.4752 21.1059 9.7432 21.1059H14.2792C14.8672 21.1059 15.5392 20.7698 15.8752 20.2659L20.4112 13.4619C20.7472 12.8739 20.9992 12.2019 20.9992 11.5299V11.1939C20.9992 10.2699 20.2432 9.59785 19.3192 9.59785H14.0272C13.6912 9.59785 13.3552 9.42985 13.1872 9.17785C13.0192 8.92585 12.9352 8.58985 13.0192 8.33785L14.1112 3.46585C14.2792 2.96185 13.8592 2.54185 13.7752 2.45785L12.5152 1.36585C12.4312 1.36585 12.3472 1.28185 12.2632 1.28185C12.1792 1.28185 12.0952 1.36585 12.0112 1.44985C8.2312 7.49785 5.7112 10.3539 5.5432 10.5219C5.2912 10.7739 4.9552 10.7739 4.6192 10.6059C4.2832 10.4378 4.3672 10.0179 4.5352 9.68185C4.5352 9.68185 7.1392 6.74185 10.8352 0.777851C11.0872 0.357851 11.5072 0.105851 11.9272 0.0218508C12.3472 -0.0621492 12.8512 0.105851 13.1872 0.357851L14.4472 1.44985C15.1192 2.12185 15.3712 2.96185 15.1192 3.71785L14.1112 8.33785H19.1512C20.7472 8.33785 22.0912 9.59785 22.0912 11.1939V11.5299C22.0912 12.3699 21.8392 13.3779 21.3352 14.1339L16.7992 20.9378C16.2952 21.6938 15.2032 22.3659 14.1952 22.3659Z"
      fill="currentColor"
    />
    <path
      d="M4.62 21.442H1.092C0.504 21.442 0 21.022 0 20.35V8.59005C0 8.00205 0.42 7.49805 1.092 7.49805H4.62C5.208 7.49805 5.712 7.91805 5.712 8.59005V20.35C5.712 20.854 5.208 21.442 4.62 21.442ZM1.344 20.182H4.452V8.75805H1.344V20.182Z"
      fill="currentColor"
    />
  </svg>
);

export default ReccomendedIcon;
