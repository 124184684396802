import React from 'react';

const HospitalIcon = ({ ...props }) => (
  <svg viewBox="0 0 22 23" fill="currentColor" {...props}>
    <path d="M18.132 22.08H13.68C13.344 22.08 13.008 21.828 13.008 21.408C13.008 20.988 13.26 20.736 13.68 20.736H17.46V2.59202C17.46 1.83602 16.872 1.33202 16.2 1.33202H5.95201C5.19601 1.33202 4.69201 1.92002 4.69201 2.59202V20.82H8.47201C8.80801 20.82 9.14401 21.072 9.14401 21.492C9.14401 21.912 8.80801 22.08 8.47201 22.08H4.02001C3.68401 22.08 3.34801 21.828 3.34801 21.408V2.59202C3.34801 1.16402 4.44001 0.0720215 5.86801 0.0720215H16.2C17.628 0.0720215 18.72 1.16402 18.72 2.59202V21.492C18.72 21.828 18.468 22.08 18.132 22.08Z" />
    <path d="M21.324 22.08H15.108V20.82H20.652V7.54802C20.652 6.79202 20.064 6.20402 19.392 6.20402H18.048V4.94402H19.392C20.82 4.94402 21.912 6.03602 21.912 7.54802V21.492C21.912 21.828 21.66 22.08 21.324 22.08Z" />
    <path d="M8.47201 22.08L0.828006 22.08C0.492006 22.08 0.156006 21.828 0.156006 21.408V7.54802C0.156006 6.12002 1.24801 4.94402 2.67601 4.94402H4.02001V6.20402H2.76001C2.00401 6.20402 1.50001 6.79202 1.50001 7.54802V20.82L8.47201 20.82V22.08Z" />
    <path d="M13.68 22.08C13.344 22.08 13.008 21.828 13.008 21.408V18.384H9.06001V21.408C9.06001 21.744 8.80801 22.08 8.38801 22.08C7.96801 22.08 7.80001 21.828 7.80001 21.492V17.796C7.80001 17.46 8.05201 17.124 8.47201 17.124H13.68C14.016 17.124 14.352 17.376 14.352 17.796V21.492C14.352 21.828 14.016 22.08 13.68 22.08Z" />
    <path d="M8.38801 10.068H6.70801V11.748H8.38801V10.068Z" />
    <path d="M8.38801 13.596H6.70801V15.276H8.38801V13.596Z" />
    <path d="M11.916 10.068H10.236V11.748H11.916V10.068Z" />
    <path d="M11.916 13.596H10.236V15.276H11.916V13.596Z" />
    <path d="M15.36 10.068H13.68V11.748H15.36V10.068Z" />
    <path d="M15.36 13.596H13.68V15.276H15.36V13.596Z" />
    <path d="M13.68 4.86002V6.62402H11.916V8.30402H10.236V6.62402H8.472V4.86002H10.236V3.09602H11.916V4.86002H13.68Z" />
  </svg>
);

export default HospitalIcon;
