import React, { useRef } from 'react';

import {
  CheckboxMain,
  CheckboxInput,
  CheckboxLabel,
  CheckboxDisplay,
  CheckboxDisplayIcon,
} from './styles';
import { TCheckboxProps } from './types';

const Checkbox = ({
  label,
  color = 'primary',
  size = 'medium',
  value,
  onValue,
  helper,
  disabled,
  required,
  ...props
}: TCheckboxProps) => {
  const checkboxRef = useRef<null | HTMLInputElement>(null);

  const handleClick = () => {
    if (checkboxRef.current) {
      checkboxRef.current.click();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onValue) {
      onValue(e.target.checked);
    }
  };

  return (
    <CheckboxMain {...props} onClick={handleClick}>
      <CheckboxInput
        hidden
        color={color}
        ref={checkboxRef}
        type="checkbox"
        checked={value}
        disabled={disabled}
        onChange={handleChange}
      />
      <CheckboxDisplay size={size} color={color} checked={value}>
        <CheckboxDisplayIcon checked={value}/>
      </CheckboxDisplay>
      {label && (
        <CheckboxLabel
          onClick={handleClick}
          helper={helper}
          required={required}
        >
          {label}
        </CheckboxLabel>
      )}
    </CheckboxMain>
  );
};
export default Checkbox;
