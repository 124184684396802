import React from 'react';

const MonthlyIcon = ({ ...props }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1515_3115)">
      <path
        d="M5.32826 4.01143C5.75421 4.01143 6.09984 3.66616 6.09984 3.24021V0.771309C6.09984 0.345314 5.75421 0 5.32826 0C4.90231 0 4.55664 0.345314 4.55664 0.771264V3.24017C4.55664 3.66612 4.90231 4.01143 5.32826 4.01143Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.00064"
      />
      <path
        d="M11.5001 4.01143C11.926 4.01143 12.2717 3.66616 12.2717 3.24021V0.771309C12.2717 0.345314 11.926 0 11.5001 0C11.0741 0 10.7285 0.345314 10.7285 0.771264V3.24017C10.7285 3.66612 11.0741 4.01143 11.5001 4.01143Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.00064"
      />
      <path
        d="M17.672 4.01143C18.098 4.01143 18.4435 3.66616 18.4435 3.24021V0.771309C18.4436 0.345314 18.098 0 17.672 0C17.246 0 16.9004 0.345314 16.9004 0.771264V3.24017C16.9004 3.66612 17.246 4.01143 17.672 4.01143Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.00064"
      />
      <path
        d="M19.1818 2.01697H19.0599V3.24019C19.0599 4.00557 18.4372 4.62855 17.6712 4.62855C16.9058 4.62855 16.2825 4.00552 16.2825 3.24019V2.01697H12.8886V3.24019C12.8886 4.00557 12.2652 4.62855 11.4998 4.62855C10.7345 4.62855 10.1111 4.00552 10.1111 3.24019V2.01697H6.71719V3.24019C6.71719 4.00557 6.09386 4.62855 5.32848 4.62855C4.5631 4.62855 3.93967 4.00557 3.93967 3.24019V2.01697H3.81847C2.15268 2.01697 0.802734 3.36727 0.802734 5.03271V19.9843C0.802734 21.6497 2.15268 23 3.81847 23H19.1818C20.8469 23 22.1976 21.6498 22.1976 19.9843V5.03271C22.1975 3.36727 20.8469 2.01697 19.1818 2.01697ZM20.2533 19.7554C20.2533 20.4729 19.6698 21.0565 18.9526 21.0565H4.04771C3.33017 21.0565 2.74628 20.473 2.74628 19.7554V6.42906H20.2533V19.7554Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.00064"
      />
      <path
        d="M9.09721 18.0994C8.58585 18.0994 8.1313 18.0118 7.73358 17.8366C7.33822 17.6615 7.02572 17.4176 6.79608 17.1051C6.56644 16.7926 6.44451 16.4316 6.43031 16.022H7.76554C7.77737 16.2185 7.84248 16.3902 7.96085 16.5369C8.07922 16.6813 8.23665 16.7938 8.43315 16.8743C8.62965 16.9548 8.84982 16.995 9.09366 16.995C9.35408 16.995 9.5849 16.95 9.78613 16.8601C9.98736 16.7678 10.1448 16.6399 10.2584 16.4766C10.3721 16.3132 10.4277 16.125 10.4253 15.9119C10.4277 15.6918 10.3709 15.4976 10.2549 15.3295C10.1389 15.1615 9.97079 15.0301 9.75062 14.9354C9.53282 14.8407 9.27003 14.7933 8.96227 14.7933H8.31951V13.7777H8.96227C9.21558 13.7777 9.43694 13.7339 9.62633 13.6463C9.81809 13.5587 9.96842 13.4356 10.0773 13.277C10.1862 13.116 10.2395 12.9302 10.2371 12.7195C10.2395 12.5135 10.1933 12.3348 10.0986 12.1832C10.0063 12.0294 9.87491 11.9098 9.70446 11.8246C9.53637 11.7393 9.33869 11.6967 9.11142 11.6967C8.88888 11.6967 8.68291 11.737 8.49352 11.8175C8.30413 11.898 8.15143 12.0128 8.03542 12.1619C7.91942 12.3087 7.85787 12.4839 7.85076 12.6875H6.58301C6.59248 12.2803 6.70967 11.9228 6.93457 11.6151C7.16184 11.3049 7.46487 11.0634 7.84366 10.8906C8.22245 10.7154 8.6474 10.6278 9.11852 10.6278C9.60384 10.6278 10.0252 10.719 10.3827 10.9013C10.7426 11.0812 11.0207 11.3239 11.2172 11.6293C11.4137 11.9347 11.512 12.272 11.512 12.6413C11.5144 13.0509 11.3936 13.3942 11.1498 13.6712C10.9083 13.9482 10.5911 14.1293 10.1981 14.2145V14.2713C10.7094 14.3423 11.1012 14.5317 11.3735 14.8395C11.6481 15.1449 11.7842 15.5249 11.7819 15.9794C11.7819 16.3866 11.6659 16.7512 11.4339 17.0732C11.2042 17.3928 10.887 17.6437 10.4822 17.826C10.0797 18.0083 9.61805 18.0994 9.09721 18.0994ZM15.9589 10.7273V18H14.6414V12.0092H14.5988L12.8978 13.0959V11.8885L14.7053 10.7273H15.9589Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1515_3115">
        <rect width="23" height="23" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default MonthlyIcon;
