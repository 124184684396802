import React from 'react';

const ElectronicsIcon = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1862_4571)">
      <path
        d="M13.3313 17.0157C13.6222 17.0157 13.8579 16.7799 13.8579 16.489C13.8579 16.1982 13.6222 15.9624 13.3313 15.9624C13.0405 15.9624 12.8047 16.1982 12.8047 16.489C12.8047 16.7799 13.0405 17.0157 13.3313 17.0157Z"
        fill="currentColor"
      />
      <path
        d="M4.37819 20.1756C4.66904 20.1756 4.90482 19.9398 4.90482 19.6489C4.90482 19.3581 4.66904 19.1223 4.37819 19.1223C4.08734 19.1223 3.85156 19.3581 3.85156 19.6489C3.85156 19.9398 4.08734 20.1756 4.37819 20.1756Z"
        fill="currentColor"
      />
      <path
        d="M22.2827 2.79626H4.37681C3.4815 2.79626 2.79688 3.48089 2.79688 4.3762V9.64267C2.79688 9.95867 3.00755 10.1693 3.3235 10.1693C3.63945 10.1693 3.85013 9.95862 3.85013 9.64267V4.37624C3.85013 4.06025 4.06081 3.84961 4.37676 3.84961H22.2827C22.5987 3.84961 22.8093 4.06029 22.8093 4.37624V17.0158C22.8093 17.3317 22.5987 17.5424 22.2827 17.5424H9.64324C9.32724 17.5424 9.11661 17.7531 9.11661 18.069C9.11661 18.385 9.32729 18.5956 9.64324 18.5956H10.6965V20.1756C10.6965 20.4916 10.4859 20.7022 10.1699 20.7022H9.64328C9.32729 20.7022 9.11665 20.9129 9.11665 21.2288C9.11665 21.5448 9.32733 21.7555 9.64328 21.7555H17.0163C17.3323 21.7555 17.5429 21.5448 17.5429 21.2288C17.5429 20.9129 17.3323 20.7022 17.0163 20.7022H16.4897C16.1737 20.7022 15.9631 20.4915 15.9631 20.1756V18.5956H22.2828C23.1781 18.5956 23.8627 17.911 23.8627 17.0157V4.37624C23.8627 3.48094 23.1781 2.79626 22.2827 2.79626ZM11.6445 20.7023C11.6972 20.5443 11.7498 20.3863 11.7498 20.1756V18.5957H14.9097V20.1756C14.9097 20.3863 14.9623 20.5443 15.015 20.7023H11.6445Z"
        fill="currentColor"
      />
      <path
        d="M21.23 14.3824H9.64382C9.32782 14.3824 9.11719 14.5931 9.11719 14.9091C9.11719 15.225 9.32787 15.4357 9.64382 15.4357H21.23C21.546 15.4357 21.7567 15.225 21.7567 14.9091C21.7567 14.5931 21.546 14.3824 21.23 14.3824Z"
        fill="currentColor"
      />
      <path
        d="M6.48451 11.2227H2.27134C1.37603 11.2227 0.691406 11.9073 0.691406 12.8026V20.1756C0.691406 21.0709 1.37603 21.7556 2.27134 21.7556H6.48451C7.37982 21.7556 8.06444 21.0709 8.06444 20.1756V12.8026C8.06444 11.9073 7.37982 11.2227 6.48451 11.2227ZM7.01114 20.1756C7.01114 20.4916 6.80046 20.7023 6.48451 20.7023H2.27134C1.95534 20.7023 1.74471 20.4916 1.74471 20.1756V12.8026C1.74471 12.4866 1.95539 12.276 2.27134 12.276H6.48451C6.8005 12.276 7.01114 12.4866 7.01114 12.8026V20.1756Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1862_4571">
        <rect
          width="23.1724"
          height="23.1724"
          fill="currentColor"
          transform="translate(0.691406 0.689697)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default ElectronicsIcon;
