import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux'
import store, { persistor } from './redux/store'
import './index.css';
import '../src/assets/scss/style.scss'
import '../src/assets/scss/responsive.scss'
import App from './App';
import Theme from './theme';
import reportWebVitals from './reportWebVitals';
import { AppcontextProvider, AuthInit } from "./context/AppContext";
import { ThemeProvider } from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={Theme}>
          <AppcontextProvider>
            <AuthInit>
              <App />
            </AuthInit>
          </AppcontextProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
