import React from 'react';

const DailyIcon = ({ ...props }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.03 12.7996C24.9181 12.7996 24.8108 12.8441 24.7317 12.9232C24.6526 13.0023 24.6081 13.1096 24.6081 13.2215C24.6144 16.0146 23.5957 18.7128 21.7451 20.8049C19.8946 22.897 17.3409 24.2374 14.568 24.5722C11.795 24.907 8.99567 24.2128 6.70042 22.6212C4.40518 21.0297 2.77359 18.6514 2.11492 15.9371C1.45624 13.2228 1.81625 10.3612 3.12672 7.89468C4.43718 5.42814 6.60701 3.52808 9.22495 2.55465C11.8429 1.58122 14.727 1.60207 17.3306 2.61325C19.9342 3.62442 22.0763 5.55565 23.351 8.04089L22.1444 7.72026C22.0325 7.69117 21.9136 7.70772 21.814 7.76627C21.7143 7.82481 21.6419 7.92056 21.6128 8.03245C21.5837 8.14434 21.6003 8.2632 21.6588 8.36289C21.7174 8.46257 21.8131 8.53492 21.925 8.56401L24.085 9.13776H24.1947C24.269 9.1374 24.3419 9.11699 24.4056 9.0787C24.501 9.02297 24.5706 8.93209 24.5997 8.82557L25.165 6.72464C25.1941 6.61275 25.1776 6.49389 25.119 6.3942C25.0605 6.29451 24.9647 6.22217 24.8528 6.19307C24.7409 6.16398 24.6221 6.18053 24.5224 6.23908C24.4227 6.29762 24.3504 6.39337 24.3213 6.50526L24.0597 7.55995C22.6748 4.91196 20.3681 2.86286 17.5753 1.7998C14.7825 0.736726 11.6971 0.733304 8.90197 1.79018C6.10683 2.84705 3.79556 4.89102 2.4048 7.53593C1.01405 10.1808 0.640132 13.2435 1.35369 16.1453C2.06726 19.0472 3.81889 21.5872 6.27764 23.2855C8.7364 24.9838 11.732 25.7228 14.6985 25.3629C17.665 25.0029 20.397 23.569 22.3783 21.332C24.3596 19.095 25.453 16.2098 25.4519 13.2215C25.4519 13.1096 25.4074 13.0023 25.3283 12.9232C25.2492 12.8441 25.1419 12.7996 25.03 12.7996Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.48"
    />
    <path
      d="M7.64631 17V16.0483L10.1712 13.5732C10.4126 13.3293 10.6139 13.1127 10.7749 12.9233C10.9358 12.7339 11.0566 12.5504 11.1371 12.3729C11.2176 12.1953 11.2578 12.0059 11.2578 11.8047C11.2578 11.575 11.2057 11.3786 11.1016 11.2152C10.9974 11.0495 10.8542 10.9216 10.6719 10.8317C10.4896 10.7417 10.2824 10.6967 10.0504 10.6967C9.81132 10.6967 9.6018 10.7464 9.42188 10.8459C9.24195 10.9429 9.10227 11.0814 9.00284 11.2614C8.90578 11.4413 8.85724 11.6555 8.85724 11.9041H7.60369C7.60369 11.4425 7.70904 11.0412 7.91974 10.7003C8.13045 10.3594 8.42045 10.0954 8.78977 9.90838C9.16146 9.72135 9.58759 9.62784 10.0682 9.62784C10.5559 9.62784 10.9844 9.71899 11.3537 9.90128C11.723 10.0836 12.0095 10.3333 12.2131 10.6506C12.419 10.9678 12.522 11.33 12.522 11.7372C12.522 12.0095 12.4699 12.277 12.3658 12.5398C12.2616 12.8026 12.0781 13.0937 11.8153 13.4134C11.5549 13.733 11.1892 14.12 10.718 14.5746L9.46449 15.8494V15.8991H12.6321V17H7.64631ZM13.5696 15.6506V14.603L16.6555 9.72727H17.5291V11.2188H16.9964L14.919 14.5107V14.5675H19.2266V15.6506H13.5696ZM17.0391 17V15.331L17.0533 14.8622V9.72727H18.2962V17H17.0391Z"
      fill="currentColor"
    />
  </svg>
);

export default DailyIcon;
