import React from 'react';

const TotalIcon = ({ ...props }) => (
  <svg
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.4676 21.5H2.01963C1.68363 21.5 1.34763 21.332 1.17963 20.996C1.01163 20.66 1.09563 20.324 1.26363 19.988L8.31963 11L1.01163 2.012C0.759633 1.76 0.759632 1.34 0.927632 1.004C1.09563 0.668 1.43163 0.5 1.76763 0.5H16.5516C17.0556 0.5 17.4756 0.92 17.4756 1.424V5.624C17.4756 6.128 17.0556 6.548 16.5516 6.548C16.0476 6.548 15.6276 6.128 15.6276 5.624V2.348H3.61563L10.1676 10.412C10.4196 10.748 10.4196 11.252 10.1676 11.588L3.86763 19.652H15.4596V16.628C15.4596 16.124 15.8796 15.704 16.3836 15.704C16.8876 15.704 17.3076 16.124 17.3076 16.628V20.576C17.3916 21.08 16.9716 21.5 16.4676 21.5Z"
      fill="white"
    />
  </svg>
);

export default TotalIcon;
