import axiosInstance from '../../axios/axiosInstance';
import {
  TLoginParams,
  TLoginResponse,
  TConfirmResetPassword,
  TResendEmailConfirmation,
  TRegisterParams,
  TResetPassword,
} from './types';

const AuthorizationAPI = {
  login: async (body: TLoginParams) => {
    const { data } = await axiosInstance.post(`/auth/local`, body);
    return data as TLoginResponse;
  },

  register: async (body: TRegisterParams) => {
    const { data } = await axiosInstance.post(`/auth/local/register`, body);
    // return data as TRegisterParams;
    return data;
  },

  logout: async () => {
    await axiosInstance.post(`/auth/logout`);
  },

  emailConfirmation: async (confirmation: string) => {
    await axiosInstance.get(`/api/auth/email-confirmation`, {
      params: {
        confirmation,
      },
    });
  },

  resendEmailConfirmation: async (
    body: TResendEmailConfirmation,
  ) => {
    await axiosInstance.post(`/auth/send-email-confirmation`, body);
  },

  forgotPassword: async (body: TResetPassword) => {
    await axiosInstance.post(`/auth/forgot-password`, body);
  },

  confirmResetPassword: async (body: TConfirmResetPassword) => {
    await axiosInstance.post(`/auth/reset-password`, body);
  },

  me: async () => {
    const { data } = await axiosInstance.get(`/auth/me`);
    return data;
  },

};

export default AuthorizationAPI;
