import React from 'react';

const CreateIcon = ({ ...props }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <g clipPath="url(#clip0_62_6615)">
      <g clipPath="url(#clip1_62_6615)">
        <path
          d="M6.99968 1.58337C5.73058 1.58337 4.48998 1.9597 3.43477 2.66478C2.37955 3.36985 1.55711 4.372 1.07145 5.54449C0.585788 6.71698 0.458717 8.00716 0.706305 9.25187C0.953894 10.4966 1.56502 11.6399 2.46241 12.5373C3.3598 13.4347 4.50314 14.0458 5.74785 14.2934C6.99256 14.541 8.28274 14.4139 9.45523 13.9283C10.6277 13.4426 11.6299 12.6202 12.3349 11.565C13.04 10.5097 13.4163 9.26914 13.4163 8.00004C13.4144 6.29883 12.7378 4.66784 11.5348 3.4649C10.3319 2.26196 8.70089 1.5853 6.99968 1.58337ZM6.99968 13.25C5.96133 13.25 4.94629 12.9421 4.08293 12.3653C3.21958 11.7884 2.54667 10.9684 2.14931 10.0091C1.75195 9.04982 1.64798 7.99422 1.85055 6.97582C2.05313 5.95742 2.55314 5.02196 3.28737 4.28773C4.02159 3.5535 4.95705 3.05349 5.97545 2.85092C6.99385 2.64835 8.04945 2.75231 9.00877 3.14967C9.96808 3.54703 10.788 4.21994 11.3649 5.0833C11.9418 5.94666 12.2497 6.96169 12.2497 8.00004C12.2481 9.39193 11.6944 10.7263 10.7102 11.7106C9.72598 12.6948 8.39157 13.2484 6.99968 13.25Z"
          fill="currentColor"
        />
        <path
          d="M6.24573 4.6709L5.4209 5.49573L7.92515 7.99998L5.4209 10.5042L6.24573 11.3291L9.1624 8.4124C9.27176 8.30301 9.33319 8.15466 9.33319 7.99998C9.33319 7.8453 9.27176 7.69696 9.1624 7.58757L6.24573 4.6709Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_62_6615">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_62_6615">
        <rect width="14" height="14" fill="white" transform="translate(0 1)" />
      </clipPath>
    </defs>
  </svg>
);

export default CreateIcon;
