import React from 'react';

const CampaignsSmallIcon = ({ ...props }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66006 9.14329C1.19694 9.14291 0 7.94571 0 6.48253C0 5.01915 1.19731 3.82184 2.66072 3.82184L4.95093 3.82187C4.95093 5.57393 5.01034 7.41364 4.95093 9.14329H2.66006ZM4.98467 10.1506C5.03062 10.5918 5.11941 11.0289 5.25824 11.4462C5.46833 12.0776 5.807 12.6965 6.30521 13.1467C6.40109 13.2334 6.43875 13.3549 6.41364 13.481L6.19207 14.595C6.16821 14.715 6.09271 14.8066 5.97913 14.8524C5.86218 14.8995 5.7471 14.886 5.64384 14.8142C4.82364 14.2433 4.22463 13.4045 3.83305 12.4937C3.51452 11.7529 3.32631 10.9559 3.25174 10.1506H4.98467ZM5.95821 3.70396V9.24033C8.78912 9.85651 11.1433 11.5472 13.3928 12.9491C13.6651 13.1189 14.0073 13.012 14.2713 12.8374C14.5353 12.6627 14.6795 12.4021 14.6795 12.0988V0.920418C14.6795 0.618256 14.5364 0.358292 14.2738 0.18354C14.0112 0.00881635 13.6895 -0.0646826 13.3987 0.0681909C11.3556 1.00229 8.75387 2.96932 5.95821 3.70396Z"
      fill="currentColor"
    />
  </svg>
);

export default CampaignsSmallIcon;
