import React from 'react';

const CheckIcon = ({ ...props }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1195_2871)">
      <g clipPath="url(#clip1_1195_2871)">
        <path
          d="M7.56257 9.6249C7.46022 9.625 7.3611 9.58908 7.28257 9.52344L2.03257 5.14844C1.98709 5.1121 1.94933 5.06706 1.92148 5.01594C1.89363 4.96483 1.87626 4.90867 1.87039 4.85075C1.86452 4.79284 1.87027 4.73434 1.8873 4.67867C1.90432 4.62301 1.93229 4.5713 1.96955 4.52658C2.00681 4.48186 2.05262 4.44503 2.1043 4.41823C2.15598 4.39144 2.21248 4.37523 2.27051 4.37056C2.32853 4.36588 2.3869 4.37283 2.4422 4.39099C2.49751 4.40916 2.54863 4.43818 2.59257 4.47635L7.56257 8.61786L9.5182 6.98773C9.60765 6.91652 9.72143 6.88315 9.83518 6.89478C9.94892 6.90641 10.0536 6.9621 10.1268 7.04994C10.2 7.13778 10.2359 7.25079 10.2268 7.36477C10.2177 7.47874 10.1644 7.58464 10.0782 7.65978L7.84257 9.52353C7.76405 9.58917 7.66492 9.62506 7.56257 9.6249Z"
          fill="currentColor"
        />
        <path
          d="M10.625 7.875C9.93277 7.875 9.25608 7.66973 8.68051 7.28514C8.10493 6.90056 7.65633 6.35393 7.39142 5.71439C7.12652 5.07485 7.0572 4.37112 7.19225 3.69219C7.3273 3.01325 7.66064 2.38961 8.15013 1.90013C8.63961 1.41064 9.26325 1.0773 9.94219 0.942253C10.6211 0.807205 11.3249 0.876516 11.9644 1.14142C12.6039 1.40633 13.1506 1.85493 13.5351 2.43051C13.9197 3.00608 14.125 3.68277 14.125 4.375C14.1239 5.30293 13.7548 6.19255 13.0987 6.8487C12.4426 7.50485 11.5529 7.87394 10.625 7.875ZM10.625 1.75C10.1058 1.75 9.59831 1.90396 9.16663 2.19239C8.73495 2.48083 8.3985 2.8908 8.19982 3.37046C8.00114 3.85011 7.94915 4.37791 8.05044 4.88711C8.15173 5.39631 8.40173 5.86404 8.76885 6.23116C9.13596 6.59827 9.60369 6.84828 10.1129 6.94956C10.6221 7.05085 11.1499 6.99887 11.6295 6.80019C12.1092 6.60151 12.5192 6.26505 12.8076 5.83337C13.096 5.40169 13.25 4.89418 13.25 4.375C13.2492 3.67904 12.9724 3.01181 12.4803 2.51969C11.9882 2.02757 11.321 1.75077 10.625 1.75Z"
          fill="currentColor"
        />
        <path
          d="M10.188 5.6875C10.1305 5.68757 10.0736 5.67628 10.0205 5.65428C9.96745 5.63228 9.91924 5.60001 9.87867 5.55932L9.00367 4.68432C8.92398 4.6018 8.87988 4.49129 8.88088 4.37658C8.88187 4.26187 8.92788 4.15214 9.009 4.07102C9.09012 3.98991 9.19985 3.94389 9.31456 3.9429C9.42927 3.9419 9.53978 3.986 9.6223 4.06569L10.188 4.63134L11.6287 3.19069C11.669 3.14891 11.7173 3.11558 11.7707 3.09265C11.8241 3.06972 11.8815 3.05765 11.9396 3.05715C11.9976 3.05664 12.0553 3.06771 12.109 3.08971C12.1628 3.11171 12.2116 3.14419 12.2527 3.18527C12.2938 3.22635 12.3263 3.2752 12.3483 3.32896C12.3703 3.38273 12.3813 3.44034 12.3808 3.49843C12.3803 3.55652 12.3683 3.61393 12.3453 3.66731C12.3224 3.72068 12.2891 3.76896 12.2473 3.80932L10.4973 5.55932C10.4567 5.60001 10.4085 5.63228 10.3554 5.65428C10.3024 5.67628 10.2454 5.68757 10.188 5.6875Z"
          fill="currentColor"
        />
        <path
          d="M12.8125 13.125H2.3125C2.19647 13.125 2.08519 13.0789 2.00314 12.9969C1.92109 12.9148 1.875 12.8035 1.875 12.6875V4.8125C1.875 4.69647 1.92109 4.58519 2.00314 4.50314C2.08519 4.42109 2.19647 4.375 2.3125 4.375H7.5975C7.71353 4.375 7.82481 4.42109 7.90686 4.50314C7.98891 4.58519 8.035 4.69647 8.035 4.8125C8.035 4.92853 7.98891 5.03981 7.90686 5.12186C7.82481 5.20391 7.71353 5.25 7.5975 5.25H2.75V12.25H12.375V6.51438C12.375 6.39834 12.4211 6.28706 12.5031 6.20502C12.5852 6.12297 12.6965 6.07688 12.8125 6.07688C12.9285 6.07688 13.0398 6.12297 13.1219 6.20502C13.2039 6.28706 13.25 6.39834 13.25 6.51438V12.6875C13.25 12.8035 13.2039 12.9148 13.1219 12.9969C13.0398 13.0789 12.9285 13.125 12.8125 13.125Z"
          fill="currentColor"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1195_2871">
        <rect
          width="14"
          height="14"
          fill="currentColor"
          transform="translate(0 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_1195_2871">
        <rect
          width="14"
          height="14"
          fill="currentColor"
          transform="translate(1)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CheckIcon;
