import React from 'react';
import {
  SidebarItemNestedMain,
  SidebarItemNestedOuter,
  SidebarItemNestedIcon,
  SidebarItemNestedLabel,
  SidebarItemNestedDropDown,
  SidebarItemNestedDropDownSubItem,
  SidebarItemNestedDropDownSubItemLabel,
  SidebarItemNestedExpandIcon,
} from './styles';
import { TSidebarItemNestedProps } from './types';
import { ArrowDownIcon } from '../../../../svg';
import { useLocation } from 'react-router-dom';
import { TSidebarSubItem } from '../../types';

const SidebarItemNested = ({
  icon,
  label,
  items,
  item,
  action,
  ...props
}: TSidebarItemNestedProps) => {
  const router = useLocation();

  const active = items.map((x) => x.location).includes(router.pathname);
  const { state } = item;

  return (
    <SidebarItemNestedOuter active={active} {...props}>
      <SidebarItemNestedMain>
        <SidebarItemNestedIcon>{icon}</SidebarItemNestedIcon>
        <SidebarItemNestedLabel>{label}</SidebarItemNestedLabel>
        <SidebarItemNestedExpandIcon expanded={state}>
          <ArrowDownIcon />
        </SidebarItemNestedExpandIcon>
      </SidebarItemNestedMain>
      <SidebarItemNestedDropDown expanded={state}>
        {items.map((x: TSidebarSubItem) => (
          <SidebarItemNestedDropDownSubItem
            to={x.location}
            key={x.id}
          >
            <SidebarItemNestedDropDownSubItemLabel
              active={x.location === router.pathname}
            >
              <div className='d-flex justify-content-between'>
                {x.label}
              </div>
            </SidebarItemNestedDropDownSubItemLabel>
          </SidebarItemNestedDropDownSubItem>
        ))}
      </SidebarItemNestedDropDown>
    </SidebarItemNestedOuter>
  );
};

export default SidebarItemNested;

