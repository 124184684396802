import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from './context/AppContext';
import { CalendarProvider } from './context/CalendarContext'; // Import CalendarProvider
import './App.css';

// Import your pages
import Home from './pages/home';
import Login from './pages/login';
import Register from './pages/register';
import ForgotPassword from './pages/forgotPassword';
import ResetPassword from './pages/resetPassword';
import ChatPage from './pages/chat';
import CalendarPage from './pages/calendar';

function App() {
  const { currentUser } = useAuth();

  return (
    <div className="main" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'hidden' }}>
      <CalendarProvider> {/* Wrap Router with CalendarProvider */}
        <Router>
          <Routes>
            {currentUser ? (
              <>
                <Route path='/chat/:id' element={<ChatPage />} />
                <Route path='/calendar' element={<CalendarPage />} />
                <Route index element={<Navigate to='/chat' />} />
                <Route path='*' element={<Navigate to='/chat/0' />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot_password" element={<ForgotPassword />} />
                <Route path="/reset_password" element={<ResetPassword />} />
                <Route path='*' element={<Navigate to='/login' />} />
              </>
            )}
          </Routes>
        </Router>
      </CalendarProvider>
    </div>
  );
}

export default App;

