import React from 'react';

const DotsIcon = ({ ...props }) => (
  <svg width="11" height="4" viewBox="0 0 11 4" fill="none" {...props}>
    <path
      d="M8.25406 2.2156C8.25406 1.85636 8.39677 1.51183 8.65079 1.25781C8.90481 1.00379 9.24934 0.861084 9.60858 0.861084C9.96782 0.861084 10.3123 1.00379 10.5664 1.25781C10.8204 1.51183 10.9631 1.85636 10.9631 2.2156C10.9631 2.57484 10.8204 2.91937 10.5664 3.17339C10.3123 3.42741 9.96782 3.57012 9.60858 3.57012C9.24934 3.57012 8.90481 3.42741 8.65079 3.17339C8.39677 2.91937 8.25406 2.57484 8.25406 2.2156ZM4.19051 2.2156C4.19051 1.85636 4.33321 1.51183 4.58724 1.25781C4.84126 1.00379 5.18578 0.861084 5.54502 0.861084C5.90427 0.861084 6.24879 1.00379 6.50281 1.25781C6.75683 1.51183 6.89954 1.85636 6.89954 2.2156C6.89954 2.57484 6.75683 2.91937 6.50281 3.17339C6.24879 3.42741 5.90427 3.57012 5.54502 3.57012C5.18578 3.57012 4.84126 3.42741 4.58724 3.17339C4.33321 2.91937 4.19051 2.57484 4.19051 2.2156ZM0.126953 2.2156C0.126953 1.85636 0.269661 1.51183 0.523682 1.25781C0.777704 1.00379 1.12223 0.861084 1.48147 0.861084C1.84071 0.861084 2.18524 1.00379 2.43926 1.25781C2.69328 1.51183 2.83599 1.85636 2.83599 2.2156C2.83599 2.57484 2.69328 2.91937 2.43926 3.17339C2.18524 3.42741 1.84071 3.57012 1.48147 3.57012C1.12223 3.57012 0.777704 3.42741 0.523682 3.17339C0.269661 2.91937 0.126953 2.57484 0.126953 2.2156Z"
      fill="currentColor"
    />
  </svg>
);

export default DotsIcon;
