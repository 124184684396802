import React from 'react';

const InpreparationIcon = ({ ...props }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3293 17.552C7.71734 17.552 4.77734 14.612 4.77734 11C4.77734 7.388 7.71734 4.448 11.3293 4.448C14.9413 4.448 17.8813 7.388 17.8813 11C17.8813 14.612 14.9413 17.552 11.3293 17.552ZM11.3293 5.792C8.47334 5.792 6.03734 8.144 6.03734 11.084C6.03734 14.024 8.38934 16.376 11.3293 16.376C14.2693 16.376 16.6213 14.024 16.6213 11.084C16.6213 8.144 14.1853 5.792 11.3293 5.792Z"
      fill="currentColor"
    />
    <path
      d="M11.3281 21.668C5.95213 21.668 1.41612 17.636 0.828125 12.26C0.828125 11.924 0.996125 11.588 1.41612 11.588C1.75212 11.504 2.08812 11.756 2.08812 12.176C2.67613 16.88 6.62413 20.408 11.4121 20.408C16.5361 20.408 20.7361 16.208 20.7361 11C20.7361 5.876 16.5361 1.676 11.4121 1.676C7.71612 1.676 4.44013 3.776 2.84413 7.22C2.67613 7.556 2.34013 7.64 2.00413 7.556C1.66813 7.388 1.58413 7.052 1.66813 6.716C3.43213 2.852 7.21212 0.5 11.4121 0.5C17.2921 0.5 21.9961 5.288 21.9961 11.084C21.9121 16.88 17.2081 21.668 11.3281 21.668Z"
      fill="currentColor"
    />
    <path
      d="M3.93667 6.63199C4.35667 6.79999 4.44067 7.13599 4.10467 7.47199L1.92067 9.57199C1.58467 9.90799 1.24867 9.73999 1.08067 9.31999L0.408673 6.29599C0.324673 5.87599 0.576672 5.62399 0.996673 5.70799L3.93667 6.63199Z"
      fill="currentColor"
    />
    <path
      d="M11.3289 11.672H8.05286C7.71686 11.672 7.38086 11.42 7.38086 11C7.38086 10.58 7.63286 10.328 8.05286 10.328H10.7409V8.31201C10.7409 7.97601 10.9929 7.64001 11.4129 7.64001C11.8329 7.64001 12.0849 7.89201 12.0849 8.31201V11C12.0009 11.336 11.6649 11.672 11.3289 11.672Z"
      fill="currentColor"
    />
  </svg>
);

export default InpreparationIcon;
