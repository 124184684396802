import React from 'react';

const HelpIcon = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 21 21" fill="none" {...props}>
    <path
      d="M10.4128 1.53906C8.68226 1.53906 6.99054 2.05224 5.55161 3.0137C4.11268 3.97517 2.99117 5.34173 2.3289 6.94058C1.66663 8.53944 1.49335 10.2988 1.83097 11.9961C2.1686 13.6934 3.00195 15.2525 4.22566 16.4762C5.44937 17.7 7.00847 18.5333 8.70581 18.8709C10.4031 19.2086 12.1625 19.0353 13.7613 18.373C15.3602 17.7107 16.7267 16.5892 17.6882 15.1503C18.6497 13.7114 19.1628 12.0196 19.1628 10.2891C19.1628 7.96842 18.241 5.74282 16.6 4.10188C14.9591 2.46093 12.7335 1.53906 10.4128 1.53906ZM10.4128 17.7891C8.92949 17.7891 7.47944 17.3492 6.24607 16.5251C5.0127 15.701 4.05141 14.5296 3.48375 13.1592C2.91609 11.7887 2.76757 10.2807 3.05696 8.82589C3.34635 7.37103 4.06065 6.03466 5.10954 4.98576C6.15844 3.93687 7.49481 3.22256 8.94967 2.93317C10.4045 2.64378 11.9125 2.79231 13.283 3.35997C14.6534 3.92762 15.8248 4.88892 16.6489 6.12229C17.473 7.35565 17.9128 8.8057 17.9128 10.2891C17.9128 12.2782 17.1227 14.1858 15.7161 15.5924C14.3096 16.9989 12.402 17.7891 10.4128 17.7891Z"
      fill="currentColor"
    />
    <path
      d="M10.4128 15.9141C10.9306 15.9141 11.3503 15.4943 11.3503 14.9766C11.3503 14.4588 10.9306 14.0391 10.4128 14.0391C9.89507 14.0391 9.47534 14.4588 9.47534 14.9766C9.47534 15.4943 9.89507 15.9141 10.4128 15.9141Z"
      fill="currentColor"
    />
    <path
      d="M11.0378 5.28907H10.1003C9.73077 5.28825 9.36468 5.36043 9.02308 5.50148C8.68148 5.64253 8.3711 5.84967 8.10977 6.11099C7.84844 6.37232 7.64131 6.6827 7.50026 7.0243C7.35921 7.3659 7.28702 7.732 7.28785 8.10157V8.41407H8.53785V8.10157C8.53785 7.68717 8.70247 7.28974 8.99549 6.99672C9.28852 6.70369 9.68595 6.53907 10.1003 6.53907H11.0378C11.4522 6.53907 11.8497 6.70369 12.1427 6.99672C12.4357 7.28974 12.6003 7.68717 12.6003 8.10157C12.6003 8.51597 12.4357 8.9134 12.1427 9.20642C11.8497 9.49945 11.4522 9.66407 11.0378 9.66407H9.78785V12.4766H11.0378V10.9141C11.7838 10.9141 12.4991 10.6178 13.0266 10.0903C13.554 9.56286 13.8503 8.84749 13.8503 8.10157C13.8503 7.35565 13.554 6.64028 13.0266 6.11283C12.4991 5.58539 11.7838 5.28907 11.0378 5.28907Z"
      fill="currentColor"
    />
  </svg>
);

export default HelpIcon;
