import React from 'react';

const InfluencersIcon = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 25 25" fill="none" {...props}>
    <path
      d="M12.1646 14.3937C13.4072 14.3937 14.4146 13.3863 14.4146 12.1437C14.4146 10.9011 13.4072 9.89371 12.1646 9.89371C10.9219 9.89371 9.91455 10.9011 9.91455 12.1437C9.91455 13.3863 10.9219 14.3937 12.1646 14.3937Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4362 21.8937H11.8925C11.545 21.8938 11.2084 21.7732 10.9399 21.5527C10.6715 21.3321 10.4879 21.0252 10.4206 20.6843L9.52061 16.1843C9.47767 15.9669 9.48345 15.7426 9.53753 15.5277C9.59162 15.3127 9.69267 15.1124 9.83342 14.9412C9.97416 14.77 10.1511 14.6321 10.3515 14.5374C10.5519 14.4427 10.7708 14.3937 10.9925 14.3937H13.3362C13.5579 14.3937 13.7768 14.4427 13.9772 14.5374C14.1776 14.6321 14.3546 14.77 14.4953 14.9412C14.6361 15.1124 14.7371 15.3127 14.7912 15.5277C14.8453 15.7426 14.8511 15.9669 14.8081 16.1843L13.9081 20.6843C13.8408 21.0252 13.6572 21.3321 13.3888 21.5527C13.1204 21.7732 12.7837 21.8938 12.4362 21.8937V21.8937Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.56768 15.0687C6.2165 14.1597 6.09203 13.1788 6.20502 12.2108C6.31801 11.2429 6.66506 10.3171 7.21617 9.51336C7.76727 8.70965 8.50585 8.05228 9.36803 7.59808C10.2302 7.14387 11.19 6.90651 12.1646 6.90651C13.1391 6.90651 14.0989 7.14387 14.9611 7.59808C15.8233 8.05228 16.5618 8.70965 17.1129 9.51336C17.664 10.3171 18.0111 11.2429 18.1241 12.2108C18.2371 13.1788 18.1126 14.1597 17.7614 15.0687"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.32705 20.4781C5.68486 19.4313 4.42649 17.8802 3.74083 16.0575C3.05518 14.2347 2.97923 12.2388 3.5244 10.3692C4.06957 8.49966 5.20643 6.85736 6.76434 5.68886C8.32224 4.52036 10.2171 3.8887 12.1646 3.8887C14.112 3.8887 16.0069 4.52036 17.5648 5.68886C19.1227 6.85736 20.2595 8.49966 20.8047 10.3692C21.3499 12.2388 21.2739 14.2347 20.5883 16.0575C19.9026 17.8802 18.6442 19.4313 17.0021 20.4781"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InfluencersIcon;
