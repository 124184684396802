import React, { useContext, useEffect, useState } from 'react';
import {
  NotificationsCardMain,
  NotificationsCardList,
  NotificationCard,
} from './styles';
import { Notification } from './elements';
import {
  TNotification,
  TNotificationsCardProps,
} from './types';
import { useAuth } from '../../../context/AppContext';
import { useAppSelector } from '../../../hooks/use-dispatch';
import { format } from "date-fns";

import { CalendarContext } from '../../../context/CalendarContext';



const NotificationsCard = ({ ...props }: TNotificationsCardProps) => {

  const [notifications, setNotifications] = useState<TNotification[]>([]);
  const store = useAppSelector((state: any) => state.calendar);

  const { selectedDate } = useContext(CalendarContext);

  useEffect(() => {
    getNotifications();
    //eslint-disable-next-line
  }, [selectedDate]);

  const { calendarApi } = useAuth();

  const getNotifications = async () => {
  if (calendarApi) {
      const events = await calendarApi.getEvents();
      // Filter events based on selectedDate
      const filters = events.filter((item: any) => 
        format(new Date(item.start), "yyyy-MM-dd") === format(selectedDate, "yyyy-MM-dd")
      );
      let notifications = filters.map((item: any) => {
        return {
          start: item.start,
          title: item.title,
          description: item.extendedProps.description,
          color: item.extendedProps.event_color,
          allDay: item.allDay
        }
      })

      notifications.sort(function (a: any, b: any) {
        if (a.allDay && !b.allDay) {
          return 1
        } else if (!a.allDay && b.allDay) {
          return -1
        } else {
          return new Date(a.start).getTime() - new Date(b.start).getTime()
        }
      })

      setNotifications([...notifications]);
    }
  };


  useEffect(() => {
    getNotifications();
    //eslint-disable-next-line
  }, [selectedDate, calendarApi, store?.events]);

  return (
    <NotificationsCardMain title="Notifications" {...props}>
      <NotificationsCardList>
        {notifications.map(({ ...x }, index: any) => (
          <Notification key={index} {...x} />
        ))}
      </NotificationsCardList>
    </NotificationsCardMain>
  );
};

export default NotificationsCard;
