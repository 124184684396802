import React from 'react';

const ClientRegisteredIcon = ({ ...props }) => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.4197 21.148H3.53173C2.10373 21.148 0.927734 19.972 0.927734 18.46V5.35595C0.927734 3.92795 2.10373 2.75195 3.53173 2.75195H5.71573C6.05173 2.75195 6.38773 3.00395 6.38773 3.42395C6.38773 3.84395 6.13573 4.09595 5.71573 4.09595H3.53173C2.77573 4.09595 2.18773 4.76795 2.18773 5.43995V18.544C2.18773 19.3 2.85973 19.972 3.53173 19.972H10.4197C10.7557 19.972 11.0917 20.224 11.0917 20.644C11.0917 21.064 10.7557 21.148 10.4197 21.148Z"
      fill="currentColor"
    />
    <path
      d="M16.4677 14.26C16.1317 14.26 15.7957 14.008 15.7957 13.588V5.27198C15.7957 4.51598 15.1237 3.92798 14.3677 3.92798H12.1837C11.8477 3.92798 11.5117 3.67598 11.5117 3.25598C11.5117 2.83598 11.7637 2.58398 12.1837 2.58398H14.3677C15.7957 2.58398 17.0557 3.75998 17.0557 5.18798V13.504C17.1397 14.008 16.8037 14.26 16.4677 14.26Z"
      fill="currentColor"
    />
    <path
      d="M12.267 6.28001H5.71497C5.37897 6.28001 5.04297 6.02801 5.04297 5.60801V3.34001C5.04297 1.82801 6.30297 0.484009 7.89897 0.484009H10.083C11.595 0.484009 12.939 1.74401 12.939 3.34001V5.60801C12.939 6.02801 12.603 6.28001 12.267 6.28001ZM6.30297 5.02001H11.679V3.42401C11.679 2.58401 10.923 1.82801 10.083 1.82801H7.89897C7.05897 1.82801 6.30297 2.58401 6.30297 3.42401V5.02001Z"
      fill="currentColor"
    />
    <path
      d="M13.1907 9.30396H6.97473C6.63873 9.30396 6.30273 9.05196 6.30273 8.63196C6.30273 8.21196 6.55473 7.95996 6.97473 7.95996H13.1907C13.5267 7.95996 13.8627 8.21196 13.8627 8.63196C13.8627 9.05196 13.5267 9.30396 13.1907 9.30396Z"
      fill="currentColor"
    />
    <path
      d="M13.1911 12.0759H4.87512C4.53912 12.0759 4.20312 11.8239 4.20312 11.4039C4.20312 10.9839 4.45512 10.7319 4.87512 10.7319H13.1911C13.5271 10.7319 13.8631 10.9839 13.8631 11.4039C13.8631 11.8239 13.5271 12.0759 13.1911 12.0759Z"
      fill="currentColor"
    />
    <path
      d="M14.4521 23.5C11.5961 23.5 9.32812 21.148 9.32812 18.292C9.32812 15.436 11.6801 13.168 14.4521 13.168C17.3081 13.168 19.6601 15.52 19.6601 18.292C19.6601 21.148 17.3081 23.5 14.4521 23.5ZM14.4521 14.428C12.2681 14.428 10.5881 16.192 10.5881 18.292C10.5881 20.476 12.3521 22.24 14.4521 22.24C16.6361 22.24 18.4001 20.476 18.4001 18.292C18.4001 16.108 16.6361 14.428 14.4521 14.428Z"
      fill="currentColor"
    />
    <path
      d="M13.7793 20.8121C13.6113 20.8121 13.4433 20.7281 13.3593 20.6441L11.5113 18.8801C11.2593 18.6281 11.2593 18.2081 11.5113 17.9561C11.7633 17.7041 12.1833 17.7041 12.4353 17.9561L13.7793 19.3001L16.2993 16.3601C16.5513 16.1081 16.8873 16.0241 17.2233 16.2761C17.5593 16.5281 17.5593 16.8641 17.3073 17.2001L14.2833 20.5601C14.1153 20.6441 13.9473 20.7281 13.7793 20.8121Z"
      fill="currentColor"
    />
  </svg>
);

export default ClientRegisteredIcon;
