// src/context/CalendarContext.tsx
import React, { createContext, useState } from 'react';

interface CalendarContextType {
  selectedDate: Date;
  updateSelectedDate: (date: Date) => void;
}

export const CalendarContext = createContext<CalendarContextType>({
  selectedDate: new Date(),
  updateSelectedDate: date => {}
});

export const CalendarProvider: React.FC = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const updateSelectedDate = (date: Date) => {
    setSelectedDate(date);
  };

  return (
    <CalendarContext.Provider value={{ selectedDate, updateSelectedDate }}>
      {children}
    </CalendarContext.Provider>
  );
};

