import React from 'react';

const LogoutIcon = ({ ...props }) => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.16667 7.66671V8.75004C1.16667 9.94712 2.13679 10.9167 3.33334 10.9167H8.75C9.94709 10.9167 10.9167 9.94712 10.9167 8.75004C10.9167 6.94683 10.9167 4.05379 10.9167 2.25004C10.9167 1.0535 9.94709 0.083374 8.75 0.083374C7.20138 0.083374 4.8825 0.083374 3.33334 0.083374C2.13679 0.083374 1.16667 1.0535 1.16667 2.25004V3.33337C1.16667 3.63237 1.40934 3.87504 1.70834 3.87504C2.00734 3.87504 2.25 3.63237 2.25 3.33337C2.25 3.33337 2.25 2.82583 2.25 2.25004C2.25 1.65204 2.73534 1.16671 3.33334 1.16671H8.75C9.34854 1.16671 9.83334 1.65204 9.83334 2.25004V8.75004C9.83334 9.34858 9.34854 9.83337 8.75 9.83337C7.20138 9.83337 4.8825 9.83337 3.33334 9.83337C2.73534 9.83337 2.25 9.34858 2.25 8.75004C2.25 8.17479 2.25 7.66671 2.25 7.66671C2.25 7.36825 2.00734 7.12504 1.70834 7.12504C1.40934 7.12504 1.16667 7.36825 1.16667 7.66671ZM5.27575 4.95837L4.57538 4.25854C4.36413 4.04675 4.36413 3.70387 4.57538 3.49208C4.78717 3.28083 5.13004 3.28083 5.34129 3.49208L6.96629 5.11708C7.17809 5.32887 7.17809 5.67175 6.96629 5.88354L5.34129 7.50854C5.13004 7.71979 4.78717 7.71979 4.57538 7.50854C4.36413 7.29675 4.36413 6.95387 4.57538 6.74208L5.27575 6.04171H0.625003C0.326003 6.04171 0.0833359 5.79904 0.0833359 5.50004C0.0833359 5.20158 0.326003 4.95837 0.625003 4.95837H5.27575Z"
      fill="currentColor"
    />
  </svg>
);

export default LogoutIcon;
