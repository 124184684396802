import React from 'react';

const ReportsAIcon = ({ ...props }) => (
  <svg
    width="16"
    height="22"
    viewBox="0 0 16 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5425 0.0431461C1.07033 0.135073 0.548019 0.506956 0.288954 0.941517C-0.0202524 1.45547 0.000639906 0.699167 0.000639906 10.6565C-0.00353856 16.4729 0.0131753 19.6611 0.0382461 19.8073C0.134351 20.3129 0.493699 20.8269 0.940796 21.0985C1.45057 21.4035 1.00765 21.3868 7.99823 21.3868C13.4261 21.3909 14.2994 21.3826 14.5083 21.3283C15.1894 21.1528 15.72 20.6555 15.9206 20.0079C15.9958 19.7613 16 19.5065 16 12.7666V5.77601L15.8788 5.61305C15.6783 5.34145 10.4343 0.135073 10.288 0.0640392C10.1669 0.00554085 9.72812 -0.00281715 5.94242 0.00136185C3.62755 0.00554085 1.64696 0.0222549 1.5425 0.0431461ZM9.39802 3.04747C9.39802 4.2049 9.41473 4.91942 9.44398 5.06149C9.61112 5.8554 10.3047 6.4947 11.1154 6.59916C11.2867 6.62006 12.1725 6.64095 13.0876 6.64095H14.7465L14.7381 13.1677L14.7256 19.6987L14.6253 19.8407C14.571 19.9159 14.4498 20.0162 14.3537 20.058C14.1907 20.1332 13.94 20.1374 8.00659 20.1374C2.85453 20.1374 1.80992 20.129 1.6971 20.0789C1.53414 20.0121 1.35446 19.824 1.29597 19.6652C1.23329 19.4981 1.23329 1.89003 1.29597 1.72289C1.35446 1.56829 1.53414 1.38026 1.68874 1.30922C1.78902 1.26326 2.53279 1.2549 5.60815 1.25072H9.39802V3.04747ZM13.8272 5.35816C13.8272 5.37905 13.2297 5.38741 12.5026 5.37905C11.0193 5.36652 11.0151 5.36234 10.7894 5.06567L10.6725 4.91524L10.6599 3.53217L10.6474 2.1491L12.2394 3.73691C13.1127 4.61021 13.8272 5.34145 13.8272 5.35816Z"
      fill="currentColor"
    />
    <path
      d="M3.10525 2.71723C2.9214 2.77155 2.72919 3.00554 2.69159 3.21446C2.65398 3.44428 2.79605 3.73677 3.00497 3.84541C3.14286 3.92063 3.28075 3.9248 5.31567 3.9248C7.20851 3.9248 7.501 3.91645 7.62218 3.85795C8.1027 3.63231 8.03167 2.85512 7.5219 2.71305C7.32969 2.65873 3.28493 2.66291 3.10525 2.71723Z"
      fill="currentColor"
    />
    <path
      d="M2.97068 5.47104C2.79519 5.57968 2.65312 5.88471 2.68655 6.07274C2.72415 6.26495 2.85786 6.46134 3.01247 6.5449C3.13782 6.61176 3.28407 6.62012 4.65461 6.62012C6.02514 6.62012 6.17139 6.61176 6.29674 6.5449C6.6937 6.32762 6.69788 5.65489 6.3051 5.45015C6.20482 5.4 5.92068 5.38747 4.64625 5.38747C3.17543 5.38747 3.10439 5.39165 2.97068 5.47104Z"
      fill="currentColor"
    />
    <path
      d="M9.31419 8.45816C8.17765 8.70051 7.27092 9.40249 6.76533 10.422C6.4603 11.0404 6.41016 11.2619 6.41016 12.0307C6.41016 12.5781 6.42269 12.7286 6.50626 13.0043C6.96171 14.5796 8.37404 15.666 9.97021 15.666C11.9717 15.666 13.4592 14.3038 13.6389 12.2982C13.6891 11.7299 13.5177 10.8691 13.2503 10.3385C12.799 9.43592 11.9759 8.78825 10.9438 8.52083C10.4925 8.40384 9.70697 8.37041 9.31419 8.45816ZM9.40612 10.9945C9.42283 12.7703 9.30584 12.6575 11.1109 12.6575H12.3269L12.3018 12.7494C12.2141 13.067 11.9968 13.4096 11.7001 13.7063C11.207 14.2036 10.7098 14.4125 10.0329 14.4125C9.4688 14.4125 9.0217 14.2662 8.57043 13.9361C7.85173 13.4055 7.50492 12.4235 7.7013 11.4834C7.85591 10.7605 8.39075 10.1295 9.09273 9.83705C9.22644 9.78273 9.34762 9.73677 9.36433 9.73259C9.38105 9.73259 9.39776 10.3009 9.40612 10.9945ZM11.1109 9.90808C11.6082 10.1463 12.1556 10.7897 12.3018 11.3079L12.3269 11.404H11.4912H10.6513V10.5641V9.72841L10.7474 9.75348C10.7975 9.7702 10.9605 9.83705 11.1109 9.90808Z"
      fill="currentColor"
    />
    <path
      d="M3.01746 8.7971C2.72079 8.94334 2.59126 9.42805 2.77929 9.67875C2.98821 9.95871 3.00911 9.96289 3.98269 9.96289C4.76406 9.96289 4.88106 9.95453 4.98134 9.88768C5.36576 9.63697 5.34905 9.05198 4.96045 8.81381C4.8351 8.7386 4.74735 8.73024 3.98687 8.73024C3.30578 8.73024 3.1261 8.74278 3.01746 8.7971Z"
      fill="currentColor"
    />
    <path
      d="M3.08921 11.4506C2.82179 11.5593 2.63794 11.8894 2.69643 12.1443C2.7424 12.3406 2.87611 12.5203 3.02235 12.5913C3.13935 12.6457 3.31903 12.6582 3.99594 12.6582C4.74806 12.6582 4.83581 12.6498 4.96116 12.5746C5.34976 12.3365 5.36648 11.7515 4.98206 11.5008C4.88595 11.4339 4.75642 11.4256 4.02937 11.4172C3.52795 11.4088 3.15189 11.4256 3.08921 11.4506Z"
      fill="currentColor"
    />
    <path
      d="M3.11653 14.137C2.89089 14.2206 2.76972 14.3459 2.7154 14.5548C2.64436 14.8348 2.74882 15.1148 2.97446 15.2485C3.09564 15.3237 3.19174 15.332 3.96476 15.332C4.71688 15.332 4.84224 15.3237 4.96341 15.2568C5.35619 15.0479 5.35201 14.4128 4.96341 14.183C4.83806 14.1077 4.7336 14.0994 4.04833 14.091C3.46752 14.0827 3.2377 14.0952 3.11653 14.137Z"
      fill="currentColor"
    />
    <path
      d="M3.00497 17.5432C2.79605 17.6518 2.65398 17.9443 2.69159 18.1741C2.72919 18.3997 2.9214 18.6212 3.13033 18.6755C3.23897 18.7048 4.89364 18.7131 8.09017 18.709C12.8035 18.6964 12.8912 18.6964 13.0291 18.6128C13.4135 18.3872 13.4177 17.8148 13.0375 17.5599L12.8954 17.4638H8.02331C3.34343 17.4638 3.14286 17.4679 3.00497 17.5432Z"
      fill="currentColor"
    />
  </svg>
);

export default ReportsAIcon;
