import React from 'react';

const ContactedIcon = ({ ...props }) => (
  <svg viewBox="0 0 15 24" fill="currentColor" {...props}>
    <path d="M11.444 23.928H2.70802C1.19602 23.928 0.02002 22.752 0.02002 21.24V2.76002C0.02002 1.24802 1.19602 0.0720215 2.70802 0.0720215H11.444C12.956 0.0720215 14.132 1.24802 14.132 2.76002V21.24C14.132 22.752 12.956 23.928 11.444 23.928ZM2.70802 0.912021C1.70002 0.912021 0.86002 1.75202 0.86002 2.76002V21.24C0.86002 22.248 1.70002 23.088 2.70802 23.088H11.444C12.452 23.088 13.292 22.248 13.292 21.24V2.76002C13.292 1.75202 12.452 0.912021 11.444 0.912021H2.70802Z" />
    <path d="M13.712 19.98H0.44002C0.18802 19.98 0.0200195 19.812 0.0200195 19.56C0.0200195 19.308 0.18802 19.14 0.44002 19.14H13.712C13.964 19.14 14.132 19.308 14.132 19.56C14.132 19.812 13.964 19.98 13.712 19.98Z" />
    <path d="M13.712 4.94402H0.44002C0.18802 4.94402 0.0200195 4.77602 0.0200195 4.52402C0.0200195 4.27202 0.18802 4.10402 0.44002 4.10402H13.712C13.964 4.10402 14.132 4.27202 14.132 4.52402C14.132 4.77602 13.964 4.94402 13.712 4.94402Z" />
    <path d="M8.50402 3.01202H5.64802C5.39602 3.01202 5.22802 2.84402 5.22802 2.59202C5.22802 2.34002 5.39602 2.17202 5.64802 2.17202H8.50402C8.75602 2.17202 8.92402 2.34002 8.92402 2.59202C8.92402 2.84402 8.75602 3.01202 8.50402 3.01202Z" />
    <path d="M7.07602 22.332C7.49355 22.332 7.83202 21.9936 7.83202 21.576C7.83202 21.1585 7.49355 20.82 7.07602 20.82C6.65849 20.82 6.32002 21.1585 6.32002 21.576C6.32002 21.9936 6.65849 22.332 7.07602 22.332Z" />
    <path d="M6.90802 16.116C6.82402 16.116 6.65602 16.032 6.57202 16.032L3.12802 12.588C2.54002 12 2.37202 11.412 2.37202 10.908C2.37202 10.74 2.37202 10.572 2.54002 10.488C2.54002 10.152 2.62402 9.90002 2.70802 9.56402C3.12802 8.64002 4.05202 7.96802 4.97602 7.88402H5.64802C6.15202 7.96802 6.57202 8.13602 6.90802 8.38802C7.41202 8.05202 7.91602 7.88402 8.25202 7.88402H9.00802C9.68002 7.96802 10.1 8.22002 10.52 8.55602C11.192 9.22802 11.528 10.068 11.36 10.908C11.36 11.16 11.276 11.496 11.108 11.832C11.024 12.168 10.772 12.336 10.52 12.588L7.16002 15.948C7.16002 16.032 6.99202 16.116 6.90802 16.116ZM3.29602 10.992C3.38002 11.412 3.54802 11.664 3.80002 11.916L6.90802 15.024L9.93202 12C10.184 11.748 10.268 11.664 10.352 11.496C10.352 11.496 10.352 11.412 10.436 11.412C10.52 11.244 10.604 11.076 10.604 10.908C10.688 10.236 10.436 9.64802 9.93202 9.22802C9.68002 8.97602 9.42802 8.80802 9.00802 8.72402H8.42002C7.91602 8.80802 7.58002 8.97602 7.32802 9.31202C7.16002 9.48002 6.90802 9.48002 6.74002 9.31202C6.40402 8.97602 6.06802 8.80802 5.73202 8.72402H5.14402C4.55602 8.80802 3.88402 9.22802 3.63202 9.90002C3.54802 10.068 3.46402 10.32 3.46402 10.488V10.656C3.38002 10.824 3.29602 10.908 3.29602 10.992Z" />
    <path d="M8.50402 12.672C8.42002 12.672 8.25202 12.672 8.16802 12.588L6.06802 10.488L4.97602 11.58C4.89202 11.664 4.80802 11.664 4.64002 11.664H1.53202C1.28002 11.664 1.11202 11.496 1.11202 11.244C1.11202 10.992 1.28002 10.824 1.53202 10.824H4.47202L5.81602 9.48002C5.98402 9.31202 6.23602 9.31202 6.40402 9.48002L8.58802 11.664L9.34402 10.908C9.42802 10.824 9.51202 10.824 9.59602 10.824H12.62C12.872 10.824 13.04 10.992 13.04 11.244C13.04 11.496 12.872 11.664 12.62 11.664H9.76402L8.75602 12.588C8.67202 12.672 8.58802 12.672 8.50402 12.672Z" />
  </svg>
);

export default ContactedIcon;
