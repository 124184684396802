import React from 'react';
import {
  NavigationMain,
  NavigationItems,
  NavigationProfileOuter,
  NavigationProfile,
  NavigationProfileName,
  NavigationProfileDropdown,
  NavigationProvileIcon,
  NavigationNotification,
  NavigationRouteName,
  NavigationMenu,
  NavigationMenuButton,
} from './styles';
import { TNavigationProps } from './types';
import { ArrowDownIcon, BellIcon, LogoutIcon, MenuIcon } from '../../svg';
import { useMenu, useModal } from '../../../hooks';
import { useAuth } from '../../../context/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { NotificationModal } from './elements';

const Navigation = ({ ...props }: TNavigationProps) => {
  const [menuRef, open, setOpen, buttonRef] = useMenu(false);
  const [nModal, openNModal, closeNModal] = useModal(false);
  const { handleMobileMenu, showMobileMenu } = useAuth();

  const navigate = useNavigate();
  const router = useLocation();

  const { currentUser, logout } = useAuth();
  const handleMenu = () => {
    setOpen(!open);
  };

  const handleLogout = async () => {
    await logout();
    navigate("/login", { replace: true });
  };

  const getRouteName = () => {
    if (router.pathname.includes('/chat')) {
      return "Chat"
    }
    if (router.pathname === '/calendar') {
      return "Calendar"
    }
    if (router.pathname === '/') {

      const base = router.pathname.replace("/", "");
      return `${base.charAt(0).toUpperCase()}${base.slice(1)}`
    }
  }

  const handleSidebar = () => {
    handleMobileMenu(!showMobileMenu);
  };

  return (
    <NavigationMain {...props}>
      <NavigationMenu>
        <NavigationMenuButton onClick={handleSidebar}>
          <MenuIcon />
        </NavigationMenuButton>
        <NavigationRouteName>{getRouteName()}</NavigationRouteName>
      </NavigationMenu>
      <NavigationItems>
        <NavigationNotification onClick={openNModal}>
          <BellIcon />
        </NavigationNotification>
        <NavigationProfileOuter>
          <NavigationProfile onClick={handleMenu}>
            <NavigationProfileName>{currentUser?.username}</NavigationProfileName>
            <NavigationProvileIcon ref={buttonRef} expanded={open}>
              <ArrowDownIcon />
            </NavigationProvileIcon>
          </NavigationProfile>
          {open && (
            <NavigationProfileDropdown
              items={[
                {
                  icon: <LogoutIcon />,
                  label: 'Logout',
                  action: handleLogout,
                },
              ]}
              ref={menuRef}
            />
          )}
        </NavigationProfileOuter>
      </NavigationItems>
      {nModal && <NotificationModal onClose={closeNModal} />}
    </NavigationMain>
  );
};

export default Navigation;
