import React from 'react';

const DeclineIcon = ({ ...props }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <g clipPath="url(#clip0_61_6102)">
      <path
        d="M7.25 1C3.80381 1 1 3.80381 1 7.25C1 10.6962 3.80381 13.5 7.25 13.5C10.6962 13.5 13.5 10.6962 13.5 7.25C13.5 3.80381 10.6962 1 7.25 1ZM7.25 12.7188C4.23447 12.7188 1.78125 10.2655 1.78125 7.25C1.78125 4.23447 4.23447 1.78125 7.25 1.78125C10.2655 1.78125 12.7188 4.23447 12.7188 7.25C12.7188 10.2655 10.2655 12.7188 7.25 12.7188ZM9.4793 5.57305L7.80234 7.25L9.4793 8.92695C9.63193 9.07959 9.63193 9.32676 9.4793 9.4793C9.40303 9.55557 9.30303 9.59375 9.20312 9.59375C9.10322 9.59375 9.00322 9.55557 8.92695 9.4793L7.25 7.80234L5.57305 9.4793C5.49678 9.55557 5.39678 9.59375 5.29688 9.59375C5.19697 9.59375 5.09697 9.55557 5.0207 9.4793C4.86807 9.32666 4.86807 9.07949 5.0207 8.92695L6.69766 7.25L5.0207 5.57305C4.86807 5.42041 4.86807 5.17324 5.0207 5.0207C5.17334 4.86816 5.42051 4.86807 5.57305 5.0207L7.25 6.69766L8.92695 5.0207C9.07959 4.86807 9.32676 4.86807 9.4793 5.0207C9.63184 5.17334 9.63193 5.42051 9.4793 5.57305Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.2"
      />
    </g>
    <defs>
      <clipPath id="clip0_61_6102">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default DeclineIcon;
