import React from 'react';

const DeleteIcon = ({ ...props }) => (
  <svg width="24" height="23" viewBox="0 0 24 23" fill="none" {...props}>
    <path
      d="M15.852 21.3231H8.49201C7.75565 21.3124 7.05044 21.0242 6.51741 20.5161C5.98438 20.0079 5.66281 19.3173 5.61701 18.5822L4.74493 5.31891C4.74046 5.22275 4.75509 5.12666 4.78799 5.03618C4.82089 4.94571 4.8714 4.86266 4.93659 4.79183C5.00376 4.71842 5.08508 4.65936 5.17566 4.61819C5.26624 4.57701 5.36421 4.55458 5.46368 4.55225H18.8803C18.9789 4.55203 19.0765 4.57208 19.1669 4.61116C19.2574 4.65024 19.3389 4.70751 19.4063 4.77941C19.4737 4.85132 19.5256 4.93631 19.5588 5.02912C19.5919 5.12193 19.6057 5.22058 19.5991 5.31891L18.7653 18.5822C18.7191 19.3238 18.3921 20.0199 17.8509 20.529C17.3098 21.0382 16.595 21.3221 15.852 21.3231ZM6.26868 5.98975L7.00659 18.496C7.03097 18.8731 7.19817 19.2268 7.47414 19.4849C7.75011 19.7431 8.11411 19.8864 8.49201 19.8856H15.852C16.2292 19.8841 16.592 19.7401 16.8675 19.4823C17.143 19.2246 17.3108 18.8723 17.3374 18.496L18.1137 6.03766L6.26868 5.98975Z"
      fill="currentColor"
    />
    <path
      d="M20.7969 5.98975H3.54688C3.35625 5.98975 3.17343 5.91402 3.03864 5.77923C2.90385 5.64444 2.82813 5.46162 2.82812 5.271C2.82813 5.08037 2.90385 4.89755 3.03864 4.76276C3.17343 4.62797 3.35625 4.55225 3.54688 4.55225H20.7969C20.9875 4.55225 21.1703 4.62797 21.3051 4.76276C21.4399 4.89755 21.5156 5.08037 21.5156 5.271C21.5156 5.46162 21.4399 5.64444 21.3051 5.77923C21.1703 5.91402 20.9875 5.98975 20.7969 5.98975Z"
      fill="currentColor"
    />
    <path
      d="M15.0469 5.98975H9.29688C9.10702 5.98726 8.92564 5.91074 8.79139 5.77648C8.65713 5.64223 8.58061 5.46085 8.57812 5.271V3.546C8.59027 3.0542 8.79106 2.5859 9.13892 2.23804C9.48678 1.89018 9.95508 1.68939 10.4469 1.67725H13.8969C14.3969 1.68975 14.8721 1.89719 15.2213 2.25529C15.5704 2.61339 15.7658 3.09378 15.7656 3.59391V5.271C15.7631 5.46085 15.6866 5.64223 15.5524 5.77648C15.4181 5.91074 15.2367 5.98726 15.0469 5.98975ZM10.0156 4.55225H14.3281V3.59391C14.3281 3.47954 14.2827 3.36985 14.2018 3.28897C14.1209 3.2081 14.0112 3.16266 13.8969 3.16266H10.4469C10.3325 3.16266 10.2228 3.2081 10.1419 3.28897C10.0611 3.36985 10.0156 3.47954 10.0156 3.59391V4.55225Z"
      fill="currentColor"
    />
    <path
      d="M15.0469 17.4894C14.857 17.4869 14.6756 17.4104 14.5414 17.2762C14.4071 17.1419 14.3306 16.9605 14.3281 16.7707V9.104C14.3281 8.91338 14.4039 8.73056 14.5386 8.59577C14.6734 8.46098 14.8563 8.38525 15.0469 8.38525C15.2375 8.38525 15.4203 8.46098 15.5551 8.59577C15.6899 8.73056 15.7656 8.91338 15.7656 9.104V16.7707C15.7631 16.9605 15.6866 17.1419 15.5524 17.2762C15.4181 17.4104 15.2367 17.4869 15.0469 17.4894Z"
      fill="currentColor"
    />
    <path
      d="M9.29688 17.4894C9.10702 17.4869 8.92564 17.4104 8.79139 17.2762C8.65713 17.1419 8.58061 16.9605 8.57812 16.7707V9.104C8.57812 8.91338 8.65385 8.73056 8.78864 8.59577C8.92343 8.46098 9.10625 8.38525 9.29688 8.38525C9.4875 8.38525 9.67032 8.46098 9.80511 8.59577C9.9399 8.73056 10.0156 8.91338 10.0156 9.104V16.7707C10.0131 16.9605 9.93662 17.1419 9.80236 17.2762C9.66811 17.4104 9.48673 17.4869 9.29688 17.4894Z"
      fill="currentColor"
    />
    <path
      d="M12.1719 17.4894C11.982 17.4869 11.8006 17.4104 11.6664 17.2762C11.5321 17.1419 11.4556 16.9605 11.4531 16.7707V9.104C11.4531 8.91338 11.5289 8.73056 11.6636 8.59577C11.7984 8.46098 11.9813 8.38525 12.1719 8.38525C12.3625 8.38525 12.5453 8.46098 12.6801 8.59577C12.8149 8.73056 12.8906 8.91338 12.8906 9.104V16.7707C12.8881 16.9605 12.8116 17.1419 12.6774 17.2762C12.5431 17.4104 12.3617 17.4869 12.1719 17.4894Z"
      fill="currentColor"
    />
  </svg>
);

export default DeleteIcon;
