import React from 'react';

const RevenueIcon = ({ ...props }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1516_3700)">
      <g clipPath="url(#clip1_1516_3700)">
        <path
          d="M1.08232 12.0133C1.18074 12.2685 1.35433 12.4879 1.58014 12.6424C1.80594 12.7968 2.07331 12.8791 2.34689 12.8783L3.86686 12.8783V21.9404C3.86686 22.4867 4.08385 23.0105 4.47009 23.3968C4.85634 23.783 5.3802 24 5.92643 24L19.9315 24C20.4778 24 21.0016 23.783 21.3879 23.3968C21.7741 23.0105 21.9911 22.4867 21.9911 21.9404V12.8783H23.5152C23.7884 12.8792 24.0554 12.7976 24.2814 12.6441C24.5073 12.4905 24.6816 12.2723 24.7814 12.018C24.8812 11.7637 24.9017 11.4852 24.8404 11.219C24.7791 10.9528 24.6388 10.7113 24.4379 10.5263L14.9556 1.7566C14.4121 1.23016 13.6857 0.934868 12.929 0.932774C12.1772 0.934814 11.4559 1.23043 10.9188 1.7566L1.4448 10.5469C1.2452 10.7274 1.10406 10.9634 1.03948 11.2247C0.974895 11.4859 0.989813 11.7605 1.08232 12.0133ZM12.0475 2.92644C12.2851 2.6952 12.6036 2.56581 12.9352 2.56581C13.2667 2.56581 13.5852 2.6952 13.8228 2.92644L22.7737 11.2306H21.1673C20.9488 11.2306 20.7392 11.3174 20.5847 11.4719C20.4302 11.6264 20.3434 11.836 20.3434 12.0545V21.9404C20.3434 22.0497 20.3001 22.1544 20.2228 22.2317C20.1456 22.3089 20.0408 22.3523 19.9315 22.3523L5.92643 22.3523C5.81719 22.3523 5.71241 22.3089 5.63516 22.2317C5.55791 22.1544 5.51452 22.0497 5.51452 21.9404V12.0545C5.51452 11.836 5.42772 11.6264 5.27322 11.4719C5.11873 11.3174 4.90918 11.2306 4.69069 11.2306L3.08422 11.2306L12.0475 2.92644Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.1"
        />
        <path
          d="M12.9293 14.9379H13.7531C13.9716 14.9379 14.1812 15.0247 14.3357 15.1792C14.4902 15.3337 14.577 15.5432 14.577 15.7617C14.577 15.9802 14.4902 16.1897 14.3357 16.3442C14.1812 16.4987 13.9716 16.5855 13.7531 16.5855H11.6935C11.4751 16.5855 11.2655 16.6723 11.111 16.8268C10.9565 16.9813 10.8697 17.1909 10.8697 17.4094C10.8697 17.6279 10.9565 17.8374 11.111 17.9919C11.2655 18.1464 11.4751 18.2332 11.6935 18.2332H12.1055V18.6451C12.1055 18.8636 12.1923 19.0731 12.3468 19.2276C12.5013 19.3821 12.7108 19.4689 12.9293 19.4689C13.1478 19.4689 13.3573 19.3821 13.5118 19.2276C13.6663 19.0731 13.7531 18.8636 13.7531 18.6451V18.2332C14.4086 18.2332 15.0372 17.9728 15.5007 17.5093C15.9642 17.0458 16.2246 16.4172 16.2246 15.7617C16.2246 15.1062 15.9642 14.4776 15.5007 14.0141C15.0372 13.5506 14.4086 13.2902 13.7531 13.2902H12.9293C12.7108 13.2902 12.5013 13.2034 12.3468 13.0489C12.1923 12.8944 12.1055 12.6849 12.1055 12.4664V12.0545C12.1055 11.836 12.1923 11.6264 12.3468 11.4719C12.5013 11.3174 12.7108 11.2306 12.9293 11.2306H14.9889C15.2074 11.2306 15.4169 11.1438 15.5714 10.9893C15.7259 10.8348 15.8127 10.6253 15.8127 10.4068C15.8127 10.1883 15.7259 9.97877 15.5714 9.82427C15.4169 9.66978 15.2074 9.58298 14.9889 9.58298H14.577V9.17107C14.577 8.95257 14.4902 8.74303 14.3357 8.58853C14.1812 8.43403 13.9716 8.34724 13.7531 8.34724C13.5346 8.34724 13.3251 8.43403 13.1706 8.58853C13.0161 8.74303 12.9293 8.95257 12.9293 9.17107V9.58298C12.2738 9.58298 11.6452 9.84337 11.1817 10.3069C10.7182 10.7704 10.4578 11.399 10.4578 12.0545V12.4664C10.4578 13.1219 10.7182 13.7505 11.1817 14.214C11.6452 14.6775 12.2738 14.9379 12.9293 14.9379Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.1"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1516_3700">
        <rect
          width="25"
          height="25"
          fill="currentColor"
          transform="matrix(-1 0 0 -1 25 25)"
        />
      </clipPath>
      <clipPath id="clip1_1516_3700">
        <rect
          width="27"
          height="27"
          fill="currentColor"
          transform="matrix(-1 0 0 -1 26 24)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RevenueIcon;
