import React from 'react';

const ContactIcon = ({ ...props }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <g clipPath="url(#clip0_93_2814)">
      <path
        d="M6.46406 5.96875C6.46406 6.79036 5.79818 7.45625 4.97656 7.45625C4.15614 7.45625 3.48906 6.78917 3.48906 5.96875C3.48906 5.14714 4.15495 4.48125 4.97656 4.48125C5.79818 4.48125 6.46406 5.14714 6.46406 5.96875ZM8.80469 6.96953H11.1016C11.3956 6.96953 11.632 7.20709 11.632 7.5C11.632 7.79285 11.3944 8.03047 11.1016 8.03047H8.80469C8.51183 8.03047 8.27422 7.79285 8.27422 7.5C8.27422 7.20715 8.51183 6.96953 8.80469 6.96953ZM2.34063 10.1797C2.34063 9.14633 3.17758 8.30937 4.21094 8.30937H5.74219C6.77554 8.30937 7.6125 9.14633 7.6125 10.1797C7.6125 10.3669 7.46068 10.5188 7.27344 10.5188H2.67969C2.4933 10.5188 2.34063 10.3661 2.34063 10.1797ZM8.80469 5.05547H11.1016C11.3956 5.05547 11.632 5.29302 11.632 5.58594C11.632 5.87879 11.3944 6.11641 11.1016 6.11641H8.80469C8.51183 6.11641 8.27422 5.87879 8.27422 5.58594C8.27422 5.29308 8.51183 5.05547 8.80469 5.05547ZM1.53125 2.18437H12.25C13.0704 2.18437 13.7375 2.85028 13.7375 3.67188V11.3281C13.7375 12.1497 13.0716 12.8156 12.25 12.8156H1.53125C0.709636 12.8156 0.04375 12.1497 0.04375 11.3281V3.67188C0.04375 2.85026 0.709636 2.18437 1.53125 2.18437ZM12.25 11.7547C12.4852 11.7547 12.6766 11.5634 12.6766 11.3281V3.67188C12.6766 3.43664 12.4852 3.24531 12.25 3.24531H1.53125C1.29601 3.24531 1.10469 3.43664 1.10469 3.67188V11.3281C1.10469 11.5634 1.29601 11.7547 1.53125 11.7547H12.25Z"
        fill="currentColor"
        stroke="white"
        strokeWidth="0.0875"
      />
    </g>
    <defs>
      <clipPath id="clip0_93_2814">
        <rect
          width="13.7812"
          height="12.25"
          fill="white"
          transform="translate(0 1.375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ContactIcon;
