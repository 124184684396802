import React, { useEffect, useState } from 'react';
import {
  SidebarMain,
  SidebarLogo,
  SidebarItems,
  SidebarLogoLink,
  SidebarCancel,
} from './styles';
import {
  SidebarItem,
  SidebarItemNested,
} from './elements';
import { DSidebarItems } from './data';
import { TSidebarProps } from './types';
import { CancelIcon } from '../../svg';
import { useAuth } from '../../../context/AppContext';



const sidebarLogoStyle = {
  width: '20vw', // Adjust based on your preference
  height: '20vw', // Same value as width to maintain square shape
  objectFit: 'contain', // Prevents image from being stretched
  maxWidth: '100px', // Maximum size
  maxHeight: '100px', // Maximum size
};


const Sidebar = ({ ...props }: TSidebarProps) => {
  const { handleMobileMenu, showMobileMenu, allChats } = useAuth();
  const [chats, setChats] = useState<any[]>([]);

  useEffect(() => {
    if (allChats && allChats !== undefined) {
      const chats = allChats?.map((item: any, index: any) => {
        return {
          id: index,
          label: item.title.length > 18 ? item.title.slice(0, 18) + "..." : item.title,
          location: `/chat/${item.chat_id}`
        }
      })
      setChats(chats)
    }

  }, [allChats])


  const handleSidebar = () => {
    if (window.innerWidth < 1200) {
      handleMobileMenu(!showMobileMenu);
    }
  };

  const nestedArray = [
    {
      id: 0,
      state: false,
    }
  ];

  const [nested, setNested] = useState<any>(nestedArray);

  const handleNested = (id: number | string) => {
    const helper = [...nested];

    helper.forEach((x: any) => {
      if (x.id === id) {
        if (x.state) return;
        x.state = !x.state;
      } else {
        x.state = false;
      }

      // return null;
    });

    setNested(helper);
  };


  return (
    <SidebarMain {...props}>
      <SidebarCancel onClick={handleSidebar}>
        <CancelIcon />
      </SidebarCancel>
      <SidebarLogoLink to="/">
        <SidebarLogo src="/logo.png" style={sidebarLogoStyle}/>
      </SidebarLogoLink>
      <SidebarItems>
        {DSidebarItems.map((x, index) =>
          x.type === 'nested' ? (
            <SidebarItemNested
              label={x.label}
              icon={x.icon}
              items={chats}
              key={x.id}
              item={nested[index]}
              onClick={() => {
                handleNested(index);
              }}
              action={handleSidebar}
            />
          ) : (
            <SidebarItem
              label={x.label}
              icon={x.icon}
              location={x.location}
              key={x.id}
              onClick={() => {
                handleSidebar()
              }}
            />
          )
        )}
      </SidebarItems>
    </SidebarMain>
  );
};

export default Sidebar;
