// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/font/Ubuntu/Ubuntu-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/font/orbitron/orbitron-medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/font/orbitron/Orbitron Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/font/orbitron/orbitron-light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/font/ag/akzidenz-grotesk(r)schulbuchmedium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./assets/font/open-sans/OpenSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./assets/font/montserrat/static/Montserrat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .main {
  background: url(/public/images/background.png) !important;
  background-position: center;
  background-size: cover;
} */

@font-face {
  font-family: 'Ubuntu';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: 'orbitron';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
  font-family: 'Orbitron';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-family: 'orbitron-small';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

@font-face {
  font-family: 'ag';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}

@font-face {
  font-family: 'open-sans';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}

@font-face {
  font-family: 'monster';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;;;GAIG;;AAEH;EACE,qBAAqB;EACrB,4CAAmD;AACrD;AACA;EACE,uBAAuB;EACvB,4CAAsD;AACxD;AACA;EACE,uBAAuB;EACvB,4CAAqD;AACvD;;AAEA;EACE,6BAA6B;EAC7B,4CAAqD;AACvD;;AAEA;EACE,iBAAiB;EACjB,4CAAqE;AACvE;;AAEA;EACE,wBAAwB;EACxB,4CAAwD;AAC1D;;AAEA;EACE,sBAAsB;EACtB,4CAA+D;AACjE","sourcesContent":["/* .main {\r\n  background: url(/public/images/background.png) !important;\r\n  background-position: center;\r\n  background-size: cover;\r\n} */\r\n\r\n@font-face {\r\n  font-family: 'Ubuntu';\r\n  src: url('./assets/font/Ubuntu/Ubuntu-Regular.ttf');\r\n}\r\n@font-face {\r\n  font-family: 'orbitron';\r\n  src: url('./assets/font/orbitron/orbitron-medium.otf');\r\n}\r\n@font-face {\r\n  font-family: 'Orbitron';\r\n  src: url('./assets/font/orbitron/Orbitron\\ Bold.ttf');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'orbitron-small';\r\n  src: url('./assets/font/orbitron/orbitron-light.ttf');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'ag';\r\n  src: url('./assets/font/ag/akzidenz-grotesk\\(r\\)schulbuchmedium.ttf');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'open-sans';\r\n  src: url('./assets/font/open-sans/OpenSans-Regular.ttf');\r\n}\r\n\r\n@font-face {\r\n  font-family: 'monster';\r\n  src: url('./assets/font/montserrat/static/Montserrat-Bold.ttf');\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
