import React from 'react';

const StartIcon = ({ ...props }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <g clipPath="url(#clip0_46_3144)">
      <path
        d="M8.48801 3.53233C8.23601 3.43604 7.95383 3.56209 7.85752 3.814C7.76121 4.06585 7.8873 4.34815 8.13916 4.44446C9.86511 5.1045 11.0586 6.7682 11.0586 8.68753C11.0586 11.1915 9.02151 13.2285 6.51758 13.2285C4.01365 13.2285 1.97656 11.1915 1.97656 8.68753C1.97656 6.76715 3.17061 5.10438 4.89583 4.44454C5.14771 4.3482 5.27378 4.0659 5.17747 3.81405C5.0811 3.56217 4.79875 3.43612 4.54697 3.5324C2.44495 4.33638 1 6.36068 1 8.68753C1 11.7374 3.46802 14.2051 6.51758 14.2051C9.56743 14.2051 12.0352 11.7371 12.0352 8.68753C12.0352 6.35965 10.5893 4.33594 8.48801 3.53233Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.3"
      />
      <path
        d="M6.48828 1.85C6.13576 1.85 5.85 2.13576 5.85 2.48828V7.2698C5.85 7.62232 6.13576 7.90808 6.48828 7.90808C6.84081 7.90808 7.12656 7.62229 7.12656 7.2698V2.48828C7.12656 2.13576 6.8408 1.85 6.48828 1.85Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </g>
    <defs>
      <clipPath id="clip0_46_3144">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default StartIcon;
