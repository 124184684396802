import React from 'react';

const BellIcon = ({ ...props }) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.58301 16.1172H10.8732"
      stroke="white"
      strokeWidth="1.37285"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.91351 8.08985C3.91262 7.45541 4.03751 6.82707 4.28101 6.24102C4.5245 5.65496 4.88178 5.12276 5.33227 4.67509C5.78276 4.22741 6.31755 3.87309 6.90585 3.63255C7.49414 3.392 8.12431 3.26998 8.76007 3.27351C11.4146 3.29358 13.5396 5.49438 13.5396 8.15005V8.62499C13.5396 11.0198 14.0423 12.4112 14.4848 13.1738C14.5318 13.255 14.5565 13.3471 14.5566 13.4409C14.5567 13.5346 14.5321 13.6268 14.4852 13.7081C14.4384 13.7894 14.371 13.857 14.2897 13.9041C14.2084 13.9512 14.1162 13.9761 14.0222 13.9765H3.43087C3.33689 13.9761 3.24465 13.9512 3.16339 13.9041C3.08213 13.857 3.0147 13.7894 2.96786 13.7081C2.92101 13.6268 2.8964 13.5346 2.89648 13.4409C2.89657 13.3471 2.92134 13.255 2.96833 13.1738C3.41076 12.4112 3.91351 11.0198 3.91351 8.62499V8.08985Z"
      stroke="white"
      strokeWidth="1.37285"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BellIcon;
