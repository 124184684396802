import React from 'react';

const CostIcon = ({ ...props }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1516_3913)">
      <g clipPath="url(#clip1_1516_3913)">
        <path
          d="M23.9177 12.9867C23.8193 12.7315 23.6457 12.5121 23.4199 12.3576C23.1941 12.2032 22.9267 12.1209 22.6531 12.1217H21.1331V3.05957C21.1331 2.51334 20.9162 1.98948 20.5299 1.60324C20.1437 1.21699 19.6198 1 19.0736 1H5.06847C4.52223 1 3.99837 1.21699 3.61213 1.60324C3.22588 1.98948 3.00889 2.51334 3.00889 3.05957V12.1217H1.48481C1.21164 12.1208 0.944563 12.2024 0.718608 12.3559C0.492653 12.5095 0.318366 12.7277 0.218604 12.982C0.118842 13.2363 0.0982621 13.5148 0.159563 13.781C0.220863 14.0472 0.361182 14.2887 0.562119 14.4737L10.0444 23.2434C10.5879 23.7698 11.3143 24.0651 12.071 24.0672C12.8228 24.0652 13.5441 23.7696 14.0812 23.2434L23.5552 14.4531C23.7548 14.2726 23.8959 14.0366 23.9605 13.7753C24.0251 13.5141 24.0102 13.2395 23.9177 12.9867ZM12.9525 22.0736C12.7149 22.3048 12.3964 22.4342 12.0648 22.4342C11.7333 22.4342 11.4148 22.3048 11.1772 22.0736L2.22625 13.7694H3.83272C4.05122 13.7694 4.26076 13.6826 4.41526 13.5281C4.56976 13.3736 4.65655 13.164 4.65655 12.9455V3.05957C4.65655 2.95033 4.69995 2.84556 4.7772 2.76831C4.85445 2.69106 4.95922 2.64766 5.06847 2.64766H19.0736C19.1828 2.64766 19.2876 2.69106 19.3648 2.76831C19.4421 2.84556 19.4855 2.95033 19.4855 3.05957V12.9455C19.4855 13.164 19.5723 13.3736 19.7268 13.5281C19.8813 13.6826 20.0908 13.7694 20.3093 13.7694H21.9158L12.9525 22.0736Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.1"
        />
        <path
          d="M12.0707 10.0622H11.2469C11.0284 10.0622 10.8188 9.97536 10.6643 9.82087C10.5098 9.66637 10.423 9.45682 10.423 9.23833C10.423 9.01984 10.5098 8.81029 10.6643 8.65579C10.8188 8.5013 11.0284 8.4145 11.2469 8.4145H13.3065C13.5249 8.4145 13.7345 8.3277 13.889 8.17321C14.0435 8.01871 14.1303 7.80916 14.1303 7.59067C14.1303 7.37218 14.0435 7.16263 13.889 7.00814C13.7345 6.85364 13.5249 6.76684 13.3065 6.76684H12.8945V6.35493C12.8945 6.13643 12.8077 5.92689 12.6532 5.77239C12.4987 5.61789 12.2892 5.5311 12.0707 5.5311C11.8522 5.5311 11.6427 5.61789 11.4882 5.77239C11.3337 5.92689 11.2469 6.13643 11.2469 6.35493V6.76684C10.5914 6.76684 9.96277 7.02723 9.49927 7.49072C9.03578 7.95422 8.77539 8.58285 8.77539 9.23833C8.77539 9.89381 9.03578 10.5224 9.49927 10.9859C9.96277 11.4494 10.5914 11.7098 11.2469 11.7098H12.0707C12.2892 11.7098 12.4987 11.7966 12.6532 11.9511C12.8077 12.1056 12.8945 12.3152 12.8945 12.5336V12.9456C12.8945 13.1641 12.8077 13.3736 12.6532 13.5281C12.4987 13.6826 12.2892 13.7694 12.0707 13.7694H10.0111C9.79264 13.7694 9.5831 13.8562 9.4286 14.0107C9.2741 14.1652 9.18731 14.3747 9.18731 14.5932C9.18731 14.8117 9.2741 15.0213 9.4286 15.1758C9.5831 15.3303 9.79264 15.4171 10.0111 15.4171H10.423V15.829C10.423 16.0475 10.5098 16.257 10.6643 16.4115C10.8188 16.566 11.0284 16.6528 11.2469 16.6528C11.4654 16.6528 11.6749 16.566 11.8294 16.4115C11.9839 16.257 12.0707 16.0475 12.0707 15.829V15.4171C12.7262 15.4171 13.3548 15.1567 13.8183 14.6932C14.2818 14.2297 14.5422 13.601 14.5422 12.9456V12.5336C14.5422 11.8782 14.2818 11.2495 13.8183 10.786C13.3548 10.3225 12.7262 10.0622 12.0707 10.0622Z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="0.1"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1516_3913">
        <rect width="25" height="25" fill="currentColor" />
      </clipPath>
      <clipPath id="clip1_1516_3913">
        <rect
          width="27"
          height="27"
          fill="currentColor"
          transform="translate(-1 1)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CostIcon;
