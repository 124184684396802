import React from 'react';

import {
  NotificationMain,
  NotificationContent,
  NotificationStatus,
  NotificationText,
  NotificationDate,
} from './styles';
import { TNotificationProps } from './types';
import { format } from 'date-fns';

const Notification = ({
  color,
  allDay,
  title,
  description,
  start,
  ...props
}: TNotificationProps) => (
  <NotificationMain {...props}>
    <NotificationContent>
      <NotificationStatus color={color} />
      <NotificationText> {title} </NotificationText>
    </NotificationContent>
    {
      start &&
      <NotificationDate>
        {allDay
          ? format(new Date(start), 'MMM dd, yyyy')
          : format(new Date(start), 'MMM dd, yyyy | h:mm a')}
      </NotificationDate>
    }
  </NotificationMain>
);

export default Notification;
