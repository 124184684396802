import React from 'react';

const CarretDownIcon = ({ ...props }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0976 4.35469C10.0689 4.28644 10.0207 4.22821 9.95895 4.18733C9.89723 4.14646 9.82478 4.12477 9.75074 4.125H2.25074C2.17671 4.12477 2.10426 4.14646 2.04254 4.18733C1.98081 4.22821 1.93256 4.28644 1.90387 4.35469C1.87685 4.42396 1.87 4.49946 1.8841 4.57246C1.8982 4.64545 1.93269 4.71297 1.98356 4.76719L5.73356 8.51719C5.80524 8.5865 5.90104 8.62524 6.00074 8.62524C6.10045 8.62524 6.19625 8.5865 6.26793 8.51719L10.0179 4.76719C10.0688 4.71297 10.1033 4.64545 10.1174 4.57246C10.1315 4.49946 10.1246 4.42396 10.0976 4.35469Z"
      fill="currentColor"
    />
  </svg>
);

export default CarretDownIcon;
