import React, { useState, useEffect } from 'react';
import {
  DashboardLayoutMain,
  DashboardLayoutNavbar,
  DashboardLayoutBox,
  DashboardLayoutSidebar,
  DashboardLayoutContainer,
  DashboardLayoutContainerOuter,
  DashboardLayoutContent,
  DashboardLayoutWidgets,
} from './styles';
import { TDashboardLayoutProps } from './types';
import { CalendarCard, NotificationsCard } from '../../components/custom';
import { useAuth } from '../../context/AppContext';

const DashboardLayout = ({ children, ...props }: TDashboardLayoutProps) => {
  const { showMobileMenu, handleMobileMenu } = useAuth();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (width >= 1200) {
      handleMobileMenu(true);
    } else {
      handleMobileMenu(false);
    }
     // eslint-disable-next-line
  }, [width]);

  return (
    <DashboardLayoutMain {...props}>
      <DashboardLayoutNavbar />
      <DashboardLayoutBox>
        <DashboardLayoutSidebar
          style={
            showMobileMenu
              ? { transform: 'translateX(0)' }
              : { transform: 'translateX(-100%)' }
          }
        />
        <DashboardLayoutContainerOuter>
          <DashboardLayoutContainer>
            <DashboardLayoutContent>{children}</DashboardLayoutContent>
            <DashboardLayoutWidgets>
              <CalendarCard />
              <NotificationsCard />
            </DashboardLayoutWidgets>
          </DashboardLayoutContainer>
        </DashboardLayoutContainerOuter>
      </DashboardLayoutBox>
    </DashboardLayoutMain>
  );
};

export default DashboardLayout;
