import React, { useState } from 'react';
import { Stack } from '../../../components/system';
import { Input, Checkbox } from '../../../components/ui';
import dayjs from 'dayjs';
import moment from 'moment';
import { addMilliseconds } from 'date-fns';

export const DateRangePicker = ({
  event,
  handleChange,
  repeatData,
  setRepeatData,
  handleErrors,
  disabled,
}: any) => {
  const [
    fromDateInvokeValidationTimestamp,
    setFromDateInvokeValidationTimestamp,
  ] = useState<number | undefined>(undefined);
  const [toDateInvokeValidationTimestamp, setToDateInvokeValidationTimstamp] =
    useState<number | undefined>(undefined);

  const onFromDateChangeValue = (from: any) => {
    const time = moment(new Date(event.from)).format('HH:mm:ss');
    const d = moment(new Date(from)).format('YYYY-MM-DD');
    let isoTimestamp = `${d}T${time}`;
    const date = dayjs(new Date(isoTimestamp))
    handleChange('from', date);
    setRepeatData((data: any) => ({
      ...data,
      start: date
    }))
    setToDateInvokeValidationTimstamp(new Date().getTime());
  };
  const onFromTimeChangeValue = (from: any) => {
    const time = moment(new Date(from)).format('HH:mm:ss');
    const d = moment(new Date(event.from)).format('YYYY-MM-DD');
    let isoTimestamp = `${d}T${time}`;
    const date = dayjs(new Date(isoTimestamp))
    handleChange('from', date);
    setToDateInvokeValidationTimstamp(new Date().getTime());

    const rtime = moment(new Date(from)).format('HH:mm:ss');
    const rd = moment(new Date(repeatData.start)).format('YYYY-MM-DD');
    let risoTimestamp = `${rd}T${rtime}`;
    const rdate = dayjs(new Date(risoTimestamp))
    setRepeatData((data: any) => ({
      ...data,
      start: rdate
    }))
  };

  const onToDateChangeValue = (to: any) => {
    const time = moment(new Date(event.to)).format('HH:mm:ss');
    const d = moment(new Date(to)).format('YYYY-MM-DD');
    let isoTimestamp = `${d}T${time}`;
    const date = dayjs(new Date(isoTimestamp))
    handleChange('to', date);
    setFromDateInvokeValidationTimestamp(new Date().getTime());

    const date2 = new Date(isoTimestamp);
    const date1 = new Date(event.from);
    //@ts-ignore
    const diffTime: any = Math.abs(date2 - date1);
    const result = addMilliseconds(new Date(repeatData.start), diffTime)
    setRepeatData((data: any) => ({
      ...data,
      end: result
    }))
  };
  const onToTimeChangeValue = (to: any) => {
    const time = moment(new Date(to)).format('HH:mm:ss');
    const d = moment(new Date(event.to)).format('YYYY-MM-DD');
    let isoTimestamp = `${d}T${time}`;
    const date = dayjs(new Date(isoTimestamp))
    handleChange('to', date);
    setFromDateInvokeValidationTimestamp(new Date().getTime());
    const date2 = new Date(isoTimestamp);
    const date1 = new Date(event.from);
    //@ts-ignore
    const diffTime: any = Math.abs(date2 - date1);
    const result = addMilliseconds(new Date(repeatData.start), diffTime)
    setRepeatData((data: any) => ({
      ...data,
      end: result
    }))
  };

  return (
    <Stack direction="horizontal" style={{ position: 'relative' }}>
      <Input
        type='date'
        label="Start Date"
        placeholder="Please Select"
        value={event.from}
        onValue={onFromDateChangeValue}
        errorCallback={handleErrors(2)}
        invokeValidation={fromDateInvokeValidationTimestamp}
        enableInvokeValidation
        disabled={disabled}
        validators={[
          {
            message: 'Start must be less than End!',
            validator: (fromDate: any) => {
              if (!event.to) return true;
              try {
                if (event.allDay) {
                  return new Date(event.to) >= new Date(event.from);
                } else {
                  return new Date(event.to) > new Date(event.from);
                }
              } catch {
                return false;
              }
            },
          },
        ]}
      />
      {
        !event.allDay &&
        <Input
          type="time"
          label="Time"
          placeholder="Please Select"
          value={event.from}
          onValue={onFromTimeChangeValue}
          invokeValidation={toDateInvokeValidationTimestamp}
          enableInvokeValidation
        />
      }
      <Input
        type='date'
        label="End Date"
        placeholder="Please Select"
        value={event.to}
        onValue={onToDateChangeValue}
        errorCallback={handleErrors(3)}
        invokeValidation={fromDateInvokeValidationTimestamp}
        enableInvokeValidation
        disabled={disabled}
        validators={[
          {
            message: 'End must be greater than Start!',
            validator: (toDate: any) => {
              if (!event.from) return true;
              try {
                if (event.allDay) {
                  return new Date(event.from) <= new Date(event.to);
                } else {
                  return new Date(event.from) < new Date(event.to);
                }
              } catch {
                return false;
              }
            },
          },
        ]}
      />
      {
        !event.allDay &&
        <Input
          type='time'
          label="Time"
          placeholder="Please Select"
          value={event.to}
          onValue={onToTimeChangeValue}
          invokeValidation={toDateInvokeValidationTimestamp}
          enableInvokeValidation
        />
      }
      <Checkbox
        label="All Day"
        style={{
          position: 'absolute',
          cursor: disabled ? "default !important" : "pointer !important",
          right: '0',
          bottom: '-25px',
        }}
        value={event.allDay}
        onValue={(allDay) => {
          handleChange('allDay', allDay);
          setFromDateInvokeValidationTimestamp(new Date().getTime());
          setToDateInvokeValidationTimstamp(new Date().getTime());
        }}
      />
    </Stack>
  );
};

export default DateRangePicker;
