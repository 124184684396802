import React from 'react';

const ProfitIcon = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.375 13.5C3.5955 13.5 1.5 14.79 1.5 16.5V19.5C1.5 21.21 3.5955 22.5 6.375 22.5C9.1545 22.5 11.25 21.21 11.25 19.5V16.5C11.25 14.79 9.1545 13.5 6.375 13.5ZM6.375 15C8.43525 15 9.75 15.8888 9.75 16.5C9.75 17.1112 8.43525 18 6.375 18C4.31475 18 3 17.1112 3 16.5C3 15.8888 4.31475 15 6.375 15ZM6.375 21C4.31475 21 3 20.1112 3 19.5V18.6998C3.86325 19.1993 5.0385 19.5 6.375 19.5C7.7115 19.5 8.88675 19.1993 9.75 18.6998V19.5C9.75 20.1112 8.43525 21 6.375 21Z"
      fill="currentColor"
    />
    <path
      d="M17.625 10.5C14.8455 10.5 12.75 11.79 12.75 13.5V19.5C12.75 21.21 14.8455 22.5 17.625 22.5C20.4045 22.5 22.5 21.21 22.5 19.5V13.5C22.5 11.79 20.4045 10.5 17.625 10.5ZM21 16.5C21 17.1112 19.6852 18 17.625 18C15.5647 18 14.25 17.1112 14.25 16.5V15.6998C15.1133 16.1993 16.2885 16.5 17.625 16.5C18.9615 16.5 20.1368 16.1993 21 15.6998V16.5ZM17.625 12C19.6852 12 21 12.8888 21 13.5C21 14.1112 19.6852 15 17.625 15C15.5647 15 14.25 14.1112 14.25 13.5C14.25 12.8888 15.5647 12 17.625 12ZM17.625 21C15.5647 21 14.25 20.1112 14.25 19.5V18.6998C15.1133 19.1993 16.2885 19.5 17.625 19.5C18.9615 19.5 20.1368 19.1993 21 18.6998V19.5C21 20.1112 19.6852 21 17.625 21Z"
      fill="currentColor"
    />
    <path
      d="M21.0002 2.25V6C21.0002 6.414 20.6649 6.75 20.2502 6.75C19.8354 6.75 19.5002 6.414 19.5002 6V4.0605L14.0304 9.53025C13.7372 9.8235 13.2632 9.8235 12.9699 9.53025L9.00019 5.5605L4.28044 10.2802C3.98719 10.5735 3.51319 10.5735 3.21994 10.2802C2.92669 9.987 2.92669 9.513 3.21994 9.21975L8.46994 3.96975C8.76319 3.6765 9.23719 3.6765 9.53044 3.96975L13.5002 7.9395L18.4397 3H16.5002C16.0854 3 15.7502 2.664 15.7502 2.25C15.7502 1.836 16.0854 1.5 16.5002 1.5H20.2502C20.6649 1.5 21.0002 1.836 21.0002 2.25Z"
      fill="currentColor"
    />
  </svg>
);

export default ProfitIcon;
