import React from 'react';

const ReportsIcon = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#clip0_1490_4923)">
      <path
        d="M18.4192 4.18091L14.3332 0.167847C14.2236 0.0602722 14.0762 0 13.9226 0H3.59253C2.38708 0 1.40625 0.980682 1.40625 2.18628V17.8137C1.40625 19.0192 2.38693 20 3.59253 20H16.4082C17.6137 20 18.5945 19.0193 18.5945 17.8137V4.599C18.5945 4.44168 18.5313 4.29108 18.4192 4.18091ZM14.5085 1.98273L16.5758 4.01306H14.6948C14.5921 4.01306 14.5085 3.92944 14.5085 3.82675V1.98273ZM16.4082 18.8281H3.59253C3.03329 18.8281 2.57812 18.3731 2.57812 17.8137V2.18628C2.57812 1.62689 3.03314 1.17188 3.59253 1.17188H13.3368V3.82675C13.3368 4.57565 13.9461 5.18494 14.695 5.18494H17.4226V17.8137C17.4226 18.3731 16.9676 18.8281 16.4082 18.8281Z"
        fill="currentColor"
      />
      <path
        d="M14.0685 9.29688H5.80859C5.48495 9.29688 5.22266 9.55917 5.22266 9.88281C5.22266 10.2065 5.48495 10.4688 5.80859 10.4688H14.0685C14.3921 10.4688 14.6544 10.2065 14.6544 9.88281C14.6544 9.55917 14.3921 9.29688 14.0685 9.29688Z"
        fill="currentColor"
      />
      <path
        d="M5.80859 12.8125H12.5435C12.8672 12.8125 13.1295 12.5502 13.1295 12.2266C13.1295 11.9029 12.8672 11.6406 12.5435 11.6406H5.80859C5.48495 11.6406 5.22266 11.9029 5.22266 12.2266C5.22266 12.5502 5.48495 12.8125 5.80859 12.8125Z"
        fill="currentColor"
      />
      <path
        d="M13.356 13.9844H5.80859C5.48495 13.9844 5.22266 14.2467 5.22266 14.5703C5.22266 14.894 5.48495 15.1562 5.80859 15.1562H13.356C13.6795 15.1562 13.942 14.894 13.942 14.5703C13.942 14.2467 13.6795 13.9844 13.356 13.9844Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1490_4923">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ReportsIcon;
