import React, { useState } from 'react';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOff from '@mui/icons-material/VolumeOff';
import {
    IconButton,
} from "@mui/material";

const BotMessge = (props: any) => {
    const {data} = props

    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    const handleReadMessage = (text: any) => {
        const utterance = new SpeechSynthesisUtterance(text);
        const synth = window.speechSynthesis;
    
        synth.speak(utterance);
        setIsPlaying(true);
        utterance.onend = () => {
            setIsPlaying(false)
        }
    }

    const handleStopReading = () => {
        const synth = window.speechSynthesis;
        synth.cancel();
        setIsPlaying(false);
      };

    return (
        <div className="d-flex justify-content-start mb-5">
            <div className="d-flex flex-column align-items-start">
                <div className="d-flex align-items-center mb-2">
                    <div className="symbol symbol-100px symbol-circle">
                        <img alt="Pic" src="/images/logo-1.png" />
                    </div>
                    <div className="ms-3">
                        <div className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">Helpful Howie</div>
                        {
                           isPlaying ?
                            <IconButton
                                aria-label="play-message"
                                style={{ marginLeft: '5px', marginBottom: '2px' }}
                                onClick={() => {
                                    handleStopReading();
                                }}
                            >
                                <VolumeOff style={{ cursor: "pointer" }} fontSize="small" />
                            </IconButton>
                            :
                            <IconButton
                                aria-label="play-message"
                                style={{ marginLeft: '5px', marginBottom: '2px' }}
                                onClick={() => {
                                    handleReadMessage(data.text)
                                }}
                            >
                                <VolumeUp style={{ cursor: "pointer" }} fontSize="small" />
                            </IconButton>

                        }
                    </div>
                </div>
		<div className="p-5 rounded bg-light-info mw-lg-700px text-start" style={{ whiteSpace: 'pre-line', fontSize: '1.2em', color: 'black', fontWeight: 'bold' }}>
                    {data.text}
                </div>
            </div>
        </div>
    )
}

export default BotMessge;
