import React from 'react';

const FinishedIcon = ({ ...props }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.41408 12.9437C9.11991 12.9437 8.82574 12.7966 8.53156 12.6495L4.85438 8.5311C4.41312 8.08984 4.41312 7.3544 4.85438 6.91314C5.29564 6.47188 6.03108 6.47188 6.47234 6.91314L9.41408 10.1491L18.9747 1.618C19.416 1.17674 20.1514 1.17674 20.5927 1.76509C21.034 2.20635 21.034 2.94179 20.4456 3.38305L10.1495 12.6495C10.0024 12.7966 9.70826 12.9437 9.41408 12.9437Z"
      fill="currentColor"
    />
    <path
      d="M9.70775 19.5626C4.26553 19.5626 0 15.15 0 9.85484C0 4.41261 4.41261 0 9.70775 0C11.6199 0 13.532 0.588346 15.15 1.61796C15.5912 1.91213 15.7383 2.64757 15.4441 3.08883C15.15 3.53009 14.4145 3.67718 13.9733 3.383C12.7966 2.50048 11.1786 2.05922 9.70775 2.05922C5.58931 2.05922 2.20631 5.44222 2.20631 9.70775C2.20631 13.9733 5.58931 17.3563 9.70775 17.3563C13.8262 17.3563 17.3563 13.9733 17.3563 9.85484V8.97231C17.3563 8.38396 17.7975 7.79562 18.533 7.79562C19.2684 7.79562 19.7097 8.23688 19.7097 8.97231V9.85484C19.5626 15.2971 15.15 19.5626 9.70775 19.5626Z"
      fill="currentColor"
    />
  </svg>
);

export default FinishedIcon;
