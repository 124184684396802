/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import {
  Alert,
  message
} from "antd";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { validateEightNumber, validateEmailAddress, validateName } from "../utils/authValidation";
import AuthorizationAPI from "../api/authorization";
// import { useAuth } from "../context/AppContext";

const Register = () => {
  // const { setCurrentUser } = useAuth();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [userinfo, setUserinfo] = useState({
    firstName: '', lastName: '', email: '', password: '', confirmPassword: ''
  })

  const [errors, setErrors] = useState({
    firstName: '', lastName: '', email: '', password: '', confirmPassword: ''
  })

  const [loading, setLoading] = useState<boolean>(false);

  const handleRegister = async () => {
   
    setErrorMsg("");
    setSuccessMsg("");

    if (!userinfo.firstName) {
      setErrors((info: any) => ({ ...info, firstName: "FirstName is required" }))
    }
    if (!userinfo.lastName) {
      setErrors((info: any) => ({ ...info, lastName: "LastName is required" }))
    }

    if (!userinfo.email) {
      setErrors((info: any) => ({ ...info, email: "Email is required" }))
    }
    if (!userinfo.password) {
      setErrors((info: any) => ({ ...info, password: "Password is required" }))
    }


    if (!validateName(userinfo.firstName)) {
      return;
    }
    if (!validateName(userinfo.lastName)) {
      return;
    }
    if (!validateEmailAddress(userinfo.email)) {
      return;
    }
    if (!validateEightNumber(userinfo.password)) {
      return;
    }

    if (userinfo.password !== userinfo.confirmPassword) {
      return;
    }

    const values = {
      username: `${userinfo.firstName} ${userinfo.lastName}`,
      email: userinfo.email,
      password: userinfo.password,
    };

    setLoading(true);

    setErrorMsg("");
    setSuccessMsg("");
    AuthorizationAPI.register(values)
      .then(data => {
        setSuccessMsg(`Please check your email (${values.email}) to confirm your account.`);
        message.success(`Please check your email (${values.email}) to confirm your account.`);
      })
      .catch(error => {
        setErrorMsg(error?.response?.data?.error?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;
    setUserinfo(info => ({ ...info, [field]: value }))
    switch (field) {
      case 'firstName':
        if (!validateName(value)) {
          setErrors(info => ({ ...info, firstName: "First Name is required" }))
        } else {
          setErrors(info => ({ ...info, firstName: "" }))
        }
        break;
      case 'lastName':
        if (!validateName(value)) {
          setErrors(info => ({ ...info, lastName: "Last Name is required" }))
        } else {
          setErrors(info => ({ ...info, lastName: "" }))
        }
        break;
      case 'email':
        if (!validateEmailAddress(value)) {
          setErrors(info => ({ ...info, email: "Email is invalid" }))
        } else {
          setErrors(info => ({ ...info, email: "" }))
        }
        break;
      case 'password':
        if (!validateEightNumber(value)) {
          setErrors(info => ({ ...info, password: "Password must be at least eight characters" }))
        } else {
          setErrors(info => ({ ...info, password: "" }))
        }
        break;
      case 'confirmPassword':
        if (userinfo.password !== value) {
          setErrors(info => ({ ...info, confirmPassword: "Passwords must match" }))
        } else {
          setErrors(info => ({ ...info, confirmPassword: "" }))
        }
        break;

      default:
        break;
    }

  }

  const resendEmail = () => {
    setErrorMsg("");
    setSuccessMsg("");
    const values = { email: userinfo.email };

    AuthorizationAPI.resendEmailConfirmation(values)
      .then(data => {
        message.success(`We sent you another email to <u>${values.email}</u> to confirm your account. Please check also your spam folder.`);
      })
      .catch(error => {
        setErrorMsg(error?.response?.data?.error?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div className="login-bg">
        <Header />
        <div className="mask-bg">
          <div className="main-section bottom-padding" style={{ height: '100%' }}>
            <div className="container" style={{ height: '100%' }}>
              <div className="row align-items-center gy-5" style={{ height: '100%' }}>
                <div className="middle-back">
                  <img src="/images/topback.svg" alt="" />
                </div>

                <div className="col-lg-12 mb-3" style={{ zIndex: '1' }}>
                  <div className="row align-items-center justify-content-center  py-5">
                    <div className="login-panel">
                      {
                        loading &&
                        <div className="loading-panel">
                          <div className="loading-bar">
                            <ClipLoader color={"#06b6aa"} loading={loading} size={50} />
                          </div>
                        </div>
                      }
                      <div className="col-lg-9  col-11">
                        <div className="login-title" >
                          Signup Now
                        </div>
                      </div>
                      {errorMsg ? (
                        <Alert
                          className="alert_error"
                          message={errorMsg}
                          type="error"
                          closable
                          afterClose={() => setErrorMsg("")}
                        />
                      ) : null}
                      {successMsg ? (
                        <Alert
                          className="alert_success"
                          message={successMsg}
                          type="success"
                          closable
                          afterClose={() => setSuccessMsg("")}
                        />
                      ) : null}

                      {
                        successMsg &&
                        <div className="col-lg-9 col-11">
                          <div className="login-text1" onClick={resendEmail}>
                            Resend Verification Email
                          </div>
                        </div>
                      }

                      <div className="col-lg-9 col-11">
                        <div className="d-flex ">
                          <div className="me-2">
                            <input
                              name="firstName"
                              className="login-input"
                              type="text"
                              placeholder="First Name"
                              value={userinfo.firstName}
                              onChange={handleChange}
                            />
                            {
                              errors.firstName &&
                              <p className="error-text">{errors.firstName}</p>
                            }
                          </div>
                          <div>
                            <input
                              name="lastName"
                              className="login-input"
                              type="text"
                              placeholder="Last Name"
                              value={userinfo.lastName}
                              onChange={handleChange}
                            />
                            {
                              errors.lastName &&
                              <p className="error-text">{errors.lastName}</p>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-9 col-11">
                        <input
                          className="login-input"
                          type="text"
                          name="email"
                          placeholder="Email Address"
                          value={userinfo.email}
                          onChange={handleChange}
                        />
                        {
                          errors.email &&
                          <p className="error-text">{errors.email}</p>
                        }
                      </div>
                      <div className="col-lg-9 col-11">
                        <input
                          className="login-input"
                          type="password"
                          name="password"
                          placeholder="New Password"
                          value={userinfo.password}
                          onChange={handleChange}
                        />
                        {
                          errors.password &&
                          <p className="error-text">{errors.password}</p>
                        }
                      </div>
                      <div className="col-lg-9 col-11">
                        <input
                          className="login-input"
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={userinfo.confirmPassword}
                          onChange={handleChange}
                        />
                        {
                          errors.confirmPassword &&
                          <p className="error-text">{errors.confirmPassword}</p>
                        }
                      </div>



                      <div className="col-lg-9 col-11">
                        <div className="login-button-container">
                          <button className="login-btn d-flex justify-content-between" onClick={handleRegister}>
                            <p className="m-0">
                              Signup Now
                            </p>
                            <div style={{ width: '30px', borderRadius: '100px', background: 'black', height: '30px', marginLeft: '20px', position: 'relative' }}>
                              <img src="/images/play.png" alt="" style={{ width: '10px', position: 'absolute', top: '10px', left: '10px' }} /></div>
                          </button>
                          <Link to={'/login'} className="login-text2">
                            Or Signin Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Register;