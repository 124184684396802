import React from 'react';

const OrderedIcon = ({ ...props }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1516_3514)">
      <path
        d="M14.392 7.307C14.392 7.78167 14.0073 8.16621 13.5328 8.16621H11.8144V9.88479C11.8144 10.3593 11.4297 10.744 10.9552 10.744C10.4805 10.744 10.096 10.3593 10.096 9.88479V8.16621H8.37739C7.90289 8.16621 7.51818 7.78167 7.51818 7.307C7.51818 6.8325 7.90289 6.44779 8.37739 6.44779H10.096V4.72938C10.096 4.25488 10.4805 3.87017 10.9552 3.87017C11.4297 3.87017 11.8144 4.25488 11.8144 4.72938V6.44779H13.5328C14.0073 6.44779 14.392 6.8325 14.392 7.307ZM20.2777 14.2668V9.39652L12.8129 14.0383C12.2533 14.3863 11.6257 14.5603 10.9981 14.5603C10.3706 14.5603 9.74299 14.3863 9.18322 14.0383L1.71841 9.39652V18.563C1.71841 19.5105 2.48933 20.2814 3.43683 20.2814H18.5593C19.5068 20.2814 20.2777 19.5105 20.2777 18.563C20.2777 18.0883 20.6624 17.7038 21.1369 17.7038C21.6114 17.7038 21.9961 18.0883 21.9961 18.563C21.9961 20.4579 20.4545 21.9998 18.5593 21.9998H3.43683C1.54184 21.9998 0 20.4579 0 18.563V8.27749C0 7.09937 0.592498 6.0171 1.58498 5.38213L9.17399 0.527843C9.17701 0.525829 9.18003 0.523815 9.18322 0.521801C10.3026 -0.174259 11.6935 -0.174259 12.8129 0.521801C12.8163 0.523815 12.8195 0.525829 12.8226 0.528011L20.4127 5.38818C21.4041 6.02314 21.9961 7.10508 21.9961 8.28236V14.2668C21.9961 14.7413 21.6114 15.126 21.1369 15.126C20.6624 15.126 20.2777 14.7413 20.2777 14.2668ZM20.0855 7.4923C19.9494 7.22895 19.7453 7.00135 19.486 6.83535L11.9012 1.97854C11.3436 1.63395 10.6524 1.63395 10.0948 1.97871L2.51098 6.82981C2.24998 6.99682 2.04504 7.22593 1.90858 7.49113L10.0906 12.5789C10.6504 12.9268 11.3458 12.9268 11.9055 12.5789L20.0855 7.4923Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1516_3514">
        <rect width="22" height="22" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default OrderedIcon;
