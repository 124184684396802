import React from 'react';

const IdentifiedIcon = ({ ...props }) => (
  <svg viewBox="0 0 17 24" fill="currentColor" {...props}>
    <path d="M6.05996 18.124H0.515962C0.179962 18.124 0.0119629 17.872 0.0119629 17.62V16.36C0.0119629 16.024 0.263962 15.856 0.515962 15.856H5.97596C6.31196 15.856 6.47996 16.108 6.47996 16.36V17.704C6.47996 17.956 6.31196 18.124 6.05996 18.124ZM0.851963 17.284H5.63996V16.696H0.851963V17.284Z" />
    <path d="M11.184 23.416H4.88396C4.54796 23.416 4.12796 23.164 3.95996 22.912C3.70796 22.576 3.70796 22.156 3.95996 21.82C4.04396 21.736 4.04396 21.652 4.21196 21.568L5.55596 19.972C5.72396 19.804 5.97596 19.804 6.14396 19.888C6.31196 20.056 6.31196 20.308 6.22796 20.476L4.79996 22.156L4.71596 22.24C4.71596 22.24 4.71596 22.24 4.71596 22.324V22.408L4.79996 22.492C4.79996 22.492 4.88396 22.576 5.05196 22.576H11.352C11.436 22.576 11.52 22.576 11.52 22.492C11.52 22.408 11.604 22.324 11.52 22.24L9.92396 20.644C9.75596 20.476 9.75596 20.224 9.92396 20.056C10.092 19.888 10.344 19.888 10.512 20.056L12.108 21.652C12.444 21.988 12.444 22.492 12.276 22.828C11.94 23.164 11.604 23.416 11.184 23.416Z" />
    <path d="M7.99196 21.064C7.06796 21.064 6.31196 20.98 5.63996 20.728C4.21196 20.224 2.95196 19.3 2.02796 17.956C1.85996 17.788 1.94396 17.536 2.11196 17.368C2.27996 17.2 2.53196 17.284 2.69996 17.452C3.53996 18.628 4.63196 19.468 5.97596 19.888C6.56396 20.14 7.23596 20.224 7.99196 20.224C8.66396 20.224 9.33596 20.056 10.008 19.888C12.612 18.964 14.376 16.528 14.376 13.84C14.376 11.656 13.284 10.144 12.36 9.30399C12.192 9.13599 12.192 8.88399 12.36 8.71599C12.528 8.54799 12.78 8.54799 12.948 8.71599C14.376 10.144 15.132 11.992 15.132 13.924C15.132 17.032 13.116 19.804 10.176 20.812C9.50396 20.896 8.83196 21.064 7.99196 21.064Z" />
    <path d="M10.848 10.984C9.58796 10.984 8.57996 9.97599 8.57996 8.71599C8.57996 7.45599 9.58796 6.44799 10.848 6.44799C12.108 6.44799 13.116 7.45599 13.116 8.71599C13.116 9.97599 12.108 10.984 10.848 10.984ZM10.848 7.28799C10.092 7.28799 9.41996 7.95999 9.41996 8.71599C9.41996 9.47199 10.092 10.144 10.848 10.144C11.604 10.144 12.276 9.47199 12.276 8.71599C12.276 7.95999 11.604 7.28799 10.848 7.28799Z" />
    <path d="M7.31996 12.496C7.15196 12.496 6.98396 12.412 6.81596 12.328L6.31196 11.824L4.04396 9.38799C3.95996 9.30399 3.87596 9.13599 3.87596 8.88399C3.87596 8.71599 3.95996 8.54799 4.04396 8.37999L11.688 1.32399C11.94 1.07199 12.36 1.07199 12.612 1.32399L13.032 1.82799L15.3 4.26399C15.552 4.51599 15.552 4.93599 15.3 5.18799L12.528 7.79199C12.36 7.95999 12.108 7.95999 11.94 7.79199C11.772 7.62399 11.772 7.37199 11.94 7.20399L14.544 4.68399L14.124 4.17999L12.192 2.07999L4.79996 8.88399L5.21996 9.30399L7.31996 11.572L9.33596 9.72399C9.50396 9.55599 9.75596 9.55599 9.92396 9.72399C10.092 9.89199 10.092 10.144 9.92396 10.312L7.82396 12.244C7.65596 12.412 7.48796 12.496 7.31996 12.496Z" />
    <path d="M14.544 4.43199C14.46 4.43199 14.292 4.34799 14.208 4.26399L12.444 2.41599C12.36 2.33199 12.36 2.24799 12.36 2.16399C12.36 2.07999 12.444 1.91199 12.528 1.82799L13.704 0.735993C13.872 0.567993 14.04 0.567993 14.124 0.567993C14.208 0.567993 14.376 0.567993 14.544 0.735993L16.056 2.33199C16.224 2.49999 16.308 2.75199 16.224 3.00399C16.224 3.08799 16.14 3.17199 16.056 3.17199L14.88 4.26399C14.712 4.43199 14.628 4.43199 14.544 4.43199ZM13.368 2.16399L14.544 3.42399L15.216 2.75199L14.04 1.57599L13.368 2.16399Z" />
    <path d="M4.96796 13.336C4.71596 13.336 4.54796 13.252 4.37996 13.084L3.11996 11.74C2.95196 11.572 2.86796 11.32 2.86796 11.068C2.86796 10.9 3.03596 10.732 3.11996 10.648L4.54796 9.30399C4.71596 9.13599 4.96796 9.13599 5.13596 9.30399L6.89996 11.152C6.98396 11.236 6.98396 11.32 6.98396 11.488C6.98396 11.572 6.89996 11.74 6.81596 11.824L5.38796 13.168C5.38796 13.252 5.13596 13.336 4.96796 13.336ZM3.79196 11.236L4.96796 12.496L6.05996 11.488L4.88396 10.228L3.79196 11.236Z" />
    <path d="M7.99196 19.384C6.64796 19.384 5.30396 18.88 4.37996 18.04C4.21196 17.872 4.21196 17.62 4.37996 17.452C4.54796 17.284 4.79996 17.284 4.96796 17.452C5.72396 18.124 6.89996 18.628 8.07596 18.628C10.68 18.628 12.78 16.528 12.78 13.924C12.78 12.664 12.276 11.572 11.436 10.732C11.268 10.564 11.268 10.312 11.436 10.144C11.604 9.97599 11.856 9.97599 12.024 10.144C13.032 11.152 13.536 12.496 13.536 14.008C13.536 16.948 11.1 19.384 7.99196 19.384Z" />
  </svg>
);

export default IdentifiedIcon;
