export const DRepeat = [
    {
        label: 'Does not repeat',
        value: ''
    },
    {
        label: 'Monthly',
        value: 'Monthly'
    },
    {
        label: 'Weekly',
        value: 'Weekly'
    },
    {
        label: 'Daily',
        value: 'Daily'
    }
];

export const DWeeklyRepeatOn = [
    {
        label: 'MO',
        value: 'MO'
    },
    {
        label: 'TU',
        value: 'TU'
    },  
    {
        label: 'WE',
        value: 'WE'
    },
    {
        label: 'TH',
        value: 'TH'
    },
    {
        label: 'FR',
        value: 'FR'
    },
    {
        label: 'SA',
        value: 'SA'
    },
    {
        label: 'SU',
        value: 'SU'
    },
];

export const DMontlyRepeatOn = [
    {
        label: 'On day 25',
        value: 'On day 25'
    },
    {
        label: 'On the fourth Monday',
        value: 'On the fourth Monday'
    },
    {
        label: 'On the last Monday',
        value: 'On the last Monday'
    },
]