// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* custom-scrollbar.css */
.custom-scrollbar::-webkit-scrollbar {
    width: 25px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Customizing scrollbar for Firefox */
.scroll-y {
    scrollbar-width: thin; /* 'auto', 'thin', 'none' */
    scrollbar-color: #888 #f1f1f1; /* thumb and track color */
}

`, "",{"version":3,"sources":["webpack://./src/pages/chat/scrollbar.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA,sCAAsC;AACtC;IACI,qBAAqB,EAAE,2BAA2B;IAClD,6BAA6B,EAAE,0BAA0B;AAC7D","sourcesContent":["/* custom-scrollbar.css */\n.custom-scrollbar::-webkit-scrollbar {\n    width: 25px;\n}\n\n.custom-scrollbar::-webkit-scrollbar-track {\n    background: #f1f1f1;\n}\n\n.custom-scrollbar::-webkit-scrollbar-thumb {\n    background: #888;\n    border-radius: 10px;\n}\n\n.custom-scrollbar::-webkit-scrollbar-thumb:hover {\n    background: #555;\n}\n\n/* Customizing scrollbar for Firefox */\n.scroll-y {\n    scrollbar-width: thin; /* 'auto', 'thin', 'none' */\n    scrollbar-color: #888 #f1f1f1; /* thumb and track color */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
