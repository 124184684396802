import React from 'react';

const ReadyIcon = ({ ...props }) => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.9448 23.8972H3.58897C1.66318 23.8972 0 22.3216 0 20.3082V10.5918C0 8.66599 1.66318 7.09034 3.58897 7.09034H6.39011C6.74025 7.09034 7.0904 7.35295 7.0904 7.79063C7.0904 8.22831 6.74025 8.40338 6.39011 8.40338H3.58897C2.36347 8.40338 1.31304 9.45381 1.31304 10.5918V20.3082C1.31304 21.5337 2.36347 22.5842 3.58897 22.5842H17.9448C19.1703 22.5842 20.2208 21.5337 20.2208 20.3082V10.5918C20.2208 9.36627 19.1703 8.40338 17.9448 8.40338H15.1437C14.7935 8.40338 14.4434 8.14077 14.4434 7.70309C14.4434 7.26542 14.706 7.00281 15.1437 7.00281H17.9448C19.8706 7.00281 21.5338 8.57845 21.5338 10.5042V20.2207C21.5338 22.234 19.8706 23.8972 17.9448 23.8972Z"
      fill="currentColor"
    />
    <path
      d="M10.7661 18.6451C10.591 18.6451 10.4159 18.5575 10.3284 18.47L2.27508 10.7668C2.01247 10.5042 2.01247 10.0666 2.27508 9.80395C2.53769 9.54134 2.97537 9.54134 3.23797 9.80395L10.8536 17.0694L18.4692 9.80395C18.7318 9.54134 19.1695 9.54134 19.4321 9.80395C19.6947 10.0666 19.6947 10.5042 19.4321 10.7668L11.3788 18.47C11.1162 18.5575 10.9411 18.6451 10.7661 18.6451Z"
      fill="currentColor"
    />
    <path
      d="M2.71276 21.5338C2.53769 21.5338 2.36262 21.4463 2.27508 21.3588C2.01247 21.0962 2.01247 20.6585 2.27508 20.3959L6.30173 16.5443L7.87737 15.1437C8.13998 14.8811 8.57766 14.9687 8.84026 15.2313C9.10287 15.4939 9.01534 15.9316 8.75273 16.1942L7.17708 17.5947L3.15044 21.4463C3.0629 21.4463 2.88783 21.5338 2.71276 21.5338Z"
      fill="currentColor"
    />
    <path
      d="M18.8198 21.5338C18.6448 21.5338 18.4697 21.4463 18.3822 21.3587L14.3555 17.4196L12.7799 16.0191C12.5173 15.7565 12.5173 15.4063 12.6923 15.0562C12.9549 14.7936 13.3926 14.7936 13.6552 14.9686L15.2309 16.3692L19.2575 20.2208C19.5201 20.4834 19.5201 20.9211 19.2575 21.1837C19.17 21.4463 18.9949 21.5338 18.8198 21.5338Z"
      fill="currentColor"
    />
    <path
      d="M10.7664 11.117C7.7026 11.117 5.16406 8.66604 5.16406 5.51476C5.16406 2.451 7.61506 0 10.7664 0C13.9176 0 16.3686 2.451 16.3686 5.51476C16.3686 8.66604 13.8301 11.117 10.7664 11.117ZM10.7664 1.40057C8.40289 1.40057 6.4771 3.32636 6.4771 5.60229C6.4771 7.87822 8.40289 9.89154 10.7664 9.89154C13.1298 9.89154 15.0556 7.96576 15.0556 5.60229C15.0556 3.23882 13.1298 1.40057 10.7664 1.40057Z"
      fill="currentColor"
    />
    <path
      d="M12.7805 6.65276H10.4171C10.0669 6.65276 9.7168 6.39016 9.7168 5.95248V2.71365C9.7168 2.36351 9.9794 2.01337 10.4171 2.01337C10.8548 2.01337 11.1174 2.27597 11.1174 2.71365V5.33973H12.8681C13.2182 5.33973 13.5684 5.60233 13.5684 6.04001C13.5684 6.47769 13.1307 6.65276 12.7805 6.65276Z"
      fill="currentColor"
    />
  </svg>
);

export default ReadyIcon;
