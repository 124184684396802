import React from 'react';

const DeliverIcon = ({ ...props }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <path
      d="M6.56257 10.125C6.46022 10.1251 6.3611 10.0892 6.28257 10.0236L1.03257 5.64856C0.987095 5.61223 0.949326 5.56718 0.921478 5.51607C0.89363 5.46495 0.876263 5.40879 0.870394 5.35088C0.864524 5.29296 0.870271 5.23446 0.887298 5.1788C0.904324 5.12313 0.932287 5.07142 0.969549 5.0267C1.00681 4.98198 1.05262 4.94515 1.1043 4.91836C1.15598 4.89156 1.21248 4.87536 1.27051 4.87068C1.32853 4.866 1.3869 4.87295 1.4422 4.89111C1.49751 4.90928 1.54863 4.9383 1.59257 4.97647L6.56257 9.11798L8.5182 7.48786C8.60765 7.41664 8.72143 7.38328 8.83518 7.3949C8.94892 7.40653 9.0536 7.46223 9.12679 7.55007C9.19999 7.63791 9.23589 7.75091 9.2268 7.86489C9.21772 7.97887 9.16438 8.08476 9.0782 8.1599L6.84257 10.0236C6.76405 10.0893 6.66492 10.1252 6.56257 10.125Z"
      fill="currentColor"
    />
    <path
      d="M9.625 8.375C8.93277 8.375 8.25608 8.16973 7.68051 7.78514C7.10493 7.40056 6.65633 6.85393 6.39142 6.21439C6.12652 5.57485 6.0572 4.87112 6.19225 4.19219C6.3273 3.51325 6.66064 2.88961 7.15013 2.40013C7.63961 1.91064 8.26325 1.5773 8.94219 1.44225C9.62112 1.3072 10.3249 1.37652 10.9644 1.64142C11.6039 1.90633 12.1506 2.35493 12.5351 2.93051C12.9197 3.50608 13.125 4.18277 13.125 4.875C13.1239 5.80293 12.7548 6.69255 12.0987 7.3487C11.4426 8.00485 10.5529 8.37394 9.625 8.375ZM9.625 2.25C9.10583 2.25 8.59831 2.40396 8.16663 2.69239C7.73495 2.98083 7.3985 3.3908 7.19982 3.87046C7.00114 4.35011 6.94915 4.87791 7.05044 5.38711C7.15173 5.89631 7.40173 6.36404 7.76885 6.73116C8.13596 7.09827 8.60369 7.34828 9.11289 7.44956C9.62209 7.55085 10.1499 7.49887 10.6295 7.30019C11.1092 7.10151 11.5192 6.76505 11.8076 6.33337C12.096 5.90169 12.25 5.39418 12.25 4.875C12.2492 4.17904 11.9724 3.51181 11.4803 3.01969C10.9882 2.52757 10.321 2.25077 9.625 2.25Z"
      fill="currentColor"
    />
    <path
      d="M9.18798 6.1875C9.13052 6.18757 9.07362 6.17628 9.02053 6.15428C8.96745 6.13228 8.91924 6.10001 8.87867 6.05932L8.00367 5.18432C7.92398 5.1018 7.87988 4.99129 7.88088 4.87658C7.88187 4.76187 7.92788 4.65214 8.009 4.57102C8.09012 4.48991 8.19985 4.44389 8.31456 4.4429C8.42927 4.4419 8.53978 4.486 8.6223 4.56569L9.18798 5.13134L10.6287 3.69069C10.669 3.64891 10.7173 3.61558 10.7707 3.59265C10.8241 3.56972 10.8815 3.55765 10.9396 3.55715C10.9976 3.55664 11.0553 3.56771 11.109 3.58971C11.1628 3.61171 11.2116 3.64419 11.2527 3.68527C11.2938 3.72635 11.3263 3.7752 11.3483 3.82896C11.3703 3.88273 11.3813 3.94034 11.3808 3.99843C11.3803 4.05652 11.3683 4.11393 11.3453 4.16731C11.3224 4.22068 11.2891 4.26896 11.2473 4.30932L9.4973 6.05932C9.45673 6.10001 9.40852 6.13228 9.35543 6.15428C9.30235 6.17628 9.24544 6.18757 9.18798 6.1875Z"
      fill="currentColor"
    />
    <path
      d="M11.8125 13.625H1.3125C1.19647 13.625 1.08519 13.5789 1.00314 13.4969C0.921094 13.4148 0.875 13.3035 0.875 13.1875V5.3125C0.875 5.19647 0.921094 5.08519 1.00314 5.00314C1.08519 4.92109 1.19647 4.875 1.3125 4.875H6.5975C6.71353 4.875 6.82481 4.92109 6.90686 5.00314C6.98891 5.08519 7.035 5.19647 7.035 5.3125C7.035 5.42853 6.98891 5.53981 6.90686 5.62186C6.82481 5.70391 6.71353 5.75 6.5975 5.75H1.75V12.75H11.375V7.01438C11.375 6.89834 11.4211 6.78706 11.5031 6.70502C11.5852 6.62297 11.6965 6.57688 11.8125 6.57688C11.9285 6.57688 12.0398 6.62297 12.1219 6.70502C12.2039 6.78706 12.25 6.89834 12.25 7.01438V13.1875C12.25 13.3035 12.2039 13.4148 12.1219 13.4969C12.0398 13.5789 11.9285 13.625 11.8125 13.625Z"
      fill="currentColor"
    />
  </svg>
);

export default DeliverIcon;
