import React from 'react';

const UserIcon = ({ ...props }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.66296 7.72833C8.78911 7.72833 10.5271 5.99031 10.5271 3.86417C10.5271 1.73802 8.78911 0 6.66296 0C4.53682 0 2.79883 1.73802 2.79883 3.86417C2.79883 5.99031 4.53685 7.72833 6.66296 7.72833Z"
      fill="currentColor"
    />
    <path
      d="M13.2935 10.8163C13.1923 10.5632 13.0573 10.3269 12.9055 10.1076C12.1292 8.96012 10.9312 8.20079 9.58127 8.01518C9.41255 7.99832 9.22693 8.03204 9.09193 8.13329C8.38321 8.65639 7.53953 8.92637 6.66205 8.92637C5.78458 8.92637 4.94089 8.65639 4.23218 8.13329C4.09717 8.03204 3.91156 7.98142 3.74283 8.01518C2.3929 8.20079 1.17798 8.96012 0.418656 10.1076C0.26679 10.3269 0.131785 10.5801 0.0305628 10.8163C-0.0200482 10.9175 -0.00318863 11.0357 0.0474223 11.1369C0.182428 11.3731 0.351153 11.6094 0.503018 11.8119C0.739245 12.1325 0.992365 12.4193 1.27924 12.6893C1.51546 12.9255 1.78544 13.1449 2.05545 13.3643C3.38849 14.3599 4.99154 14.8829 6.64519 14.8829C8.29885 14.8829 9.9019 14.3598 11.2349 13.3643C11.5049 13.1618 11.7749 12.9255 12.0112 12.6893C12.2811 12.4193 12.5511 12.1325 12.7874 11.8119C12.9561 11.5925 13.108 11.3731 13.243 11.1369C13.3273 11.0357 13.3442 10.9175 13.2935 10.8163Z"
      fill="currentColor"
    />
  </svg>
);

export default UserIcon;
