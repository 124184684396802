/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import "bootstrap/dist/js/bootstrap.min.js";

const Header = () => {

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const { width } = useWindowDimensions();


  return (
    <nav className="navbar navbar-expand-lg navbar-light nav-custom fixed-top nav-bg">
      <div className="container">
        <div className="d-flex justify-content-between w-100">
          <Link to="/" className="navbar-brand">
            <div className="d-flex justify-content-start align-items-center">
              <img src="logo.png" className="logo" style={{ width: '50px', height: '50px' }} />
              <div className="d-flex align-items-baseline ml-2">
                <p className="custom-logo-big">Helpful</p>
                <p className="custom-logo-small">&nbsp;Howie</p>
              </div>
            </div>
          </Link>
          {
            width > 990 &&
            <ul className="navbar-nav nav m-0">
              <Link to="/login" className="custom-nav-btn-login">
                Login
              </Link>
              <Link to="/register" className="custom-nav-btn">
                Sign Up
              </Link>
            </ul>
          }
        </div>
      </div>
    </nav>
  );
}

export default Header;
