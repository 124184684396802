import React from 'react';

const AccesoriesIcon = ({ ...props }) => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.25617 19.9968H4.7593C3.21469 20.0383 1.9712 18.4475 2.3846 16.9589L3.80999 11.2567C4.06766 10.2302 4.9515 9.50395 5.99341 9.42235V10.2209C5.99341 10.446 6.17579 10.6282 6.40074 10.6282C6.62569 10.6282 6.80807 10.446 6.80807 10.2209V9.40624H15.7693V10.2209C15.7693 10.446 15.9517 10.6282 16.1766 10.6282C16.4016 10.6282 16.5839 10.446 16.5839 10.2209V9.42235C17.6258 9.50395 18.5097 10.2302 18.7672 11.2563L20.0705 16.47C20.2083 16.9859 20.982 16.7952 20.8609 16.2727L19.5573 11.0586C19.2086 9.66835 18.001 8.68208 16.5839 8.59966V7.3696C16.5839 4.44989 14.2086 2.07434 11.2887 2.07434C8.36876 2.07434 5.99341 4.44989 5.99341 7.3696V8.59966C4.57632 8.68208 3.3687 9.66838 3.0198 11.059L1.59416 16.762C1.34992 17.7429 1.56691 18.7624 2.18924 19.5588C2.81157 20.3548 3.74834 20.8114 4.7593 20.8114H7.25617C7.79054 20.8016 7.79275 20.0065 7.25617 19.9968ZM6.80807 7.3696C7.05487 1.42427 15.5245 1.4283 15.7693 7.36963L15.7693 8.59159H6.80807V7.3696Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M16.9911 13.0721C16.9911 12.847 16.8087 12.6648 16.5838 12.6648H5.99327C5.76832 12.6648 5.58594 12.847 5.58594 13.0721C5.58594 13.2973 5.76832 13.4795 5.99327 13.4795H16.5838C16.8087 13.4795 16.9911 13.2973 16.9911 13.0721Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M15.3633 13.8868V14.7014C15.3722 15.2367 16.169 15.2369 16.1779 14.7014V13.8868C16.169 13.3516 15.3722 13.3512 15.3633 13.8868Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M24.7328 19.5893H24.5189C23.1903 15.9551 17.9055 16.545 17.4249 20.404H16.5622C16.0804 16.5423 10.7951 15.9574 9.4682 19.5893C7.56731 19.4511 7.56848 22.1721 9.4682 22.0333C10.7968 25.6676 16.0816 25.0777 16.5622 21.2187H17.4249C17.9067 25.0803 23.192 25.6652 24.5189 22.0333C26.2761 22.1904 26.4859 19.691 24.7328 19.5893ZM9.25432 21.2187C8.71888 21.2091 8.71923 20.4134 9.25432 20.404V21.2187ZM12.9203 23.6626C11.348 23.6626 10.069 22.3834 10.069 20.8113C10.2124 17.0339 15.6287 17.0349 15.7716 20.8114C15.7716 22.3834 14.4925 23.6626 12.9203 23.6626ZM21.0668 23.6626C19.4946 23.6626 18.2155 22.3834 18.2155 20.8113C18.359 17.0339 23.7753 17.0349 23.9181 20.8114C23.9181 22.3834 22.639 23.6626 21.0668 23.6626ZM24.7328 21.2187V20.404C25.2682 20.4135 25.2679 21.2093 24.7328 21.2187Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.1"
    />
  </svg>
);
export default AccesoriesIcon;
