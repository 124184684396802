// @ts-ignore
export { default as InterBlack } from './Inter-Black.otf';
// @ts-ignore
export { default as InterBlackItalic } from './Inter-BlackItalic.otf';
// @ts-ignore
export { default as InterBold } from './Inter-Bold.otf';
// @ts-ignore
export { default as InterBoldItalic } from './Inter-BoldItalic.otf';
// @ts-ignore
export { default as InterExtraBold } from './Inter-ExtraBold.otf';
// @ts-ignore
export { default as InterExtraBoldItalic } from './Inter-ExtraBoldItalic.otf';
// @ts-ignore
export { default as InterExtraLight } from './Inter-ExtraLight.otf';
// @ts-ignore
export { default as InterExtraLightItalic } from './Inter-ExtraLightItalic.otf';
// @ts-ignore
export { default as InterRegular } from './Inter-Regular.otf';
// @ts-ignore
export { default as InterItalic } from './Inter-Italic.otf';
// @ts-ignore
export { default as InterLight } from './Inter-Light.otf';
// @ts-ignore
export { default as InterLightItalic } from './Inter-LightItalic.otf';
// @ts-ignore
export { default as InterMedium } from './Inter-Medium.otf';
// @ts-ignore
export { default as InterMediumItalic } from './Inter-MediumItalic.otf';
// @ts-ignore
export { default as InterSemiBold } from './Inter-SemiBold.otf';
// @ts-ignore
export { default as InterSemiBoldItalic } from './Inter-SemiBoldItalic.otf';
// @ts-ignore
export { default as InterThin } from './Inter-Thin.otf';
// @ts-ignore
export { default as InterThinItalic } from './Inter-ThinItalic.otf';
