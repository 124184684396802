import React from 'react';

const RedCrossIcon = ({ ...props }) => (
  <svg viewBox="0 0 26 26" fill="none" {...props}>
    <path
      d="M13 1C6.37831 1 1 6.37831 1 13C1 19.6217 6.37831 25 13 25C19.6217 25 25 19.6217 25 13C25 6.37831 19.6217 1 13 1ZM13 23.959C6.95663 23.959 2.04096 19.0434 2.04096 13C2.04096 6.95663 6.95663 2.04096 13 2.04096C19.0434 2.04096 23.959 6.95663 23.959 13C23.959 19.0434 19.0434 23.959 13 23.959Z"
      fill="currentColor"
    />
    <path
      d="M19.2458 10.3976H15.6024V6.75422C15.6024 6.46506 15.3711 6.23374 15.0819 6.23374H10.9181C10.6289 6.23374 10.3976 6.46506 10.3976 6.75422V10.3976H6.75422C6.46506 10.3976 6.23374 10.6289 6.23374 10.9181V15.0819C6.23374 15.3711 6.46506 15.6024 6.75422 15.6024H10.3976V19.2458C10.3976 19.5349 10.6289 19.7663 10.9181 19.7663H15.0819C15.3711 19.7663 15.6024 19.5349 15.6024 19.2458V15.6024H19.2458C19.5349 15.6024 19.7663 15.3711 19.7663 15.0819V10.9181C19.7373 10.6289 19.506 10.3976 19.2458 10.3976ZM18.7542 14.5614H15.0819C14.7928 14.5614 14.5614 14.7928 14.5614 15.0819V18.7253H11.4386V15.0819C11.4386 14.7928 11.2072 14.5614 10.9181 14.5614H7.2747V11.4386H10.9181C11.2072 11.4386 11.4386 11.2072 11.4386 10.9181V7.2747H14.5904V10.9181C14.5904 11.2072 14.8217 11.4386 15.1108 11.4386H18.7542V14.5614Z"
      fill="currentColor"
    />
    <path
      d="M13 1C6.37831 1 1 6.37831 1 13C1 19.6217 6.37831 25 13 25C19.6217 25 25 19.6217 25 13C25 6.37831 19.6217 1 13 1ZM13 23.959C6.95663 23.959 2.04096 19.0434 2.04096 13C2.04096 6.95663 6.95663 2.04096 13 2.04096C19.0434 2.04096 23.959 6.95663 23.959 13C23.959 19.0434 19.0434 23.959 13 23.959Z"
      stroke="currentColor"
      strokeWidth="0.1"
    />
    <path
      d="M19.2458 10.3976H15.6024V6.75422C15.6024 6.46506 15.3711 6.23374 15.0819 6.23374H10.9181C10.6289 6.23374 10.3976 6.46506 10.3976 6.75422V10.3976H6.75422C6.46506 10.3976 6.23374 10.6289 6.23374 10.9181V15.0819C6.23374 15.3711 6.46506 15.6024 6.75422 15.6024H10.3976V19.2458C10.3976 19.5349 10.6289 19.7663 10.9181 19.7663H15.0819C15.3711 19.7663 15.6024 19.5349 15.6024 19.2458V15.6024H19.2458C19.5349 15.6024 19.7663 15.3711 19.7663 15.0819V10.9181C19.7373 10.6289 19.506 10.3976 19.2458 10.3976ZM18.7542 14.5614H15.0819C14.7928 14.5614 14.5614 14.7928 14.5614 15.0819V18.7253H11.4386V15.0819C11.4386 14.7928 11.2072 14.5614 10.9181 14.5614H7.2747V11.4386H10.9181C11.2072 11.4386 11.4386 11.2072 11.4386 10.9181V7.2747H14.5904V10.9181C14.5904 11.2072 14.8217 11.4386 15.1108 11.4386H18.7542V14.5614Z"
      stroke="currentColor"
      strokeWidth="0.1"
    />
  </svg>
);

export default RedCrossIcon;
