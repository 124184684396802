/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { DashboardLayout } from "../../layout";
import ApiCalendar from "react-google-calendar-api";
import Calendar from "./calendar";
import { fetchEvents, selectEvent, updateEvent } from "./store/actions";
import { useAppDispatch, useAppSelector } from "../../hooks/use-dispatch";
import { useRTL } from "../../utils/useRTL";
import '../../assets/scss/app-calendar.scss'
import '../../assets/scss/style.bundle.scss'
import { useModal } from "../../hooks";
import AddEventModal from "./event-modal/addEventModal";
import { useAuth } from "../../context/AppContext";
import CalendarAPI from "../../api/calendar";
import EditEventModal from "./event-modal/editEventModal";
import moment from 'moment';
import ClipLoader from "react-spinners/ClipLoader";

const CalendarPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const dispatch = useAppDispatch()
  //eslint-disable-next-line
  const [isRtl, setIsRtl] = useRTL()
  const store = useAppSelector((state: any) => state.calendar)
  const { calendarApi, setCalendarApi } = useAuth();
  const [addEventModal, openAddEventModal, closeAddEventModal] = useModal(false);
  const [editEventModal, openEditEventModal, closeEditEventModal] = useModal(false);
  const config = {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    scope: "https://www.googleapis.com/auth/calendar",
    discoveryDocs: [
      "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
    ],
  };

  // @ts-ignore
  const apiCalendar = new ApiCalendar(config);

  // ** Blank Event Object
  const blankEvent = {
    title: '',
    start: '',
    end: '',
    allDay: false,
    url: '',
    extendedProps: {
      event_id: '',
      recurring_event_id: '',
      description: '',
      event_color: '',
      repeat_data: {},
      rrule_text: ''
    }
  }

  const handleGetAllEvents = async () => {
    const res = await CalendarAPI.getAllEvents();
    const events = res.events.map((item: any) => {
      if (item.rrule_text) {
        return {
          ...item,
          url: `##${item.id}`,
          event_color: item.color,
          rrule: item.rrule_text
        }
      } else {
        return {
          ...item,
          url: `##${item.id}`,
          event_color: item.color
        }
      }
    })
    dispatch(fetchEvents(events))
  }

  // ** Fetch Events On Mount
  useEffect(() => {
    handleGetAllEvents();
  }, [])

  useEffect(() => {
    // console.clear()
  })

  const [syncing, setSyncing] = useState<boolean>(false);

  const handleSignInCalendar = async () => {
    setSyncing(true);
    try {
      await apiCalendar.handleAuthClick();

      const res = await CalendarAPI.getSyncingEvents();
      for (let i = 0; i < res.events.length; i++) {
        const eventData = res.events[i];
        if (eventData.event_id) {
          if (eventData.action === 'update') {
            let start: any = eventData.repeat_data.freq?.value ? new Date(eventData.repeat_data.start) : new Date(eventData.start);
            let end: any = eventData.repeat_data.freq?.value ? new Date(eventData.repeat_data.end) : new Date(eventData.end);
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let event: any = null
            const index = eventData.rrule_text.indexOf('RRULE');
            const rrule = eventData.rrule_text.substring(index);
            if (eventData.repeat_data.freq?.value) {
              if (eventData.allDay) {
                start = moment(start).format('YYYY-MM-DD')
                end = moment(end).format('YYYY-MM-DD')
                event = {
                  summary: eventData.title,
                  description: eventData.description,
                  start: {
                    date: start,
                    dateTime: null,
                    timeZone
                  },
                  end: {
                    date: end,
                    dateTime: null,
                    timeZone
                  },
                  recurrence: [rrule]
                }
              } else {
                event = {
                  summary: eventData.title,
                  description: eventData.description,
                  start: {
                    dateTime: start,
                    date: null,
                    timeZone
                  },
                  end: {
                    dateTime: end,
                    date: null,
                    timeZone
                  },
                  recurrence: [rrule]
                }
              }
            } else {
              if (eventData.allDay) {
                start = moment(start).format('YYYY-MM-DD')
                end = moment(end).format('YYYY-MM-DD')
                event = {
                  summary: eventData.title,
                  description: eventData.description,
                  start: {
                    date: start,
                    dateTime: null,
                    timeZone
                  },
                  end: {
                    date: end,
                    dateTime: null,
                    timeZone
                  },
                  recurrence: []
                }
              } else {
                event = {
                  summary: eventData.title,
                  description: eventData.description,
                  start: {
                    dateTime: start,
                    date: null,
                    timeZone
                  },
                  end: {
                    dateTime: end,
                    date: null,
                    timeZone
                  },
                  recurrence: []
                }
              }
            }
            if (event) {
              const response = await apiCalendar.updateEvent(event, eventData.event_id)
              const newEvent = {
                event_id: response.result.id,
              }
              const body = { newEvent };
              await CalendarAPI.syncEventWithGoogle(body, eventData.id);
            }
          } else if (eventData.action === 'delete') {
            await apiCalendar.deleteEvent(eventData.event_id);
            const newEvent = {
              event_id: eventData.event_id,
            }
            const body = { newEvent };
            await CalendarAPI.syncEventWithGoogle(body, eventData.id);
          }
        } else {
          if (eventData.action !== "delete") {
            let start: any = eventData.repeat_data.freq?.value ? new Date(eventData.repeat_data.start) : new Date(eventData.start);
            let end: any = eventData.repeat_data.freq?.value ? new Date(eventData.repeat_data.end) : new Date(eventData.end);
            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let event: any = null
            const index = eventData.rrule_text.indexOf('RRULE');
            const rrule = eventData.rrule_text.substring(index);
            if (eventData.repeat_data.freq?.value) {
              if (eventData.allDay) {
                start = moment(start).format('YYYY-MM-DD')
                end = moment(end).format('YYYY-MM-DD')
                event = {
                  summary: eventData.title,
                  description: eventData.description,
                  start: {
                    date: start,
                    dateTime: null,
                    timeZone
                  },
                  end: {
                    date: end,
                    dateTime: null,
                    timeZone
                  },
                  recurrence: [rrule]
                }
              } else {
                event = {
                  summary: eventData.title,
                  description: eventData.description,
                  start: {
                    dateTime: start,
                    date: null,
                    timeZone
                  },
                  end: {
                    dateTime: end,
                    date: null,
                    timeZone
                  },
                  recurrence: [rrule]
                }
              }
            } else {
              if (eventData.allDay) {
                start = moment(start).format('YYYY-MM-DD')
                end = moment(end).format('YYYY-MM-DD')
                event = {
                  summary: eventData.title,
                  description: eventData.description,
                  start: {
                    date: start,
                    dateTime: null,
                    timeZone
                  },
                  end: {
                    date: end,
                    dateTime: null,
                    timeZone
                  },
                  recurrence: []
                }
              } else {
                event = {
                  summary: eventData.title,
                  description: eventData.description,
                  start: {
                    dateTime: start,
                    date: null,
                    timeZone
                  },
                  end: {
                    dateTime: end,
                    date: null,
                    timeZone
                  },
                  recurrence: []
                }
              }
            }
            if (event) {
              const response = await apiCalendar.createEvent(event)
              const newEvent = {
                event_id: response.result.id,
              }
              const body = { newEvent };
              await CalendarAPI.syncEventWithGoogle(body, eventData.id);
            }
          }
        }
      }
      setSyncing(false);
    } catch (error) {
      setSyncing(false);
    }
  }

  const handleAddNewEvent = () => {
    const ev = blankEvent
    let isoTimestamp = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
    ev.start = isoTimestamp
    ev.end = isoTimestamp
    dispatch(selectEvent(ev))
    openAddEventModal();
  }

  return (
    <DashboardLayout>
      <div className="app-calendar overflow-hidden border">
        <div className="card">
          <div className="card-header" >
            <div className="card-title w-100">
              <div className="d-flex w-100 justify-content-between align-items-center">
                <div className="fs-4 fw-bold text-gray-900 text-hover-primary me-2 mb-2 lh-1">Calendar</div>
                <div className="d-flex">
                  <button
                    className="btn btn-info btn-sm me-2"
                    type="button"
                    disabled={syncing}
                    onClick={handleSignInCalendar}
                  >
                    {syncing ?
                      <>
                        <ClipLoader color={"#fff"} loading={syncing} size={12} /> Sync with Google Calendar
                      </>
                      : "Sync with Google Calendar"}

                  </button>
                  <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    onClick={handleAddNewEvent}
                  >
                    + Add Event
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body" style={{ paddingBottom: '10px' }}>
            <Calendar
              isRtl={isRtl}
              store={store}
              calendarApi={calendarApi}
              setCalendarApi={setCalendarApi}
              dispatch={dispatch}
              selectEvent={selectEvent}
              updateEvent={updateEvent}
              blankEvent={blankEvent}
              openAddEventModal={openAddEventModal}
              openEditEventModal={openEditEventModal}
            />
          </div>
        </div>
        {addEventModal && (
          <AddEventModal
            store={store}
            calendarApi={calendarApi}
            onClose={closeAddEventModal}
            refresh={() => { }} />
        )}
        {editEventModal && (
          <EditEventModal
            store={store}
            calendarApi={calendarApi}
            onClose={closeEditEventModal}
            refresh={() => { }} />
        )}
      </div>
    </DashboardLayout>
  )
}

export default CalendarPage;