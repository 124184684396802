/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef } from "react";
import { DashboardLayout } from "../../layout";
import Chatting from "./chatting";
import { useAppDispatch, useAppSelector } from "../../hooks/use-dispatch";
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import rrulePlugin from '@fullcalendar/rrule'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import { useAuth } from "../../context/AppContext";
import { useRTL } from "../../utils/useRTL";
import CalendarAPI from "../../api/calendar";
import { fetchEvents } from "../calendar/store/actions";

const ChatPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const dispatch = useAppDispatch()
  const calendarRef = useRef<any>(null)

  //eslint-disable-next-line
  const [isRtl, setIsRtl] = useRTL()
  const store = useAppSelector((state: any) => state.calendar)
  const { setCalendarApi } = useAuth();

  useEffect(() => {
      setCalendarApi(calendarRef.current.getApi())
  }, [])

  const calendarOptions = {
    events: store.events.length ? store.events : [],
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, rrulePlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, today',
      center: 'title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
    },
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 4,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClick({ event: clickedEvent }: any) {
    },

    dateClick(info: any) {
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }: any) {
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }: any) {
    },

    ref: calendarRef,

    // Get direction from app state (store)
    direction: isRtl ? 'rtl' : 'ltr'
  }

  const handleGetAllEvents = async () => {
    const res = await CalendarAPI.getAllEvents();
    const events = res.events.map((item: any) => {
      if (item.rrule_text) {
        return {
          ...item,
          url: `##${item.id}`,
          event_color: item.color,
          rrule: item.rrule_text
        }
      } else {
        return {
          ...item,
          url: `##${item.id}`,
          event_color: item.color
        }
      }
    })
    dispatch(fetchEvents(events))
  }

  // ** Fetch Events On Mount
  useEffect(() => {
    handleGetAllEvents();
  }, [])

  //useEffect(() => {
    // console.clear()
  //})

  return (
    <DashboardLayout>
      <Chatting />
      <div style={{ display: 'none' }}>
        {
          //@ts-ignore
          <FullCalendar {...calendarOptions} />
        }
      </div>
    </DashboardLayout>
  )
}

export default ChatPage;
