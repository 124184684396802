import React from 'react';

const WithoutReportIcon = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1906_5171)">
      <path
        d="M12 24C5.38312 24 0 18.6169 0 12C0 5.38312 5.38312 0 12 0C18.6165 0 24 5.38312 24 12C24 18.6169 18.6165 24 12 24ZM12 1.5C6.21 1.5 1.5 6.21 1.5 12C1.5 17.79 6.21 22.5 12 22.5C17.7896 22.5 22.5 17.7896 22.5 12C22.5 6.21037 17.7896 1.5 12 1.5Z"
        fill="currentColor"
      />
      <path
        d="M12 21C10.7591 21 9.75 19.9909 9.75 18.75C9.75 17.5091 10.7591 16.5 12 16.5C13.2409 16.5 14.25 17.5091 14.25 18.75C14.25 19.9909 13.2409 21 12 21ZM12 18C11.5864 18 11.25 18.3364 11.25 18.75C11.25 19.1636 11.5864 19.5 12 19.5C12.4132 19.5 12.75 19.1636 12.75 18.75C12.75 18.3364 12.4132 18 12 18Z"
        fill="currentColor"
      />
      <path
        d="M12 15.75C10.7764 15.75 9.77775 14.7683 9.75075 13.5514C9.67838 12.9694 9 7.479 9 6C9 4.34588 10.3459 3 12 3C13.6541 3 15 4.34588 15 6C15 7.479 14.3216 12.9694 14.2493 13.5514C14.2223 14.7683 13.2236 15.75 12 15.75ZM12 4.5C11.1727 4.5 10.5 5.17275 10.5 6C10.5 7.43663 11.2369 13.3474 11.244 13.407C11.2481 13.4377 11.25 13.4689 11.25 13.5C11.25 13.9136 11.5864 14.25 12 14.25C12.4132 14.25 12.75 13.9136 12.75 13.5C12.75 13.4689 12.7519 13.4377 12.756 13.407C12.7631 13.3474 13.5 7.43663 13.5 6C13.5 5.17275 12.8273 4.5 12 4.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1906_5171">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default WithoutReportIcon;
