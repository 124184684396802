import React from 'react';

const LocationIcon = ({ ...props }) => (
  <svg width="17" height="18" viewBox="0 0 17 18" fill="none" {...props}>
    <path
      d="M8.39338 16.0112L4.11336 10.9635C4.05388 10.8877 3.99503 10.8114 3.9368 10.7347C3.20569 9.77163 2.81073 8.5953 2.81251 7.38617C2.81251 5.90603 3.40049 4.48651 4.44711 3.4399C5.49372 2.39328 6.91324 1.8053 8.39338 1.8053C9.87352 1.8053 11.293 2.39328 12.3396 3.4399C13.3863 4.48651 13.9742 5.90603 13.9742 7.38617C13.976 8.59475 13.5812 9.77054 12.8505 10.7332L12.85 10.7347C12.85 10.7347 12.6977 10.9346 12.6749 10.9615L8.39338 16.0112ZM4.74653 10.1233C4.74755 10.1233 4.86525 10.2796 4.89214 10.3131L8.39338 14.4424L11.8992 10.3075C11.9215 10.2796 12.0402 10.1223 12.0407 10.1218C12.638 9.33496 12.9607 8.374 12.9595 7.38617C12.9595 6.17515 12.4785 5.01372 11.6221 4.1574C10.7658 3.30108 9.6044 2.82 8.39338 2.82C7.18235 2.82 6.02093 3.30108 5.16461 4.1574C4.30829 5.01372 3.82721 6.17515 3.82721 7.38617C3.82615 8.37461 4.14926 9.33613 4.74704 10.1233H4.74653Z"
      fill="currentColor"
    />
    <path
      d="M8.39614 9.6879C7.89441 9.6879 7.40396 9.53912 6.98679 9.26038C6.56962 8.98164 6.24448 8.58545 6.05248 8.12192C5.86047 7.65838 5.81024 7.14833 5.90812 6.65624C6.006 6.16416 6.2476 5.71215 6.60238 5.35738C6.95715 5.00261 7.40916 4.76101 7.90124 4.66312C8.39332 4.56524 8.90338 4.61548 9.36691 4.80748C9.83044 4.99948 10.2266 5.32462 10.5054 5.74179C10.7841 6.15896 10.9329 6.64942 10.9329 7.15114C10.9321 7.82368 10.6646 8.46845 10.189 8.94401C9.71345 9.41957 9.06868 9.68709 8.39614 9.6879ZM8.39614 5.62908C8.0951 5.62908 7.80083 5.71835 7.55053 5.8856C7.30023 6.05284 7.10514 6.29056 6.98994 6.56868C6.87474 6.84679 6.8446 7.15283 6.90333 7.44808C6.96205 7.74333 7.10702 8.01453 7.31988 8.2274C7.53274 8.44026 7.80395 8.58522 8.0992 8.64395C8.39445 8.70268 8.70048 8.67254 8.9786 8.55734C9.25672 8.44214 9.49443 8.24705 9.66168 7.99675C9.82892 7.74645 9.91819 7.45217 9.91819 7.15114C9.91779 6.74759 9.7573 6.36068 9.47195 6.07533C9.18659 5.78998 8.79969 5.62949 8.39614 5.62908Z"
      fill="currentColor"
    />
  </svg>
);

export default LocationIcon;
