import React from 'react';

const CampaignsLocationIcon = ({ ...props }) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 1C6.38317 1 1 6.38317 1 13C1 19.6168 6.38317 25 13 25C19.6168 25 25 19.6168 25 13C25 6.38317 19.6168 1 13 1ZM13 23.875C7.00352 23.875 2.125 18.9965 2.125 13C2.125 7.00352 7.00352 2.125 13 2.125C18.9965 2.125 23.875 7.00352 23.875 13C23.875 18.9965 18.9965 23.875 13 23.875ZM13 5.75781C9.9843 5.75781 7.53081 8.21125 7.53081 11.227C7.53081 13.4733 10.2192 17.1123 12.5612 20.0317C12.668 20.1647 12.8294 20.2422 13 20.2422C13.1706 20.2422 13.332 20.1647 13.4387 20.0317L13.5113 19.9412C15.963 16.8867 18.4692 13.4627 18.4692 11.227C18.4692 8.21125 16.0157 5.75781 13 5.75781ZM13.0005 18.7791C11.5493 16.9497 8.65581 13.1446 8.65581 11.227C8.65581 8.83159 10.6046 6.88281 13 6.88281C15.3954 6.88281 17.3442 8.83159 17.3442 11.227C17.3442 13.1885 14.473 16.9306 13.0005 18.7791ZM13 7.89241C11.1613 7.89241 9.66541 9.38828 9.66541 11.227C9.66541 13.0657 11.1613 14.5616 13 14.5616C14.8387 14.5616 16.3346 13.0657 16.3346 11.227C16.3346 9.38828 14.8387 7.89241 13 7.89241ZM13 13.4365C11.7816 13.4365 10.7904 12.4453 10.7904 11.227C10.7904 10.0086 11.7816 9.01736 13 9.01736C14.2184 9.01736 15.2096 10.0086 15.2096 11.227C15.2096 12.4453 14.2184 13.4365 13 13.4365Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.15"
    />
  </svg>
);

export default CampaignsLocationIcon;
