import React from 'react';

const InstagramIcon = ({ ...props }) => (
  <svg viewBox="0 0 25 25" fill="currentColor" {...props}>
    <path d="M18.224 24.808H7.66397C3.91997 24.808 0.94397 21.736 0.94397 18.088V7.52798C0.94397 3.78398 4.01597 0.807983 7.66397 0.807983H18.224C21.968 0.807983 24.944 3.87998 24.944 7.52798V18.088C25.04 21.832 21.968 24.808 18.224 24.808ZM7.66397 2.24798C4.68797 2.24798 2.38397 4.64798 2.38397 7.52798V18.088C2.38397 21.064 4.78397 23.368 7.66397 23.368H18.224C21.2 23.368 23.504 20.968 23.504 18.088V7.52798C23.504 4.55198 21.104 2.24798 18.224 2.24798H7.66397Z" />
    <path d="M12.944 19.048C9.58397 19.048 6.79997 16.36 6.79997 12.904C6.79997 9.44798 9.48797 6.75998 12.944 6.75998C16.4 6.75998 19.088 9.44798 19.088 12.904C19.088 16.36 16.304 19.048 12.944 19.048ZM12.944 8.19998C10.352 8.19998 8.23997 10.312 8.23997 12.904C8.23997 15.496 10.352 17.608 12.944 17.608C15.536 17.608 17.648 15.496 17.648 12.904C17.648 10.312 15.536 8.19998 12.944 8.19998Z" />
    <path d="M19.664 7.91198C20.4593 7.91198 21.104 7.26727 21.104 6.47198C21.104 5.67669 20.4593 5.03198 19.664 5.03198C18.8687 5.03198 18.224 5.67669 18.224 6.47198C18.224 7.26727 18.8687 7.91198 19.664 7.91198Z" />
  </svg>
);

export default InstagramIcon;
