import React from 'react';

import {
  DiscoverIcon,
  InfluencersIcon,
} from '../../svg';
import { TSidebarItem } from './types';

export const DSidebarItems: Array<TSidebarItem> = [
  {
    id: 0,
    type: 'nested',
    icon: <InfluencersIcon />,
    label: 'Chat',
    items: [
      {
        id: 14,
        label: 'Influencers',
        location: '/chat/1',
      },
      {
        id: 15,
        label: 'Clients',
        location: '/chat/2',
      },
    ],
  },
  {
    id: 1,
    type: 'route',
    icon: <DiscoverIcon />,
    label: 'Calendar',
    location: '/calendar',
  },

];
