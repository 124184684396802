import React from 'react';

const DownloadIcon = ({ ...props }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <g clipPath="url(#clip0_62_6494)">
      <path
        d="M10.4102 14.0254H2.58984C1.90322 14.0246 1.24495 13.7515 0.759438 13.266C0.273923 12.7804 0.000806368 12.1222 0 11.4355L0 3.61523C0.000806368 2.92861 0.273923 2.27034 0.759438 1.78483C1.24495 1.29931 1.90322 1.0262 2.58984 1.02539H10.4102C11.0968 1.0262 11.755 1.29931 12.2406 1.78483C12.7261 2.27034 12.9992 2.92861 13 3.61523V11.4355C12.9992 12.1222 12.7261 12.7804 12.2406 13.266C11.755 13.7515 11.0968 14.0246 10.4102 14.0254ZM2.58984 2.04102C2.17248 2.04149 1.77234 2.20749 1.47722 2.50261C1.1821 2.79773 1.0161 3.19787 1.01562 3.61523V11.4355C1.0161 11.8529 1.1821 12.253 1.47722 12.5482C1.77234 12.8433 2.17248 13.0093 2.58984 13.0098H10.4102C10.8275 13.0093 11.2277 12.8433 11.5228 12.5482C11.8179 12.253 11.9839 11.8529 11.9844 11.4355V3.61523C11.9839 3.19787 11.8179 2.79773 11.5228 2.50261C11.2277 2.20749 10.8275 2.04149 10.4102 2.04102H2.58984Z"
        fill="currentColor"
      />
      <path
        d="M6.5 9.26115C6.36532 9.26115 6.23616 9.20765 6.14092 9.11242C6.04569 9.01718 5.99219 8.88802 5.99219 8.75334V4.03271C5.99219 3.89803 6.04569 3.76887 6.14092 3.67364C6.23616 3.5784 6.36532 3.5249 6.5 3.5249C6.63468 3.5249 6.76384 3.5784 6.85908 3.67364C6.95431 3.76887 7.00781 3.89803 7.00781 4.03271V8.75334C7.00781 8.88802 6.95431 9.01718 6.85908 9.11242C6.76384 9.20765 6.63468 9.26115 6.5 9.26115Z"
        fill="currentColor"
      />
      <path
        d="M6.50029 9.62311C6.38756 9.62345 6.27588 9.60143 6.17172 9.55833C6.06756 9.51522 5.97298 9.45189 5.89345 9.372L3.92416 7.40295C3.8289 7.3077 3.77539 7.17851 3.77539 7.0438C3.77539 6.9091 3.8289 6.77991 3.92416 6.68465C4.01941 6.5894 4.1486 6.53589 4.28331 6.53589C4.41801 6.53589 4.5472 6.5894 4.64246 6.68465L6.50029 8.54274L8.35812 6.68465C8.40528 6.63749 8.46128 6.60008 8.5229 6.57455C8.58452 6.54903 8.65057 6.53589 8.71727 6.53589C8.78397 6.53589 8.85002 6.54903 8.91164 6.57455C8.97326 6.60008 9.02926 6.63749 9.07642 6.68465C9.12359 6.73182 9.161 6.78781 9.18652 6.84943C9.21205 6.91106 9.22519 6.9771 9.22519 7.0438C9.22519 7.1105 9.21205 7.17655 9.18652 7.23817C9.161 7.2998 9.12359 7.35579 9.07642 7.40295L7.10712 9.372C7.0276 9.45189 6.93302 9.51522 6.82885 9.55833C6.72469 9.60143 6.61302 9.62345 6.50029 9.62311Z"
        fill="currentColor"
      />
      <path
        d="M8.71742 11.4751H4.2832C4.14852 11.4751 4.01936 11.4216 3.92413 11.3264C3.82889 11.2311 3.77539 11.102 3.77539 10.9673C3.77539 10.8326 3.82889 10.7034 3.92413 10.6082C4.01936 10.513 4.14852 10.4595 4.2832 10.4595H8.71742C8.8521 10.4595 8.98127 10.513 9.0765 10.6082C9.17173 10.7034 9.22523 10.8326 9.22523 10.9673C9.22523 11.102 9.17173 11.2311 9.0765 11.3264C8.98127 11.4216 8.8521 11.4751 8.71742 11.4751Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_62_6494">
        <rect width="13" height="13" fill="white" transform="translate(0 1)" />
      </clipPath>
    </defs>
  </svg>
);

export default DownloadIcon;
