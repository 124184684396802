// ** Initial State
const initialState = {
  events: [],
  selectedEvent: {},
}

const calenderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'FETCH_EVENTS':
      return { ...state, events: action.events }
    case 'ADD_EVENT':
      return { ...state, events: [...state.events, action.event] }
    case 'ADD_RECURRING_EVENT':
      return { ...state, events: [...state.events, ...action.events] }
    case 'REMOVE_EVENT':
      return { ...state, events: state.events.filter((item: any) => item.id.toString() !== action.id.toString()) }
    case 'UPDATE_EVENT':
      const events = state.events.map((item: any) => {
        if (item.id.toString() === action.event.id.toString()) {
          return action.event
        } else {
          return item
        }
      })
      return { ...state, events }
    case 'SELECT_EVENT':
      return { ...state, selectedEvent: action.event }
    default:
      return state
  }
}

export default calenderReducer
