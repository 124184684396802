import React from 'react';

const RaiseIcon = ({ ...props }) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5303 11.9H2.00909V2.45455C2.00909 2.14826 1.76084 1.9 1.45455 1.9C1.14826 1.9 0.9 2.14826 0.9 2.45455V12.4545C0.9 12.7608 1.14826 13.0091 1.45455 13.0091H12.5303C12.8366 13.0091 13.0848 12.7608 13.0848 12.4545C13.0848 12.1482 12.8366 11.9 12.5303 11.9Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M12.5453 3.09727H9.13619C8.8299 3.09727 8.58164 3.34552 8.58164 3.65181C8.58164 3.9581 8.8299 4.20636 9.13619 4.20636H11.9907V7.06105C11.9907 7.36715 12.239 7.61559 12.5453 7.61559C12.8516 7.61559 13.0998 7.36712 13.0998 7.06088V3.65181C13.0998 3.34552 12.8515 3.09727 12.5453 3.09727Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M12.937 3.27508L12.937 3.27502C12.7206 3.05808 12.3699 3.05751 12.1527 3.27353L12.1527 3.27357L8.77206 6.64055L7.8452 5.71369L7.84516 5.71366C7.62857 5.49727 7.27773 5.49701 7.06105 5.71369L3.01564 9.75913C3.01563 9.75914 3.01561 9.75915 3.0156 9.75916C2.79895 9.97565 2.79897 10.3269 3.01564 10.5433C3.12381 10.6516 3.26594 10.7058 3.40769 10.7058C3.54929 10.7058 3.69164 10.6516 3.79982 10.5432L7.45314 6.89008L8.37924 7.81603C8.37924 7.81603 8.37925 7.81604 8.37925 7.81604C8.5957 8.03249 8.94569 8.03262 9.16257 7.81699L9.16264 7.81692L12.9354 4.05937C13.1524 3.84328 13.1531 3.49208 12.937 3.27508Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
);

export default RaiseIcon;
