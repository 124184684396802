import React from 'react';

const SendIcon = ({ ...props }) => (
  <svg
    width="29"
    height="17"
    viewBox="0 0 29 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.9698 0.44043H6.7498C5.3858 0.44043 4.2698 1.55643 4.2698 2.92043V3.85043C4.2698 4.19143 4.5488 4.47043 4.8898 4.47043C5.2308 4.47043 5.5098 4.19143 5.5098 3.85043V2.92043C5.5098 2.79643 5.5408 2.67243 5.5718 2.54843L12.5158 8.50043L5.5718 14.4524C5.5408 14.3284 5.5098 14.2044 5.5098 14.0804V13.1504C5.5098 12.8094 5.2308 12.5304 4.8898 12.5304C4.5488 12.5304 4.2698 12.8094 4.2698 13.1504V14.0804C4.2698 15.4444 5.3858 16.5604 6.7498 16.5604H25.9698C27.3338 16.5604 28.4498 15.4444 28.4498 14.0804V2.92043C28.4498 1.55643 27.3338 0.44043 25.9698 0.44043ZM6.5018 1.71143C6.5638 1.68043 6.6568 1.68043 6.7498 1.68043H25.9698C26.0628 1.68043 26.1558 1.68043 26.2178 1.71143L16.7628 9.80243C16.5148 9.98843 16.2048 9.98843 15.9568 9.80243L6.5018 1.71143ZM25.9698 15.3204H6.7498C6.6568 15.3204 6.5638 15.3204 6.5018 15.2894L13.4768 9.30643L15.1508 10.7634C15.4918 11.0734 15.9258 11.2284 16.3598 11.2284C16.7938 11.2284 17.2278 11.0734 17.5688 10.7634L19.2428 9.30643L26.2178 15.2894C26.1558 15.3204 26.0628 15.3204 25.9698 15.3204ZM27.2098 14.0804C27.2098 14.2044 27.1788 14.3284 27.1478 14.4524L20.2038 8.50043L27.1478 2.54843C27.1788 2.67243 27.2098 2.79643 27.2098 2.92043V14.0804ZM2.4098 6.95043C2.4098 6.60943 2.6888 6.33043 3.0298 6.33043H6.7498C7.0908 6.33043 7.3698 6.60943 7.3698 6.95043C7.3698 7.29143 7.0908 7.57043 6.7498 7.57043H3.0298C2.6888 7.57043 2.4098 7.29143 2.4098 6.95043ZM6.7498 10.6704H1.1698C0.828805 10.6704 0.549805 10.3914 0.549805 10.0504C0.549805 9.70943 0.828805 9.43043 1.1698 9.43043H6.7498C7.0908 9.43043 7.3698 9.70943 7.3698 10.0504C7.3698 10.3914 7.0908 10.6704 6.7498 10.6704Z"
      fill="currentColor"
    />
  </svg>
);

export default SendIcon;
