import React from 'react';

const ReportsSmallIcon = ({ ...props }) => (
  <svg
    width="13"
    height="15"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.6572 4.07426C9.13484 4.07426 8.7098 3.6493 8.7098 3.12694V0.457764H1.9136C1.07272 0.457764 0.388672 1.14181 0.388672 1.98269V12.8828C0.388672 13.7237 1.07272 14.4078 1.9136 14.4078H10.852C11.693 14.4078 12.377 13.7237 12.377 12.8828V4.07426H9.6572ZM8.723 11.0292H3.45897C3.23325 11.0292 3.05027 10.8463 3.05027 10.6206C3.05027 10.3948 3.23325 10.2119 3.45897 10.2119H8.723C8.9488 10.2119 9.13169 10.3948 9.13169 10.6206C9.13169 10.8463 8.9488 11.0292 8.723 11.0292ZM3.05027 8.98579C3.05027 8.76007 3.23325 8.5771 3.45897 8.5771H8.15636C8.38216 8.5771 8.56505 8.76007 8.56505 8.98579C8.56505 9.21151 8.38199 9.39448 8.15636 9.39448H3.45897C3.23325 9.39448 3.05027 9.21151 3.05027 8.98579ZM9.21998 7.75972H3.45897C3.23325 7.75972 3.05027 7.57674 3.05027 7.35103C3.05027 7.12531 3.23325 6.94233 3.45897 6.94233H9.21998C9.4457 6.94233 9.62867 7.12531 9.62867 7.35103C9.62867 7.57674 9.4457 7.75972 9.21998 7.75972Z"
      fill="currentColor"
    />
  </svg>
);

export default ReportsSmallIcon;
