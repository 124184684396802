export { default as Label } from './label';
export { default as Button } from './button';
export { default as Search } from './search';
export { default as Avatar } from './avatar';
export { default as Card } from './card';
export { default as Input } from './input';
export { default as Badge } from './badge';
export { default as Checkbox } from './checkbox';
export { default as Switch } from './switch';
export { default as Pagination } from './pagination';
export { default as InputGroup } from './input-group';
export { default as Slider } from './slider';
export { default as MinMax } from './min-max';
export { default as Image } from './image';