import React from 'react';

import { TPageLayoutProps } from './type';
import {
  PageLayoutMain,
  PageLayoutLeft,
  PageLayoutContent,
  PageLayoutRightContainer,
} from './styles';
import Header from '../../components/header';

const PageLayout = ({ children, ...props }: TPageLayoutProps) => (
  <PageLayoutMain {...props}>
    <Header />
    <PageLayoutContent>
      <PageLayoutLeft>{children}</PageLayoutLeft>
      <PageLayoutRightContainer imageUrl={"/static/assets/images/authorization.jpg"} />
    </PageLayoutContent>
  </PageLayoutMain>
);

export default PageLayout;
