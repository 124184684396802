import React from 'react';
import { ButtonMain } from './styles';
import { TButtonProps } from './types';

const Button = ({
  size = 'medium',
  color = 'primary',
  ...props
}: TButtonProps) => <ButtonMain color={color} size={size} {...props} />;

export default Button;
