import React from 'react';

const ArchiveIcon = ({ ...props }) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" {...props}>
    <g clipPath="url(#clip0_46_3215)">
      <path
        d="M12.7665 4.18782L11.9309 2.79473C11.6369 2.30436 11.0996 2 10.528 2H3.472C2.90036 2 2.36309 2.30436 2.06909 2.79473L1.23345 4.18782C1.08018 4.442 1 4.73327 1 5.02945V12.3636C1 13.2658 1.73418 14 2.63636 14H11.3636C12.2658 14 13 13.2658 13 12.3636V5.02945C13 4.73327 12.9198 4.442 12.7665 4.18782ZM3.472 3.09091H10.528C10.7189 3.09091 10.8978 3.19236 10.9955 3.356L11.4913 4.18182H2.50873L3.00455 3.35545C3.10218 3.19236 3.28164 3.09091 3.472 3.09091ZM11.3636 12.9091H2.63636C2.33527 12.9091 2.09091 12.6642 2.09091 12.3636V5.27273H11.9091V12.3636C11.9091 12.6642 11.6647 12.9091 11.3636 12.9091Z"
        fill="currentColor"
      />
      <path
        d="M9.18204 6.90918H4.8184C4.51677 6.90918 4.27295 7.15354 4.27295 7.45463C4.27295 7.75573 4.51677 8.00009 4.8184 8.00009H9.18204C9.48368 8.00009 9.72749 7.75573 9.72749 7.45463C9.72749 7.15354 9.48368 6.90918 9.18204 6.90918Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_46_3215">
        <rect
          width="14"
          height="14"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ArchiveIcon;
