/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
const Footer = () => {
  return (
    <section className="main-section p-0" id="contact" style={{}}>
      <div className="container">
        <div className="row align-items-center gy-5">
          <div className="col-lg-12 order-xs-2 mt-5">
            <div className="main-box">
                <div className="d-flex align-items-baseline">
                  <p className="custom-logo-big">Chat</p>
                  <p className="custom-logo-small">&nbsp;Bot</p>
                </div>
              <div className="footer-des">
                <p className='pt-3 pb-3 mb-0'>Powered By © 2023 chatbot.org All Rights Reserved. </p>
                <p className='pt-3 pb-3 mb-0'>Support: info@chatbot.com</p>
              </div>
              <div className="social-btn">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <img src="images/facebook.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <img src="images/instagram.svg" alt="" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      <img src="images/twitter.svg" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer;
