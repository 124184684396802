import styled from '@emotion/styled';
import { CardWithText } from '../../custom';
import { Theme } from '@mui/material';
import { Card } from '../../ui';

export const NotificationCard = styled(Card)`
	gap: 20px;  
  display: flex;
  flex-direction: column;
  background-color: #FF0000; /* Red background */
  color: #000000; /* Black text */
`;

export const NotificationTitle = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    gap: ${theme.spacing(2)};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    color:#fff;
    font-weight: 500;

    svg {
      cursor: pointer;
    }
`}
`;

export const NotificationsCardMain = styled(CardWithText)`
  // max-height: 400px;
  // overflow-y: auto;
  // padding-right: 5px;
`;

export const NotificationsCardList = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
    max-height: 400px;
    overflow-y: auto;
`}
`;
