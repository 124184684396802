import axiosInstance from "../../axios/axiosInstance";
import { API } from "../../utils/constant";
import { getToken } from "../../utils/helper";

const CalendarAPI = {

  getAllEvents: async () => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.get(`${API}/events/get_upcomming_events`, {
      headers: headers
    });
    return data;
  },

  getSyncingEvents: async () => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.get(`${API}/events/get_syncing_events`, {
      headers: headers
    });
    return data;
  },

  addEvent: async (body: any) => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.post(`${API}/events`, body, {
      headers: headers
    });
    return data;
  },

  updateEvent: async (body: any, id: any) => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.post(`${API}/events/update_events/${id}`, body, {
      headers: headers
    });
    return data;
  },

  syncEventWithGoogle: async (body: any, id: any) => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.post(`${API}/events/sync_event_with_google/${id}`, body, {
      headers: headers
    });
    return data;
  },

  deleteEvent: async (body: any, id: any) => {
    const headers = {"Content-Type": "application/json",  Authorization: `Bearer ${getToken()}`}
    const { data } = await axiosInstance.post(`${API}/events/delete_event/${id}`,body, {
      headers: headers
    });
    return data;
  },
};

export default CalendarAPI;
