import React from 'react';

const AccountIcon = ({ ...props }) => (
  <svg width="24" height="24" viewBox="0 0 21 21" fill="none" {...props}>
    <path
      d="M10.4141 1.95508C5.81164 1.95508 2.08081 5.68591 2.08081 10.2884C2.08081 14.8909 5.81164 18.6217 10.4141 18.6217C15.0166 18.6217 18.7475 14.8909 18.7475 10.2884C18.7475 5.68591 15.0166 1.95508 10.4141 1.95508Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.97144 15.5774C3.97144 15.5774 5.82894 13.2057 10.4123 13.2057C14.9956 13.2057 16.8539 15.5774 16.8539 15.5774M10.4123 10.2891C11.0753 10.2891 11.7112 10.0257 12.18 9.55683C12.6489 9.08799 12.9123 8.4521 12.9123 7.78906C12.9123 7.12602 12.6489 6.49014 12.18 6.0213C11.7112 5.55245 11.0753 5.28906 10.4123 5.28906C9.74923 5.28906 9.11334 5.55245 8.6445 6.0213C8.17566 6.49014 7.91227 7.12602 7.91227 7.78906C7.91227 8.4521 8.17566 9.08799 8.6445 9.55683C9.11334 10.0257 9.74923 10.2891 10.4123 10.2891V10.2891Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default AccountIcon;
