import React, { Children, useEffect, useState } from 'react';
import { Modal } from '../../../components/custom';
import {
  AddProjectModalMain, ColorInput,
} from './style';
import { Button, Input } from '../../../components/ui';
import { TAddEventModalProps } from './types';
import { Stack } from '../../../components/system';
import DateRangePicker from './dateRangePicker';
import { MuiColorInputFormat } from 'mui-color-input'
import { InputLabel, InputMain } from '../../../components/ui/input/styles';
import { DMontlyRepeatOn, DRepeat, DWeeklyRepeatOn } from './data';
import { datetime, RRule } from 'rrule'
import moment from 'moment';
import dayjs from 'dayjs';
import CalendarAPI from '../../../api/calendar';
import { useAppDispatch } from '../../../hooks/use-dispatch';
import { removeEvent, updateEvent } from '../store/actions';
import ClipLoader from "react-spinners/ClipLoader";
import { checkTime } from '../../../utils/helper';

const EditEventModal = ({
  onClose,
  refresh,
  calendarApi,
  store,
  ...props
}: TAddEventModalProps) => {

  const dispatch = useAppDispatch()
  const selectedEvent = store.selectedEvent
  const [eventData, setEventData] = useState<any>({
    id: '',
    event_id: '',
    name: '',
    description: '',
    allDay: true,
    from: null,
    to: null,
    eventColor: '#009ef7',
    rrule: '',
    full_rrule: ''
  });

  useEffect(() => {
    let originStartTime: any = new Date(selectedEvent.start)
    let originEndTime: any = selectedEvent.end ? new Date(selectedEvent.end) : new Date(selectedEvent.start);
    const time = moment(new Date()).format('HH:mm:ss');
    const startd = moment(originStartTime).format('YYYY-MM-DD');
    const endd = moment(originEndTime).format('YYYY-MM-DD');
    let formatedStartTime = `${startd}T${time}`;
    let formatedEndTime = `${endd}T${time}`;

    setEventData((data: any) => ({
      ...data,
      id: selectedEvent.id,
      event_id: selectedEvent.extendedProps.event_id,
      from: selectedEvent.allDay ? dayjs(new Date(formatedStartTime)) : dayjs(originStartTime),
      to: selectedEvent.allDay ? dayjs(new Date(formatedEndTime)) : dayjs(originEndTime),
      name: selectedEvent.title,
      description: selectedEvent.extendedProps.description,
      eventColor: selectedEvent.extendedProps.event_color,
      allDay: selectedEvent.allDay,
    }))

    setRepeatData((data: any) => ({
      ...data,
      ...selectedEvent.extendedProps.repeat_data
    }))
  }, [selectedEvent])

  const [repeatData, setRepeatData] = useState<any>({
    start: "",
    end: "",
    freq: DRepeat[0],
    interval: 1,
    weeklyRepeatOn: [DWeeklyRepeatOn[0]],
    monthlyRepeatOn: DMontlyRepeatOn[0]
  })

  useEffect(() => {
    if (repeatData.start) {
      if (repeatData.freq?.value) {
        const start = new Date(repeatData.start);
        const freq = repeatData.freq?.value;
        let rule = new RRule({
          freq: RRule.DAILY,
          interval: repeatData.interval,
          dtstart: datetime(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()),
          until: datetime(start.getUTCFullYear(), 12, 31)
        })
        switch (freq) {
          case "Weekly":
            let byweekday: any[] = [];
            repeatData.weeklyRepeatOn.forEach((element: any) => {
              // @ts-ignore
              byweekday.push(RRule[element?.value]);
            });
            rule = new RRule({
              freq: RRule.WEEKLY,
              interval: repeatData.interval,
              byweekday: byweekday,
              dtstart: datetime(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()),
              until: datetime(start.getUTCFullYear(), 12, 31)
            })
            break;

          case "Monthly":
            switch (repeatData.monthlyRepeatOn?.value) {
              case "On day 25":
                rule = new RRule({
                  freq: RRule.MONTHLY,
                  interval: 1,
                  bymonthday: [25],
                  dtstart: datetime(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()),
                  until: datetime(start.getUTCFullYear(), 12, 31)
                })
                break;
              case "On the fourth Monday":
                rule = new RRule({
                  freq: RRule.MONTHLY,
                  interval: 1,
                  byweekday: [RRule.MO],
                  bysetpos: [4],
                  dtstart: datetime(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()),
                  until: datetime(start.getUTCFullYear(), 12, 31)
                })
                break;
              case "On the last Monday":
                rule = new RRule({
                  freq: RRule.MONTHLY,
                  interval: 1,
                  byweekday: [RRule.MO],
                  bysetpos: [-1],
                  dtstart: datetime(start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()),
                  until: datetime(start.getUTCFullYear(), 12, 31)
                })
                break;

              default:
                break;
            }
            break;

          default:
            break;
        }

        const rrule = rule.toString();
        const index = rrule.indexOf('RRULE');
        setEventData((data: any) => ({
          ...data,
          rrule: rrule.substring(index),
          full_rrule: rrule
        }))
      } else {
        setEventData((data: any) => ({
          ...data,
          rrule: "",
          full_rrule: ""
        }))
      }

    }
  }, [repeatData])

  const [errors, setErrors] = useState([false, false, false, false, false, false, false, false]);

  const handleErrors = (index: number) => (value: boolean) => {
    setErrors((x) => x.map((a, b) => (b === index ? value : a)));
  };

  const format: MuiColorInputFormat = 'hex'

  const isDisabled =
    !eventData.name ||
    !eventData.description ||
    !eventData.to ||
    !eventData.from ||
    !!errors.find((x) => x === true)

  const [updating, setUpdating] = useState<boolean>(false);

  const handleUpdateEvent = async () => {
    setUpdating(true);
    const startDate: any = new Date(eventData.from);
    const endDate: any = new Date(eventData.to);
    const milliseconds = endDate - startDate;
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;

    hours = checkTime(hours);
    minutes = checkTime(minutes);
    const duration = `${hours}:${minutes}`

    const newEvent = {
      title: eventData.name,
      description: eventData.description,
      start: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
      end: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      color: eventData.eventColor,
      allDay: eventData.allDay,
      duration: eventData.allDay ? null : duration,
      rrule_text: eventData.full_rrule,
      repeat_data: repeatData
    }
    const body = { newEvent };
    const res = await CalendarAPI.updateEvent(body, eventData.id);

    if (res.event.rrule_text) {
      const event = {
        ...res.event,
        rrule: res.event.rrule_text,
        url: `##${res.event.id}`,
        event_color: res.event.color
      }
      dispatch(updateEvent(event))
    } else {
      const event = {
        ...res.event,
        url: `##${res.event.id}`,
        event_color: res.event.color
      }
      dispatch(updateEvent(event))
    }

    setUpdating(false);
    onClose();
  };

  const [deleteing, setDeleting] = useState<boolean>(false);

  const handleDeleteEvent = async () => {
    setDeleting(true);
    await CalendarAPI.deleteEvent(eventData, eventData.id);
    dispatch(removeEvent(eventData.id))
    setDeleting(false);
    onClose();
  }

  const handleChange = (name: string, value: any) => {
    setEventData((data: any) => ({
      ...data,
      [name]: value
    }))
  };

  return (
    <Modal
      size="medium"
      title="Update an Event"
      actions={Children.toArray([
        <Button
          color="primary"
          variant="contained"
          size="large"
          disabled={isDisabled}
          onClick={handleUpdateEvent}
        >
          {
            !updating ? "Update" :
              <ClipLoader color={"#fff"} loading={updating} size={20} />
          }
        </Button>,

        <Button
          color="error"
          variant="contained"
          size="large"
          onClick={handleDeleteEvent}
        >
          {
            !deleteing ? "Delete" :
              <ClipLoader color={"#fff"} loading={deleteing} size={20} />
          }
        </Button>

      ])}
      onClose={onClose}
      {...props}
    >
      <Stack>
        <AddProjectModalMain columns={1}>
          <Input
            type="text"
            label="Event Title"
            required
            placeholder="Please Enter"
            value={eventData?.name}
            onValue={(name: any) => setEventData({ ...eventData, name })}
            errorCallback={handleErrors(0)}
            validators={[
              {
                message: 'Event Title is required',
                validator: (name: any) => {
                  const v = name as string;
                  if (v) return true;
                  return false;
                },
              },
            ]}
          />

          <Input
            type="text"
            label="Event Description"
            multiline
            rows={2}
            required
            placeholder="Please Enter"
            value={eventData?.description}
            onValue={(description: any) => setEventData({ ...eventData, description })}
            errorCallback={handleErrors(1)}
            validators={[
              {
                message: 'Event Description is required',
                validator: (description: any) => {
                  const v = description as string;
                  if (v) return true;
                  return false;
                },
              },
            ]}
          />

          <DateRangePicker
            event={eventData}
            handleChange={handleChange}
            handleErrors={handleErrors}
            repeatData={repeatData}
            setRepeatData={setRepeatData}
            disabled={false}
          />

          <Stack direction="horizontal" style={{ position: 'relative', marginTop: '20px' }}>
            <Input
              type="select"
              label="Recurrence"
              placeholder="Please Select"
              options={DRepeat}
              value={repeatData.freq}
              onValue={(freq) => setRepeatData({ ...repeatData, freq })}
            />
          </Stack>

          <Stack direction="horizontal" style={{ position: 'relative' }}>
            {
              repeatData?.freq?.value &&
              <Input
                type="number"
                label="Interval"
                placeholder=""
                value={repeatData.interval}
                onValue={(interval) => setRepeatData({ ...repeatData, interval })}
              />
            }
            {
              repeatData?.freq?.value === 'Weekly' &&
              <Input
                type="multiselect"
                label="Repeat On"
                placeholder="Please Select"
                options={DWeeklyRepeatOn}
                value={repeatData.weeklyRepeatOn}
                errorCallback={handleErrors(5)}
                onValue={(weeklyRepeatOn) => setRepeatData({ ...repeatData, weeklyRepeatOn })}
                validators={[
                  {
                    message: 'Repeat on is required',
                    validator: (weeklyRepeatOn: any) => {
                      if (weeklyRepeatOn.length > 0) return true;
                      return false;
                    },
                  },
                ]}
              />
            }
            {
              repeatData?.freq?.value === 'Monthly' &&
              <Input
                type="select"
                label="Repeat On"
                placeholder="Please Select"
                options={DMontlyRepeatOn}
                value={repeatData.monthlyRepeatOn}
                errorCallback={handleErrors(5)}
                onValue={(monthlyRepeatOn) => setRepeatData({ ...repeatData, monthlyRepeatOn })}
                validators={[
                  {
                    message: 'Repeat on is required',
                    validator: (monthlyRepeatOn: any) => {
                      if (monthlyRepeatOn) return true;
                      return false;
                    },
                  },
                ]}
              />
            }
          </Stack>

        </AddProjectModalMain>
      </Stack>
    </Modal>
  );
};

export default EditEventModal;
