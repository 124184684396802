import React from 'react';

const FurnitureIcon = ({ ...props }) => (
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" {...props}>
    <path
      d="M20.994 13.7054C21.0259 14.6518 20.966 15.5605 20.6491 16.4406C20.3633 17.2344 19.9566 17.9511 19.4175 18.6028C18.8185 19.327 18.0911 19.8797 17.262 20.3011C16.5802 20.6476 15.8561 20.8558 15.0903 20.946C13.2332 21.1645 11.5608 20.7221 10.0789 19.5881C8.77667 18.5916 7.97065 17.2751 7.60195 15.6786C7.44798 15.0119 7.40985 14.3363 7.46585 13.6661C7.57824 12.3213 8.042 11.0973 8.89436 10.0375C9.74222 8.98333 10.7941 8.21751 12.0857 7.78525C12.9621 7.49193 13.8644 7.38488 14.7746 7.46149C16.1287 7.57547 17.3478 8.05382 18.4319 8.89971C19.3717 9.63304 20.0509 10.5528 20.4945 11.6373C20.7123 12.1697 20.8007 12.7555 20.9422 13.3185C20.9718 13.4363 20.9774 13.5603 20.994 13.7054ZM13.6496 13.2571C13.5694 13.0129 13.5573 12.7777 13.7503 12.5752C13.9261 12.3907 14.1504 12.2825 14.3909 12.3709C14.6572 12.4688 14.88 12.6447 14.8572 12.9854C15.2579 12.9854 15.646 12.9854 16.1047 12.9854C16.0118 12.6975 15.9539 12.4288 15.8406 12.186C15.6311 11.737 15.2477 11.4625 14.7915 11.2723C14.7915 10.8308 14.7915 10.4006 14.7915 9.96424C14.4061 9.96424 14.0368 9.96424 13.6507 9.96424C13.6507 10.3176 13.6337 10.6585 13.6569 10.9967C13.6715 11.2103 13.6052 11.3253 13.4091 11.4002C12.825 11.6232 12.3072 12.5478 12.436 13.2791C12.5834 14.1157 13.3808 14.788 14.157 14.8047C14.6368 14.815 14.9163 15.0517 14.8468 15.597C14.8182 15.8217 14.685 15.9715 14.4627 16.0477C14.2392 16.1243 14.0211 16.1334 13.8504 15.9615C13.7251 15.8353 13.6417 15.6675 13.5377 15.5154C13.1761 15.5154 12.7914 15.5154 12.4071 15.5154C12.4755 16.3392 12.9271 16.8538 13.6622 17.1874C13.6622 17.6087 13.6622 18.0459 13.6622 18.4738C14.0545 18.4738 14.424 18.4738 14.8041 18.4738C14.8041 18.0231 14.8041 17.5912 14.8041 17.1625C15.5378 16.9619 16.192 15.9771 16.0161 15.1308C15.848 14.322 15.0748 13.6495 14.2843 13.6467C14.0104 13.6458 13.7978 13.5299 13.6496 13.2571Z"
      fill="currentColor"
    />
    <path
      d="M1.13186 1.67069C1.98188 0.988245 2.95245 0.593659 3.97604 0.333708C4.50966 0.19819 5.0621 0.110935 5.61118 0.0685875C6.31977 0.0139381 7.03772 -0.029137 7.74387 0.024738C8.46843 0.080018 9.18921 0.232714 9.90146 0.389156C10.7551 0.576646 11.5346 0.957471 12.2479 1.45879C12.9861 1.97757 13.5311 2.65169 13.6404 3.57356C13.7173 4.22197 13.4769 4.80269 13.0701 5.31334C12.6299 5.86581 12.0667 6.27268 11.4328 6.54518C10.7693 6.8304 10.0775 7.07383 9.37551 7.23972C8.44036 7.4607 7.47606 7.53839 6.51373 7.49762C4.91751 7.43 3.3611 7.16486 1.9348 6.39078C1.26842 6.02912 0.67059 5.57562 0.289307 4.90015C-0.14168 4.13662 -0.101413 3.11529 0.479416 2.37241C0.671926 2.12619 0.903102 1.9102 1.13186 1.67069Z"
      fill="currentColor"
    />
    <path
      d="M2.43072 10.3409C1.91433 10.0155 1.37046 9.75118 0.927707 9.36954C0.400296 8.91493 0.0134001 8.33277 0.013672 7.58139C0.0138039 7.21688 0.0136949 6.85238 0.0136949 6.45268C0.312357 6.68188 0.575074 6.90962 0.862791 7.09948C1.78902 7.71067 2.81268 8.09367 3.88701 8.35153C5.17162 8.65986 6.47841 8.6818 7.78833 8.64763C8.05746 8.64061 8.32535 8.58557 8.61127 8.55053C8.26209 8.9941 7.90997 9.3915 7.61655 9.82833C7.33002 10.2549 7.10343 10.7218 6.8265 11.2136C6.29827 11.1626 5.72004 11.1041 5.14126 11.0516C4.40142 10.9845 3.69096 10.7941 2.99294 10.5498C2.81035 10.4859 2.62961 10.4168 2.43072 10.3409Z"
      fill="currentColor"
    />
    <path
      d="M2.91241 17.8934C2.26714 17.656 1.66464 17.3652 1.15297 16.9382C0.490441 16.3854 -0.0381771 15.7387 0.0109849 14.7918C0.0276967 14.4699 0.0136344 14.1465 0.0136344 13.8094C1.865 15.4502 4.1116 15.9018 6.48825 16.0458C6.70474 16.9113 7.03692 17.7359 7.55238 18.5295C5.93728 18.5979 4.40728 18.4526 2.91241 17.8934Z"
      fill="currentColor"
    />
    <path
      d="M1.02811 13.137C0.556911 12.7043 0.139036 12.2395 0.0561929 11.604C-0.00518329 11.1331 0.0441224 10.6478 0.0441224 10.147C1.88118 11.7756 4.12985 12.2167 6.49629 12.3621C6.34239 13.1744 6.17293 13.9861 6.31119 14.8411C6.04234 14.8251 5.78788 14.8321 5.54153 14.7908C4.9265 14.6876 4.30624 14.5963 3.70435 14.4383C2.73688 14.1844 1.81913 13.8053 1.02811 13.137Z"
      fill="currentColor"
    />
  </svg>
);
export default FurnitureIcon;
