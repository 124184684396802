/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "../context/AppContext";
import { setToken, setUser } from "../utils/helper";
import {
  Alert,
  message
} from "antd";
import { validateEightNumber, validateEmailAddress } from "../utils/authValidation";
import AuthorizationAPI from "../api/authorization";

const Login = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();
  const { setCurrentUser, setJustLoggedIn } = useAuth();

  const [userinfo, setUserInfo] = useState<any>({
    email: "",
    password: ""
  })
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errors, setErrors] = useState<any>({
    email: "",
    password: ""
  })
  
  const handleLogin = async () => {
    setErrorMsg("");

    if (!userinfo.email) {
      setErrors((info: any) => ({ ...info, email: "Email is required" }))
    }
    if (!userinfo.password) {
      setErrors((info: any) => ({ ...info, password: "Password is required" }))
    }

    if (!validateEmailAddress(userinfo.email)) {
      return;
    }
    if (!validateEightNumber(userinfo.password)) {
      return;
    }

    setLoading(true);
    const values = {
      identifier: userinfo.email,
      password: userinfo.password,
    };

    AuthorizationAPI.login(values)
      .then(data => {
        if (!data?.user.blocked) {
          if (data?.user.apiKey) {
            setToken(data.jwt);
            setCurrentUser(data.user);
            setUser(data.user);
            message.success(`Welcome to Helpful Howie ${data.user.username}!`);
	    console.log("Setting justLoggedIn to true");
	    setJustLoggedIn(true); // Set the flag here
	    navigate("/chat", { replace: true });
          } else {
            setErrorMsg("Not found valid Api Key for this User, Please contact to support team!");
          }
        } else {
          setErrorMsg("Your account has blocked!");
        }
      })
      .catch(error => {
        setErrorMsg(error?.response?.data?.error?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;
    setUserInfo((info: any) => ({ ...info, [field]: value }))
    switch (field) {
      case 'email':
        if (!validateEmailAddress(value)) {
          setErrors((info: any) => ({ ...info, email: "Email is invalid" }))
        } else {
          setErrors((info: any) => ({ ...info, email: "" }))
        }
        break;
      case 'password':
        if (!validateEightNumber(value)) {
          setErrors((info: any) => ({ ...info, password: "Password must be at least eight characters" }))
        } else {
          setErrors((info: any) => ({ ...info, password: "" }))
        }
        break;

      default:
        break;
    }
  }

  return (
    <>
      <div className="login-bg">
        <Header />
            <div className="container" style={{ height: '100%' }}>
              <div className="row align-items-center gy-5" style={{ height: '100%' }}>
                <div className="middle-back">
                  <img src="/images/topback.svg" alt="" />
                </div>

                <div className="col-lg-12 mb-3" style={{ zIndex: '1' }} >
                  <div className="row align-items-center justify-content-center  py-5">
                    <div className="login-panel">
                      {
                        loading &&
                        <div className="loading-panel">
                          <div className="loading-bar">
                            <ClipLoader color={"#06b6aa"} loading={loading} size={50} />
                          </div>
                        </div>
                      }
                      <div className="col-lg-9  col-11">
                        <div className="login-title" >
                          Signin Now
                        </div>
                      </div>
                      {errorMsg ? (
                        <Alert
                          className="alert_error"
                          message={errorMsg}
                          type="error"
                          closable
                          afterClose={() => setErrorMsg("")}
                        />
                      ) : null}
                      <div className="col-lg-9 col-11">
                        <input
                          className="login-input"
                          type="text"
                          name="email"
                          placeholder="Email Address"
                          value={userinfo.email}
                          onChange={handleChange}
                        />
                        {
                          errors.email &&
                          <p className="error-text">{errors.email}</p>
                        }
                      </div>
                      <div className="col-lg-9 col-11">
                        <input
                          className="login-input"
                          name="password"
                          type="password"
                          value={userinfo.password}
                          placeholder="Your Password"
                          onChange={handleChange}
                        />
                        {
                          errors.password &&
                          <p className="error-text">{errors.password}</p>
                        }
                      </div>
                      <div className="col-lg-9 col-11">
                        <Link to="/forgot_password" className="login-text1">
                          Forgot Password?
                        </Link>
                      </div>
                      <div className="col-lg-9 col-11">
                        <div className="login-button-container">
                          <button className="login-btn d-flex justify-content-between" onClick={handleLogin}>
                            <p className="m-0">
                              Signin Now
                            </p>
                            <div style={{ width: '30px', borderRadius: '100px', background: 'black', height: '30px', marginLeft: '20px', position: 'relative' }}>
                              <img src="/images/play.png" alt="" style={{ width: '10px', position: 'absolute', top: '10px', left: '10px' }} /></div>
                          </button>
                          <Link to='/register' className="login-text2">
                            Or Signup Now
                          </Link>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}

export default Login;
