/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import { Link, useLocation, useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { validateEightNumber } from "../utils/authValidation";
import AuthorizationAPI from "../api/authorization";
import { Alert, message } from "antd";

const ResetPassword = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");

  const [userinfo, setUserinfo] = useState({
    password: '', confirmPassword: ''
  })

  const [errors, setErrors] = useState({
    password: '', confirmPassword: ''
  })

  const [loading, setLoading] = useState<boolean>(false);

  const useQuery = () => new URLSearchParams(useLocation().search);

  const query = useQuery();

  const code = query.get('code');

  const handleResetPassword = async () => {
    if (!userinfo.password) {
      setErrors((info: any) => ({ ...info, password: "Password is required" }))
    }

    if (!validateEightNumber(userinfo.password)) {
      return;
    }

    if (userinfo.password !== userinfo.confirmPassword) {
      return;
    }

    if (!code)
      return;

    const values = {
      password: userinfo.password,
      passwordConfirmation: userinfo.confirmPassword,
      code
    };

    setLoading(true);

    setErrorMsg("");
    AuthorizationAPI.confirmResetPassword(values)
      .then(data => {
        message.success(`Your password has been resetted. In a few second you'll be redirected to login page.`);

        setTimeout(() => { navigate("/login", { replace: true }); }, 5000);
      })
      .catch(error => {
        setErrorMsg(error?.response?.data?.error?.message ?? "Something went wrong!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleChange = (event: any) => {
    const field = event.target.name;
    const value = event.target.value;
    setUserinfo(info => ({ ...info, [field]: value }))
    switch (field) {
      case 'password':
        if (!validateEightNumber(value)) {
          setErrors(info => ({ ...info, password: "Password must be at least eight characters" }))
        } else {
          setErrors(info => ({ ...info, password: "" }))
        }
        break;
      case 'confirmPassword':
        if (userinfo.password !== value) {
          setErrors(info => ({ ...info, confirmPassword: "Passwords must match" }))
        } else {
          setErrors(info => ({ ...info, confirmPassword: "" }))
        }
        break;

      default:
        break;
    }

  }
  return (
    <>
      <div className="login-bg">
        <Header />
        <div className="mask-bg">
          <div className="main-section bottom-padding" style={{ height: '100%' }}>
            <div className="container" style={{ height: '100%' }}>
              <div className="row align-items-center gy-5" style={{ height: '100%' }}>
                <div className="middle-back">
                  <img src="/images/topback.svg" alt="" />
                </div>

                <div className="col-lg-12 mb-3" style={{ zIndex: '1' }}>
                  <div className="row align-items-center justify-content-center  py-5">
                    <div className="login-panel">
                      {
                        loading &&
                        <div className="loading-panel">
                          <div className="loading-bar">
                            <ClipLoader color={"#06b6aa"} loading={loading} size={50} />
                          </div>
                        </div>
                      }
                      <div className="col-lg-9  col-11">
                        <div className="login-title" >
                          Reset Password
                        </div>
                      </div>

                      {errorMsg ? (
                        <Alert
                          className="alert_error"
                          message={errorMsg}
                          type="error"
                          closable
                          afterClose={() => setErrorMsg("")}
                        />
                      ) : null}

                      <div className="col-lg-9 col-11">
                        <input
                          className="login-input"
                          type="password"
                          name="password"
                          placeholder="New Password"
                          value={userinfo.password}
                          onChange={handleChange}
                        />
                        {
                          errors.password &&
                          <p className="error-text">{errors.password}</p>
                        }
                      </div>
                      <div className="col-lg-9 col-11">
                        <input
                          className="login-input"
                          type="password"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={userinfo.confirmPassword}
                          onChange={handleChange}
                        />
                        {
                          errors.confirmPassword &&
                          <p className="error-text">{errors.confirmPassword}</p>
                        }
                      </div>

                      <div className="col-lg-9 col-11">
                        <div className="login-button-container">
                          <button className="login-btn d-flex justify-content-between" onClick={handleResetPassword}>
                            <p className="m-0">
                              Change Password
                            </p>
                            <div style={{ width: '30px', borderRadius: '100px', background: 'black', height: '30px', marginLeft: '20px', position: 'relative' }}>
                              <img src="/images/play.png" alt="" style={{ width: '10px', position: 'absolute', top: '10px', left: '10px' }} /></div>
                          </button>
                          <Link to={'/login'} className="login-text2">
                            Or Signin Now
                          </Link>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ResetPassword;