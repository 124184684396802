import { combineReducers } from 'redux'

// ** Reducers Imports
import calendar from '../pages/calendar/store/reducer'
import layout from './reducers/layout'

export const rootReducer = combineReducers({
  calendar,
  layout
})

export type RootState = ReturnType<typeof rootReducer>