import styled from '@emotion/styled';
import { Theme } from '@mui/material';
import { Link } from 'react-router-dom';

export const NotificationMain = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
        padding: ${theme.spacing(2.5)};
        border-radius: 4px;
        border: 1px solid #ebebeb10;  
        background-color: #252339;
    `}
`;

export const NotifictionLinkContainer = styled(Link)<{ theme?: Theme }>`
  ${({ theme }) => `
    text-decoration: none;
  `}
`;

export const NotificationContent = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
    display: block;
    // margin-bottom: ${theme.spacing(2.5)};
`}
`;

export const NotificationStatus = styled.div<{
  theme?: Theme;
  color: string;
}>`
  ${({ theme, color }) => `
    width: 8px;
    height: 8px;
    border-radius: 50%;
    float: left;
    margin: 7.5px 5px 0 0;
    background-color: ${color};
`}
`;

export const NotificationText = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
font-size: 16px;
color: #d2cdcd;
`}
`;

export const NotificationDescription = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
font-size: 14px;
color: #999999;
`}
`;

export const NotificationDate = styled.div<{ theme?: Theme }>`
  ${({ theme }) => `
font-size: 12px;
color: #999999;
`}
`;
