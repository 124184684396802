import React from 'react';

const EnvelopeIcon = ({ ...props }) => (
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" {...props}>
    <path
      d="M14.4482 0.0889893H1.69193C1.41 0.0889893 1.13961 0.203022 0.940258 0.406002C0.740903 0.608983 0.628906 0.884283 0.628906 1.17134V9.83014C0.628906 10.1172 0.740903 10.3925 0.940258 10.5955C1.13961 10.7985 1.41 10.9125 1.69193 10.9125H14.4482C14.7301 10.9125 15.0005 10.7985 15.1999 10.5955C15.3992 10.3925 15.5112 10.1172 15.5112 9.83014V1.17134C15.5112 0.884283 15.3992 0.608983 15.1999 0.406002C15.0005 0.203022 14.7301 0.0889893 14.4482 0.0889893ZM13.2789 1.17134L8.07007 4.84051L2.86125 1.17134H13.2789ZM1.69193 9.83014V1.66381L7.76711 5.94451C7.85608 6.00735 7.96178 6.04103 8.07007 6.04103C8.17835 6.04103 8.28406 6.00735 8.37303 5.94451L14.4482 1.66381V9.83014H1.69193Z"
      fill="currentColor"
    />
  </svg>
);

export default EnvelopeIcon;
