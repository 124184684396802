import React from 'react';

const ClientIdentifiedIcon = ({ ...props }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.33216 6.8C0.996156 6.8 0.660156 6.548 0.660156 6.128V1.844C0.660156 1.088 1.24816 0.5 2.00416 0.5H6.12016C6.45616 0.5 6.79216 0.752 6.79216 1.172C6.79216 1.592 6.45616 1.76 6.12016 1.76H2.00416L1.92016 6.128C1.92016 6.464 1.66816 6.8 1.33216 6.8Z"
      fill="currentColor"
    />
    <path
      d="M20.1474 21.5H16.0314C15.6954 21.5 15.3594 21.248 15.3594 20.828C15.3594 20.408 15.6114 20.156 16.0314 20.156H20.1474V15.788C20.1474 15.452 20.3994 15.116 20.8194 15.116C21.2394 15.116 21.4914 15.368 21.4914 15.788V20.072C21.4914 20.912 20.9034 21.5 20.1474 21.5Z"
      fill="currentColor"
    />
    <path
      d="M6.12016 21.5001H2.00416C1.24816 21.5001 0.660156 20.9121 0.660156 20.2401V15.9561C0.660156 15.6201 0.912156 15.2841 1.33216 15.2841C1.75216 15.2841 2.00416 15.5361 2.00416 15.9561V20.2401H6.20416C6.54016 20.2401 6.87616 20.4921 6.87616 20.9121C6.87616 21.3321 6.45616 21.5001 6.12016 21.5001Z"
      fill="currentColor"
    />
    <path
      d="M20.7354 6.71602C20.3994 6.71602 20.0634 6.46402 20.0634 6.04402V1.76002H15.8634C15.5274 1.76002 15.1914 1.50802 15.1914 1.08802C15.1914 0.668016 15.4434 0.416016 15.8634 0.416016H19.9794C20.7354 0.416016 21.3234 1.00402 21.3234 1.67602V5.96002C21.4074 6.38002 21.0714 6.71602 20.7354 6.71602Z"
      fill="currentColor"
    />
    <path
      d="M16.957 17.72H5.19703C4.69303 17.72 4.35703 17.468 4.02103 17.132C3.76903 16.712 3.68503 16.208 3.85303 15.788C4.52503 14.192 5.70103 12.848 7.29703 12.008C6.62503 11.168 6.28903 10.076 6.28903 9.068C6.28903 6.212 8.80903 3.86 11.749 4.196C14.101 4.448 15.865 6.296 16.117 8.564C16.285 9.824 15.949 11.084 15.109 12.092C16.621 12.932 17.797 14.192 18.469 15.788L18.553 15.872V16.04C18.553 16.964 17.881 17.72 16.957 17.72ZM11.245 5.288C9.22903 5.288 7.54903 6.968 7.54903 8.984C7.54903 9.908 7.96903 10.916 8.64103 11.588L8.80903 11.756V12.008C8.80903 12.176 8.80903 12.428 8.55703 12.68L8.38903 12.764C6.87703 13.436 5.70103 14.696 5.11303 16.124C5.11303 16.124 5.11303 16.208 5.19703 16.292C5.28103 16.376 5.28103 16.376 5.36503 16.376H17.125C17.293 16.376 17.377 16.208 17.377 16.04C16.705 14.612 15.613 13.52 14.185 12.848C13.933 12.764 13.765 12.596 13.681 12.428C13.597 12.176 13.597 11.756 13.849 11.588C14.689 10.748 15.109 9.656 14.941 8.564C14.773 6.884 13.429 5.53999 11.749 5.37199C11.497 5.28799 11.329 5.288 11.245 5.288Z"
      fill="currentColor"
    />
  </svg>
);

export default ClientIdentifiedIcon;
