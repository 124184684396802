import React from 'react';

const BeautyIcon = ({ ...props }) => (
  <svg
    width="21"
    height="23"
    viewBox="0 0 21 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.8981 12.686H15.3319C15.0949 12.686 14.9027 12.8782 14.9027 13.1152C14.9027 13.3522 15.0949 13.5443 15.3319 13.5443H18.8981C19.0616 13.5443 19.1945 13.6772 19.1945 13.8407V15.7251H7.60707V13.8407C7.60707 13.6772 7.74001 13.5443 7.90343 13.5443H11.4697C11.7067 13.5443 11.8988 13.3522 11.8988 13.1152C11.8988 12.8782 11.7067 12.686 11.4697 12.686H10.1892L11.1586 2.08578C11.1913 1.72857 11.0714 1.37175 10.8296 1.10684C10.5878 0.841897 10.2434 0.689941 9.88465 0.689941H1.41602C1.05731 0.689941 0.712886 0.841897 0.47107 1.10684C0.229254 1.37179 0.109311 1.72857 0.142011 2.08578L1.61737 18.2182V21.1767C1.61737 21.9955 2.28347 22.6616 3.10225 22.6616H17.7173C19.0051 22.6616 20.0528 21.6139 20.0528 20.3261V17.0346C20.0528 16.8298 20.0054 16.6359 19.9212 16.4631C20.0022 16.385 20.0528 16.2757 20.0528 16.1543V13.8407C20.0528 13.204 19.5348 12.686 18.8981 12.686ZM7.90343 12.686C7.26677 12.686 6.7488 13.204 6.7488 13.8407V16.1542C6.7488 16.2756 6.7994 16.3849 6.88038 16.463C6.79618 16.6359 6.7488 16.8298 6.7488 17.0346V17.7694H2.43817L1.13344 3.50295H10.1672L9.32738 12.6861H7.90343V12.686ZM1.10503 1.6854C1.18575 1.59696 1.29621 1.54821 1.41602 1.54821H9.88465C10.0045 1.54821 10.1149 1.59691 10.1956 1.6854C10.2764 1.77389 10.3149 1.88834 10.304 2.00759L10.2457 2.64464H1.05495L0.996671 2.00759C0.985771 1.88834 1.02426 1.77389 1.10503 1.6854ZM2.47563 21.1766V18.6277H6.74885V20.0871C6.74653 20.0871 6.74434 20.0867 6.74202 20.0867H4.55873C4.0817 20.0867 3.69364 20.4748 3.69364 20.9518V21.8033H3.10221C2.75671 21.8033 2.47563 21.5222 2.47563 21.1766ZM4.55186 21.8033V20.9518C4.55186 20.948 4.55491 20.945 4.55868 20.945H6.74198C6.74576 20.945 6.7488 20.948 6.7488 20.9518V21.8033H4.55186ZM19.1945 20.3261C19.1945 21.1406 18.5318 21.8033 17.7173 21.8033H9.08431C8.26978 21.8033 7.60711 21.1406 7.60711 20.3261V17.0346C7.60711 16.7858 7.80953 16.5834 8.05834 16.5834H18.7434C18.9922 16.5834 19.1946 16.7858 19.1946 17.0346V20.3261H19.1945Z"
      fill="currentColor"
    />
    <path
      d="M15.8486 17.8196H10.9565C10.7195 17.8196 10.5273 18.0117 10.5273 18.2487V20.1369C10.5273 20.3739 10.7195 20.566 10.9565 20.566H15.8486C16.0856 20.566 16.2777 20.3739 16.2777 20.1369V18.2487C16.2777 18.0117 16.0856 17.8196 15.8486 17.8196ZM15.4195 19.7078H11.3856V18.6778H15.4195V19.7078Z"
      fill="currentColor"
    />
    <path
      d="M6.17103 7.96434C6.04127 7.81079 5.85155 7.72278 5.65054 7.72278C5.44953 7.72278 5.25982 7.81084 5.13005 7.96434L4.27672 8.97383C3.67786 9.68228 3.72258 10.7511 4.37855 11.4071C4.71829 11.7468 5.17004 11.9339 5.65054 11.9339C6.13104 11.9339 6.58279 11.7468 6.92253 11.4071C7.5785 10.7511 7.62326 9.68233 7.02441 8.97383L6.17103 7.96434ZM6.31565 10.8002C6.13799 10.9778 5.9018 11.0757 5.65054 11.0757C5.39933 11.0757 5.16313 10.9778 4.98547 10.8002C4.64247 10.4572 4.61908 9.89835 4.93222 9.52792L5.65054 8.67811L6.36887 9.52792C6.68205 9.89835 6.65866 10.4572 6.31565 10.8002Z"
      fill="currentColor"
    />
    <path
      d="M20.2745 5.43331C19.5921 4.8057 18.7468 4.41553 17.8303 4.30494C16.6534 4.16277 15.4924 4.48861 14.5613 5.22213C13.6348 5.95195 13.0495 6.99621 12.9133 8.1625C12.8573 8.64184 12.8788 9.11998 12.976 9.58465C12.8106 9.97623 12.6983 10.3922 12.6475 10.8217C12.6197 11.0571 12.7879 11.2704 13.0233 11.2983C13.0404 11.3003 13.0574 11.3013 13.0743 11.3013C13.2887 11.3013 13.4741 11.1407 13.4999 10.9225C13.5329 10.6431 13.596 10.3731 13.6864 10.1156C13.9835 10.2196 14.2915 10.2922 14.6074 10.3311C14.7868 10.3532 14.9654 10.364 15.1427 10.364C17.0055 10.364 18.7114 9.17229 19.3212 7.37732C19.4925 6.87313 19.813 6.42709 20.2481 6.08743C20.3475 6.00984 20.4078 5.89248 20.4129 5.76648C20.4178 5.64049 20.3672 5.51866 20.2745 5.43331ZM18.5084 7.10117C17.9707 8.6839 16.3742 9.68395 14.7123 9.47929C14.4918 9.45213 14.2761 9.4045 14.0669 9.33794C14.3806 8.85259 14.8091 8.44217 15.3302 8.14126C15.5355 8.02278 15.6058 7.76032 15.4873 7.55506C15.3688 7.34981 15.1063 7.27948 14.9011 7.398C14.4674 7.64836 14.0828 7.96738 13.7582 8.33712C13.7606 8.3121 13.7628 8.28712 13.7657 8.2621C13.8753 7.32411 14.3464 6.48391 15.0923 5.89634C15.843 5.30504 16.7789 5.04267 17.7274 5.15699C18.3068 5.22694 18.8509 5.43592 19.3239 5.76756C18.9557 6.15009 18.6775 6.60355 18.5084 7.10117Z"
      fill="currentColor"
    />
    <path
      d="M13.4018 13.5442C13.6388 13.5442 13.8309 13.352 13.8309 13.115C13.8309 12.878 13.6388 12.6859 13.4018 12.6859C13.1648 12.6859 12.9727 12.878 12.9727 13.115C12.9727 13.352 13.1648 13.5442 13.4018 13.5442Z"
      fill="currentColor"
    />
  </svg>
);
export default BeautyIcon;
