import React, { useEffect, useState } from 'react';
import { NotificationsCardList } from './styles';

import {
  Notification,
} from './elements';
import { TNotificationsCardProps } from './types';
import Modal from '../../../modal';
import { TNotification } from '../../../notifications-card/types';
import { useAppSelector } from '../../../../../hooks/use-dispatch';
import { useAuth } from '../../../../../context/AppContext';
import { format } from "date-fns";

const NotificationsCard = ({ onClose, ...props }: TNotificationsCardProps) => {

  const [notifications, setNotifications] = useState<TNotification[]>([]);
  const store = useAppSelector((state: any) => state.calendar);

  const { calendarApi } = useAuth();

  const getNotifications = async () => {
    if (calendarApi) {
      const events = await calendarApi.getEvents();
      const filters = events.filter((item: any) => format(new Date(item.start), "yyyy-MM-dd") === format(new Date(), "yyyy-MM-dd"));
      let notifications = filters.map((item: any) => {
        return {
          start: item.start,
          title: item.title,
          description: item.extendedProps.description,
          color: item.extendedProps.event_color,
          allDay: item.allDay
        }
      })

      notifications.sort(function (a: any, b: any) {
        if (a.allDay && !b.allDay) {
          return 1
        } else if (!a.allDay && b.allDay) {
          return -1
        } else {
          return new Date(a.start).getTime() - new Date(b.start).getTime()
        }
      })

      setNotifications([...notifications]);
    }
  };


  useEffect(() => {
    getNotifications();
    //eslint-disable-next-line
  }, [calendarApi, store?.events]);

  return (
    <Modal
      size="small"
      title={
        <div style={{color: '#fff'}}>
          Notifications 
        </div>
      }
      onClose={onClose}
      {...props}
    >
      <NotificationsCardList>
        {notifications.map(({...x }, index: any) => (
          <Notification key={index} {...x} />
        ))}
      </NotificationsCardList>
    </Modal>
  );
};

export default NotificationsCard;
