import React from 'react';

const WeeklyIcon = ({ ...props }) => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1516_3156)">
      <path
        d="M5.32826 4.01143C5.75421 4.01143 6.09984 3.66616 6.09984 3.24021V0.771309C6.09984 0.345314 5.75421 0 5.32826 0C4.90231 0 4.55664 0.345314 4.55664 0.771264V3.24017C4.55664 3.66612 4.90231 4.01143 5.32826 4.01143Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.00064"
      />
      <path
        d="M11.5001 4.01143C11.926 4.01143 12.2717 3.66616 12.2717 3.24021V0.771309C12.2717 0.345314 11.926 0 11.5001 0C11.0741 0 10.7285 0.345314 10.7285 0.771264V3.24017C10.7285 3.66612 11.0741 4.01143 11.5001 4.01143Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.00064"
      />
      <path
        d="M17.672 4.01143C18.098 4.01143 18.4435 3.66616 18.4435 3.24021V0.771309C18.4436 0.345314 18.098 0 17.672 0C17.246 0 16.9004 0.345314 16.9004 0.771264V3.24017C16.9004 3.66612 17.246 4.01143 17.672 4.01143Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.00064"
      />
      <path
        d="M19.1818 2.01697H19.0599V3.24019C19.0599 4.00557 18.4372 4.62855 17.6712 4.62855C16.9058 4.62855 16.2825 4.00552 16.2825 3.24019V2.01697H12.8886V3.24019C12.8886 4.00557 12.2652 4.62855 11.4998 4.62855C10.7345 4.62855 10.1111 4.00552 10.1111 3.24019V2.01697H6.71719V3.24019C6.71719 4.00557 6.09386 4.62855 5.32848 4.62855C4.5631 4.62855 3.93967 4.00557 3.93967 3.24019V2.01697H3.81847C2.15268 2.01697 0.802734 3.36727 0.802734 5.03271V19.9843C0.802734 21.6497 2.15268 23 3.81847 23H19.1818C20.8469 23 22.1976 21.6498 22.1976 19.9843V5.03271C22.1975 3.36727 20.8469 2.01697 19.1818 2.01697ZM20.2533 19.7554C20.2533 20.4729 19.6698 21.0565 18.9526 21.0565H4.04771C3.33017 21.0565 2.74628 20.473 2.74628 19.7554V6.42906H20.2533V19.7554Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.00064"
      />
      <path
        d="M9.47807 18L12.5711 11.8778V11.8281H8.98091V10.7273H13.9347V11.853L10.8453 18H9.47807Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1516_3156">
        <rect width="23" height="23" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);

export default WeeklyIcon;
